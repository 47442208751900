import { AxiosError } from "axios";
import { call, takeLatest, put } from "redux-saga/effects";

import {createShopAction, getShopAction, shopAction} from "./actions";
import {PromiseReturnType, TDataWrapper} from "../types";
import { ApiShopService } from "./api.service";
import { showToastAction } from "../toasts";
import {generateErrorToast, generateSuccessToast} from "../../common";
import i18n from "i18next";
import {getAccessToken} from "../user";
import {TRemoveShopPayload} from "./types";
import {SHOP_RESPONSE} from "./consts";

function* getShopWorker({ payload }: ReturnType<typeof getShopAction['request']>) {
	try {
		const token: string = yield getAccessToken();

		const response: PromiseReturnType<ReturnType<typeof ApiShopService.getShop>> =
			yield call([ApiShopService, ApiShopService.getShop], {
				authorization: token,
				...payload,
			})

		yield put(
			getShopAction.success({
				shops: response.data.data,
			}),
		)
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.shops") as string)));
		yield put(getShopAction.failure(e as AxiosError));
	}
}

function* createShopWorker({
	                            payload,
                            }: ReturnType<typeof createShopAction['request']>) {
	try {
		const token: string = yield getAccessToken();

		const response: PromiseReturnType<ReturnType<typeof ApiShopService.createShop>> =
			yield call([ApiShopService, ApiShopService.createShop], {
				authorization: token,
				...payload,
			})

		yield put(createShopAction.success(response.data.data))

		yield put(
			showToastAction.request(
				generateSuccessToast(i18n.t('shop.creating.successful') as string),
			),
		)
	} catch (e:any) {
		yield put(
			showToastAction.request(
				generateErrorToast(i18n.t("shop.creating.error") as string),
			),
		)
		yield put(getShopAction.failure(e as AxiosError));
	}
}

function* removeShopsWorker({ payload }: TDataWrapper<TRemoveShopPayload>) {
	yield put(shopAction.setShopState({ loading: true }))
	const token: string = yield call(getAccessToken)

	const { _ids } = payload

	try {
		for (let _id of _ids) {
			yield call([ApiShopService, ApiShopService.removeShop], { token, _id })
		}
		yield put(showToastAction.request(generateSuccessToast(i18n.t("shop.removed.successfully") as string)));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.shop") as string)));
	}

	yield put(shopAction.setShopState({ loading: false, response: SHOP_RESPONSE.REMOVED }))
}

export function* shopWatcher() {
	yield takeLatest(getShopAction.request, getShopWorker);
	yield takeLatest(createShopAction.request, createShopWorker)
	yield takeLatest(shopAction.removeShops, removeShopsWorker)
}