import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { clientActions } from './actions'
import { ApiClientService } from './api.service'
import { RESPONSE } from './consts'
import {
   TClientResponse,
   TEditClientPayload,
   TGetClientPayload,
   TGetClientsPayload, TRemoveClientRequestPayload
} from './types'
import { getAccessToken } from "../user";
import {showToastAction} from "../toasts";
import {generateErrorToast, generateSuccessToast} from "../../common";
import {i18n} from "../../common/config";


function* getClientsWorker({ payload }: TDataWrapper<TGetClientsPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiClientService, ApiClientService.getClients], { ...payload, token })
      if (response.data) yield put(clientActions.setClients(response.data))
   } catch (e) {
      yield put(clientActions.setClients({ data: [], meta: null }))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.clients") as string)));
   }

   yield put(clientActions.setClientState({ loading: false }))
}

function* getClientWorker({ payload }: TDataWrapper<TGetClientPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const { _id } = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiClientService, ApiClientService.getClient], { _id, token })
      if (response.data.data) {
         yield put(clientActions.setClient({ ...response.data.data }))
         yield put(clientActions.setClientState({ loading: false }))
      } else {
         yield put(clientActions.setClientState({ loading: false, response: RESPONSE.NOT_FOUND as TClientResponse }))
      }
   } catch (e) {
      yield put(clientActions.setClientState({ loading: false, response: RESPONSE.NOT_FOUND as TClientResponse }))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.client") as string)));
   }
}

function* editClientWorker({ payload }: TDataWrapper<TEditClientPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const { _id, data } = payload
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiClientService, ApiClientService.editClient], { _id, data, token })
      yield put(showToastAction.request(generateSuccessToast(i18n.t("client.updated.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.update.client") as string)));
   }

   yield put(clientActions.setClientState({ loading: false }))
}

function* removeClientWorker({ payload }: TDataWrapper<TRemoveClientRequestPayload>) {
   yield put(clientActions.setClientState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _id } = payload

   try {
      yield call([ApiClientService, ApiClientService.removeClient], { token, _id })
      yield put(clientActions.setClients({ data: [], meta: { needReload: true } }))
      yield put(showToastAction.request(generateSuccessToast(i18n.t("client.removed.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.client") as string)));
   }

   yield put(clientActions.setClientState({ loading: false, response: RESPONSE.REMOVED as TClientResponse }))
}

export function* clientWatcher() {
   yield takeLatest(clientActions.getClients, getClientsWorker)
   yield takeLatest(clientActions.getClient, getClientWorker)
   yield takeLatest(clientActions.editClient, editClientWorker)
   yield takeLatest(clientActions.removeClient, removeClientWorker)
}
