import { createAction } from '@reduxjs/toolkit'
import { GET_REQUESTS, MODIFY_REQUEST, SET_REQUESTS, SET_SUPPORT_STATE } from './consts'
import { TSupportRequestsPayload } from './types'

export const supportActions = {
   getRequests: createAction(GET_REQUESTS, (payload: TSupportRequestsPayload) => ({ payload })),
   setRequests: createAction(SET_REQUESTS, (payload) => ({ payload })),
   modifyRequest: createAction(MODIFY_REQUEST, (payload) => ({ payload })),
   setSupportState: createAction(SET_SUPPORT_STATE, (payload) => ({ payload }))
}
