import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";

import {
	ButtonNew,
	DataTotal,
	DateInput,
	FiltersWrapper,
	FlexContainer,
	HeaderWrapper,
	HoverList,
	Pagination,
	SearchBar,
	SubPagesBar,
	Table,
	TSort,
	useTypedSelector
} from "../../common";
import { getMainOrderSelector, orderActions } from "../../store";

import {
	ButtonBlock,
	Container,
	FlexStyledContainer,
	Header,
	MainContainer,
	Title,
	TitleBlock,
} from "./styled";
import {getLocale} from "../../types";

const OrderArchive = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { archive } = useTypedSelector(getMainOrderSelector)
	const navigate = useNavigate()
	const local = getLocale();

	const [page, setPage] = useState<number>(0);
	const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [searchForm, setSearchForm] = useState({
		searchValue: '',
		startDateValue: '',
		endDateValue: ''
	})

	const [searchParams, setSearchParams] = useSearchParams({})

	const [sortParamsForm, setSortParamsForm] = useState({
		sortBy: '',
		order: '' as TSort
	})
	const paginationSections = useMemo(
		() => [
			{
				title: t('current.orders'),
				onClickHandler: () => {
					navigate('../orders')
				}
			},
			{
				title: t('notify.when.available'),
				onClickHandler: () => {
					navigate('/availabilityNotification')
				}
			},
			{
				title: t('archive'),
				active: true
			}
		],
		[]
	)

	const tableSortBy = useMemo(() => {
		{
			if (!sortParamsForm.order) {
				return { id: null, desc: false }
			}
			return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
		}
	}, [sortParamsForm])

	const Requests = {
		getArchive: () => {
			dispatch(
				orderActions.getArchive({
					limit: 9,
					page,
					lang: local,
					regex: searchForm.searchValue,
					start: searchForm.startDateValue,
					end: searchForm.endDateValue,
					...sortParamsForm
				})
			)
		},
		removeOrdersArchive: (_ids: string[]) => {
			dispatch(orderActions.removeOrder({_ids}))
			Requests.getArchive()
		}
	}

	const Events = {
		onPageChangeHandler: ({ selected }: { selected: number }) => {
			setPage(selected)
		},
		onChangeHandler: (e: React.SyntheticEvent) => {
			const input = e.target as HTMLInputElement
			setSearchForm({ ...searchForm, [input.name]: input.value })
			Requests.getArchive()
		},
		onSubmitSearchHandler: (e: SyntheticEvent) => {
			e.preventDefault()
			Requests.getArchive()
		},
		sortToggleHandler: (sortBy: string, order: TSort) => {
			setSortParamsForm({ sortBy, order })
		},
		editClickHandler: (e: SyntheticEvent, index: number) => {
			navigate(`../orders/profile/${archive.data[index]._id}`)
		},
		removeOrdersArchive: () => {
			Requests.removeOrdersArchive(selectedItems.map((index) => archive.data[index]._id))
			setRemoveButtonState(false)
			setSelectedItems([])
		},
		checkboxClickHandler: (e: SyntheticEvent, hasCheckboxesActiveState: boolean, ckeckedItemsIndex: number[]) => {
			setRemoveButtonState(hasCheckboxesActiveState)
			setSelectedItems(ckeckedItemsIndex)
		},
	}

	const data = useMemo(
		() =>
			archive.data.map((order) => {
				const created_at = order.createdAt
				return {
					id: <>{order.id}</>,
					createdAt: moment(created_at).format('DD.MM.YYYY HH:mm'),
					order: (
						<FlexContainer align="center" justify="center">
							<FlexContainer direction="column" align="flex-start" justify="center" gap="4px" width="170px">
								<p>{order.items[0].product?.description?.title}</p>
								{!!order.items.slice(1).length && (
									<HoverList items={order.items.slice(1).map((item) => item.product?.description?.title)} />
								)}
							</FlexContainer>
						</FlexContainer>
					),
					customer: (
						<>
							<p>{order.customer?.name}</p>
							<p>{order.customer?.email}</p>
						</>
					),
					paymentStatus: <>{t('online')}</>,
					orderStatus: <>{t(order.orderStatus).split(' ').join('.')}</>
				}
			}),
		[archive]
	)

	const columns = useMemo(
		() => [
			{
				Header: t('order.code'),
				accessor: 'id',
				width: 100,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('date'),
				accessor: 'createdAt',
				width: 200,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('order'),
				accessor: 'order',
				width: 250
			},
			{
				Header: t('client'),
				accessor: 'customer',
				width: 300,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('payment'),
				accessor: 'paymentStatus',
				width: 300
			},
			{
				Header: t('order.status'),
				accessor: 'orderStatus',
				width: 300,
				sortToggleHandler: Events.sortToggleHandler
			}
		],
		[]
	);

	useEffect(() => {
		Requests.getArchive()
		setSearchParams({ ...searchParams, page: '' + page })
	}, [page, sortParamsForm, searchForm])

	// Set default page uri
	useEffect(() => {
		setPage(!isNaN(parseInt(searchParams.get('page') as string)) ? parseInt(searchParams.get('page') as string) : 0)
	}, [])

	return (
		<Container>
			<Header>
				<TitleBlock>
					<Title>{t('orders')}</Title>
				</TitleBlock>
				<FlexStyledContainer>
					{removeButtonState && (
						<ButtonBlock>
							<ButtonNew width={195} theme="red" onClick={Events.removeOrdersArchive}>
								{t('remove.all')}
							</ButtonNew>
						</ButtonBlock>
					)}
				</FlexStyledContainer>
			</Header>
			<MainContainer>
				<SubPagesBar sections={paginationSections} />

				<FlexContainer direction="column" gap="30px">
					<HeaderWrapper>
						<FiltersWrapper>
							<SearchBar
								name="searchValue"
								placeholder={t('search')}
								value={searchForm.searchValue}
								onChange={Events.onChangeHandler}
								onSubmit={Events.onSubmitSearchHandler}
							/>
							<DateInput
								name="startDateValue"
								value={searchForm.startDateValue}
								onChange={Events.onChangeHandler}
							/>
							<DateInput name="endDateValue" value={searchForm.endDateValue} onChange={Events.onChangeHandler} />
						</FiltersWrapper>
						<DataTotal>
							{t('total.archive')} {archive.meta?.totalCount}
						</DataTotal>
					</HeaderWrapper>

					<Table
						columns={columns}
						data={data}
						editable
						checkboxClickHandler={Events.checkboxClickHandler}
						editClickHandler={Events.editClickHandler}
						sortBy={tableSortBy}
					/>
				</FlexContainer>

				<Pagination
					page={page}
					pageCount={archive.meta ? Math.ceil(archive.meta.totalCount / 9) : 1}
					onPageChange={Events.onPageChangeHandler}
				/>
			</MainContainer>
		</Container>
	);
}

export default OrderArchive;