import React, {SyntheticEvent, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";

import { PRODUCT_IMAGES_LENGTH } from "../../consts";
import {
	Assets, ButtonNew,
	colors,
	DropDownList,
	FlexContainer,
	Input,
	PhotoPicker,
	Textarea, useTypedSelector
} from "../../../../common";

import {
	Delete,
	FormContainer,
	ImageClose,
	Label,
	Image,
	MainContainer,
	PhotoBlock,
	VariationBlock,
	VariationsContainer,
	VariationsMain,
	VariationWrapper,
} from "../../styled";
import { api } from "../../../../common/config/api";
import {FormBlock} from "../../../CreateProduct/styled";
import {getMainProductSelector} from "../../../../store";

const EditVariation = (
	{
		removePhotoHandler,
		resultPhotos,
		Events,
		setProductGroup,
		productGroup,
		form,
		formPhotos,
		visibilityOptions,
		statusOptions,
	}:any) => {
	const { t } = useTranslation();

	const { newProduct } = useTypedSelector(getMainProductSelector);

	const [variationsOption, setVariationsOptions] = useState<any>([]);
	const [variationText, setVariationText] = useState<any>([])
	const [variationColor, setVariationColor] = useState<any>([])

	const Util = {
		totalPhotos: (): number => {
			return PRODUCT_IMAGES_LENGTH
		},
		totalValidPhotos: (): number => {
			return formPhotos.photos.length
		},
		getVariationIndex: (e: SyntheticEvent): number => {
			const target = e.target as HTMLElement
			const variationsContainer = target.closest('.variations_form_container')
			const variationContainer = target.closest('.variation_form_container')
			return Array.prototype.indexOf.call(variationsContainer?.children, variationContainer)
		}
	};

	useEffect(() => {
		if(newProduct){
			setVariationsOptions(newProduct?.group?.variations?.map((el:any) => ({
				name: el?.variation?.title[0].title,
				value: el?.variation?._id
			})))
			setVariationColor(newProduct?.group?.variations?.map((variation:any) => variation?.values?.map((value:any) => ({
				variationId:  variation?.variation?._id,
				name: value?.title[0]?.title,
				value: value?._id
			})))[0])
			setVariationText(newProduct?.group?.variations?.map((variation:any) => variation?.values?.map((value:any) => ({
				variationId: variation?.variation?._id,
				name: value?.title[0]?.title,
				value: value?._id
			})))[1])
		}
	}, [newProduct])

	const Event = {
		onAddClickHandler: () => {
			setProductGroup(productGroup.concat([{ variation: '', value: '' }] as typeof productGroup))
		},
		inputChangeHandler: (e: SyntheticEvent) => {
			const { name, value } = e.target as HTMLInputElement
			const variationContainerIndex = Util.getVariationIndex(e)
			setProductGroup((form:any) =>
				form.map((group:any, index:number) => {
					if (index !== variationContainerIndex) return group
					if(name === "variation"){
						return {
							...group,
							[name]: value,
						}
					} else {
						return {
							...group,
							value: value
						}
					}
				})
			)
		}
	};

	useEffect(() => {
		if(variationsOption){
			setProductGroup(variationsOption.map((el:any) => ({
				variation: el.value,
				value: ''
			})))
		}
	}, [variationsOption])

	return (
		<MainContainer>
			<FormContainer>
				<FlexContainer gap="62px">
					<>
						<DropDownList
							name="show"
							label={t('visiability')}
							options={visibilityOptions}
							value={form.show}
							onChange={Events.inputChangeShowHandler}
						/>
						<Input
							name="sku"
							type={'number'}
							label={t('SKU')}
							placeholder={t('enter.code')}
							value={form.sku}
							onChange={Events.inputChangeHandler}
						/>
					</>
				</FlexContainer>
				<FormBlock>
					<Input
						name="nameEn"
						width="275px"
						label={t('name')}
						placeholder={t('enter.name.en')}
						value={form.nameEn}
						onChange={Events.inputChangeHandler}
					/>
					<Input
						name="nameUa"
						width="275px"
						label={t('name')}
						placeholder={t('enter.name.ua')}

						value={form.nameUa}
						onChange={Events.inputChangeHandler}
					/>
					<Input
						name="nameDe"
						width="275px"
						label={t('name')}
						placeholder={t('enter.name.de')}
						value={form.nameDe}
						onChange={Events.inputChangeHandler}
					/>
				</FormBlock>
				<FormBlock>
					<Textarea
						width='275px'
						name="descriptionEn"
						height="260px"
						placeholder={t('enter.description.en')}
						label={t('description')}
						onChange={Events.inputChangeHandler}>
						{form.descriptionEn}
					</Textarea>
					<Textarea
						width='275px'
						name="descriptionUa"
						height="260px"
						placeholder={t('enter.description.ua')}
						label={t('description')}
						onChange={Events.inputChangeHandler}>
						{form.descriptionUa}
					</Textarea>
					<Textarea
						width='275px'
						name="descriptionDe"
						height="260px"
						placeholder={t('enter.description.de')}
						label={t('description')}
						onChange={Events.inputChangeHandler}>
						{form.descriptionDe}
					</Textarea>
				</FormBlock>
				<VariationWrapper >
					<VariationsContainer className='variations_form_container'>
						{
							productGroup.map((group:any, index:number) => {
								return (
									<VariationBlock key={index} className='variation_form_container'>
										<VariationsMain>
											<FlexContainer gap={'62px'} style={{padding: '10px 0'}}>
												<DropDownList
													disabled={true}
													name="variation"
													label={t('variation')}
													options={variationsOption}
													placeholder={t('enter.variation')}
													value={group.variation}
													onChange={(event) => Event.inputChangeHandler(event)}
												/>
												{
													variationColor[0]?.variationId === group?.variation ?
													<DropDownList
													name="values"
													label={t('value.for.variation')}
													options={variationColor}
													placeholder={t('enter.value.for.variation')}
													value={group.value}
													onChange={(event) => Event.inputChangeHandler(event)}
													/> : <DropDownList
															name="values"
															label={t('value.for.variation')}
															options={variationText}
															placeholder={t('enter.value.for.variation')}
															value={group.value}
															onChange={(event) => Event.inputChangeHandler(event)}
														/>
												}
											</FlexContainer>
										</VariationsMain>
									</VariationBlock>
								)
						})}
					</VariationsContainer>
				</VariationWrapper>
				<FlexContainer gap="30px">
					<FlexContainer gap="62px">
						<Input
							name="price"
							type="number"
							label={t('price')}
							placeholder={t('enter.price')}
							value={form.price}
							onChange={Events.inputChangeHandler}
						/>
						<Input
							name="discountPrice"
							type="number"
							label={t('discount.price')}
							placeholder={t('enter.discount.price')}
							value={form.discountPrice}
							onChange={Events.inputChangeHandler}
						/>
					</FlexContainer>
					<FlexContainer gap="62px">
						<Input
							name="amount"
							label={t('amount')}
							placeholder={t('enter.amount')}
							value={form.amount}
							onChange={Events.inputChangeHandler}
						/>
						<DropDownList
							name="sellStatus"
							label={t('status')}
							options={statusOptions}
							placeholder={t('enter.status')}
							value={form.sellStatus}
							onChange={Events.inputChangeHandler}
						/>
					</FlexContainer>
				</FlexContainer>
			</FormContainer>

			<FlexContainer direction="column" align="center" gap="">
				<FlexContainer
					direction="column"
					style={{borderTop: `1px solid ${colors.lines}`, marginTop: '8px', padding: '24px 30px'}}>
					<Label>{t('photo')}</Label>
					<FlexContainer gap="28px">
						{
							resultPhotos?.map((photo:any, index:any) => (
								<PhotoBlock key={index}>
									<ImageClose onClick={() => removePhotoHandler(index)} src={Assets.CROSS}/>
									<PhotoPicker
										width={150} height={150}
										name="image"
										index={index}
										value={`${api.withImageAPI}/product/gallery/${photo}`}
									/>
								</PhotoBlock>
							))
						}
						{Util.totalValidPhotos() < Util.totalPhotos() && (
							<PhotoPicker
								width={150} height={150}
								name={'photo' + (Util.totalValidPhotos() + 1)}
								onChange={Events.onPhotoChange}
							/>
						)}
					</FlexContainer>
				</FlexContainer>
			</FlexContainer>
		</MainContainer>
	);
};

export default EditVariation;