import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common";
import {TImage} from "../Banner/types";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({justify: 'space-between'})}
`;

export const TitleBlock = styled.div`
  display: flex;
`;

export const ButtonBlock = styled.div`
  margin-left: 30px;
`;

export const FlexStyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  ${FONT({ weight: '700', size: '24px', color: colors.black })};

  margin-left: 14px;
`;

export const MainContainer = styled.main`
	position: relative;
	
  max-width: 1609px;
	height: 925px;
	
	overflow-y: scroll;
  
  margin-top: 26px;
	
	background: white;
	
  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
	
  border-radius: 22px; 
`;

export const Image = styled.div<TImage>`
   display: inline-block;
   background-image: ${({ src }) => `url(${src})`};
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   height: 46px;
   width: 46px;
`

