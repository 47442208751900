import styled from 'styled-components'
import { TStyledTextarea } from './types'
import {colors, FONT} from "../../styles";

export const StyledTextarea = styled.textarea<TStyledTextarea>`
   width: ${({ width }) => width};
   height: ${({ height }) => height};

   border: 1px solid ${colors.gray};
   border-radius: 8px;
   resize: none;
   padding: 14px;

   ${FONT({ size: '16px' })}

   &:focus {
      border: 2px solid  ${({ color }) => `${color}`};
      background: ${colors.white};
   }

   @media (max-width: 1600px) {
      width: 90%;
      height: ${({ height }) => height};
   }
`
