import { createAction } from '@reduxjs/toolkit'

import {
	GET_DELIVERY,
	SET_DELIVERY,
	SET_DELIVERY_STATE,
	GET_DELIVERY_REF,
	SET_DELIVERY_REF,
	SET_DELIVERY_WAREHOUSE,
	GET_DELIVERY_WAREHOUSE,
	CREATE_CALCULATE,
	GET_CALCULATE,
	SET_CALCULATE,
	GET_DELIVERY_CONFIG,
	SET_DELIVERY_CONFIG,
	UPDATE_DELIVERY_CONFIG
} from './consts'

export const deliveryActions = {
	getDelivery: createAction(GET_DELIVERY, (payload) => ({payload})),
	setDelivery: createAction(SET_DELIVERY, (payload) => ({payload})),
	getDeliveryRef: createAction(GET_DELIVERY_REF, (payload) => ({payload})),
	setDeliveryRef: createAction(SET_DELIVERY_REF, (payload) => ({payload})),
	setDeliveryState: createAction(SET_DELIVERY_STATE, (payload) => ({payload})),
	setDeliveryWarehouse: createAction(SET_DELIVERY_WAREHOUSE, (payload) => ({payload})),
	getDeliveryWarehouse: createAction(GET_DELIVERY_WAREHOUSE, (payload) => ({payload})),
	createCalculate: createAction(CREATE_CALCULATE, (payload) => ({payload})),
	getCalculate: createAction(GET_CALCULATE, (payload) => ({payload})),
	setCalculate: createAction(SET_CALCULATE, (payload) => ({payload})),
	getDeliveryConfig: createAction(GET_DELIVERY_CONFIG, (payload) => ({payload})),
	setDeliveryConfig: createAction(SET_DELIVERY_CONFIG, (payload) => ({payload})),
	updateDeliveryConfig: createAction(UPDATE_DELIVERY_CONFIG, (payload) => ({payload})),
}
