import styled from "styled-components";

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;

  margin-top: 26px;
	
	background: white;
	
  border-radius: 22px;
`;

export const CharacteristicsWrapper = styled.div``;



