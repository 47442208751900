import styled from 'styled-components'
import { Assets, colors, FONT } from "../../index";

export const Styles = styled.div`
   width: 100%;

   table {
      border-spacing: 0;
      border: 0px solid black;
      width: 100%;
      border-collapse: collapse;

      .checkboxHeader {
         width: 90px;
      }

      .buttonHeader {
         width: auto;
      }

      th {
         height: 74px;
         user-select: none;
         ${FONT({ color: colors.gray, weight: '500', size: '14px', align: 'center' })}
         width:1px;
      }

      td {
         border: 1px solid ${colors.lines};
         border-left: 0;
         border-right: 0;
         height: 74px;
         align-items: center;
         ${FONT({ size: '14px', align: 'center' })}

         & > img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 100%;
            height: 46px;
            width: 46px;
         }

         input[type='checkbox'] {
            height: 18px;
            width: 18px;
            cursor: pointer;
         }
      }
   }
`
export const HeaderContentContainer = styled.div`
   display: inline-flex;
   position: relative;
`

export const ButtonsContainer = styled.div`
   display: flex;
   gap: 20px;
   justify-content: flex-end;
   margin-right: 30px;
`

export const ArrowDownIcon = styled.img.attrs({
   src: Assets.ARROW_DOWN_ICON
})`
   position: absolute;

   right: -25px;
   top: -4px;
`

export const ArrowUpIcon = styled(ArrowDownIcon)`
   transform: rotate(180deg);
`
