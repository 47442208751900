import {AxiosResponse} from 'axios'
import { HttpService } from '../http.service'
import {TResponse} from '../types'
import {BANNER_URL} from './config'
import {TCreateBannerRequestPayload, TEditBannerRequestPayload, TRemoveBannerRequestPayload} from './types'

export class ApiBannerService extends HttpService {
   static getBanners(token: string): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: BANNER_URL.getBanners,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })
   }

   static createBanner({ token, data }: TCreateBannerRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: BANNER_URL.createBanner,
         method: 'POST',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static removeBanner({ token, _id }: TRemoveBannerRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${BANNER_URL.createBanner}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })
   }

   static editBanner({ token, data, _id }: TEditBannerRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${BANNER_URL.createBanner}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })
   }
}
