import React, {
   FC,
   SyntheticEvent,
   useEffect,
   useMemo,
   useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
   ButtonNew,
   colors,
   FlexContainer,
   generateErrorToast,
   H2,
   Input,
   Pagination,
   PhotoPicker,
   Popup,
   SubPagesBar,
   Table,
   TDataTable, Textarea,
   TOption,
   TSort,
   useTypedSelector,
   useValidation
} from '../../../../common';
import { api } from '../../../../common/config/api';
import { getLocale } from "../../../../types";

import {
   ButtonBlock,
   Container,
   FlexStyledContainer,
   Header,
   Image,
   MainContainer,
   Title
} from '../../styled';

import {
   brandActions,
   BRAND_RESPONSE,
   getMainBrandSelector,
   showToastAction
} from '../../../../store';

import { TBrandValidationSchema, TForm, TFormTypes } from './types';
import { DROP_DOWN_LIST_LIMIT} from "./consts";

const IndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate();
   const [page, setPage] = useState<number>(0);

   const { brands, response } = useTypedSelector(getMainBrandSelector);

   const local = getLocale();

   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)

   const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false)

   const [formType, setFormType] = useState<TFormTypes>('create')

   const [selectedItems, setSelectedItems] = useState<number[]>([])

   const [form, setForm] = useState<TForm>({
      name: '',
      photo: null,
      _id: '',
      description: '',
      order: 1
   })

   const [searchParams, setSearchParams] = useSearchParams({})

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const data: TDataTable[] = useMemo(
      () =>
       brands.data.map((brand:any) => {
            return {
               photo: <Image src={`${api.withImageAPI}/brand/${brand.image}`} />,
               title: brand.title,
               description: <>{brand.description}</>,
            }
         }),
      [brands]
   );

   const Requests = {
      getBrands: () => {
         dispatch(
            brandActions.getBrands({
               limit: DROP_DOWN_LIST_LIMIT,
               page: page,
               lang: local,
               ...sortParamsForm
            })
         )
      },
      createBrand: () => {
         const data = new FormData()

         data.append('title', form.name)
         data.append('description', form.description)
         data.append('img', form.photo as File)
         data.append('order', form.order as any)

         dispatch(brandActions.createBrand({ data }))
      },
      removeBrands: (_ids: string[]) => {
         dispatch(brandActions.removeBrand({ _ids }))
      },
      editBrand: () => {
         const data = new FormData()

         data.append('title', form.name)
         data.append('description', form.description)
         data.append('order', form.order as any)
         form.photo && data.append('img', form.photo as File)

         dispatch(brandActions.editBrand({ data, _id: form._id as string }))
      }
   };

   const schema = useMemo<TBrandValidationSchema>(
     () => ({
        name:{
           condition: !!form.name,
           error: t('enter.name')
        },
        description: {
           condition: !!form.description,
           error: t('enter.description')
        },
        photo: {
           condition: form.photo !== null,
           error: t('enter.photo')
        }
     }),
     [form]
   );

   const {
      validation,
      enableValidation,
      disableValidation,
      //eslint-disable-next-line
   } = useValidation(schema);


   const Events = {
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      closePopupClickHandler: () => {
         setPopupVisiablity(false)
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      onPhotoChange: (file: File | null) => {
         setForm({ ...form, photo: file as File })
      },
      createButtonClickHandler: async () => {
         try {
            enableValidation();
            await validation();
            Requests.createBrand()
            setPopupVisiablity(false)
            disableValidation()
         } catch (error:any) {
            error?.map((err:any) => {
               dispatch(showToastAction.request(generateErrorToast(err)));
            });
         }
      },
      editButtonClickHandler: async () => {
         try {
            enableValidation();
            await validation();
            Requests.editBrand()
            setPopupVisiablity(false)
            disableValidation()
         } catch (error:any) {
            error?.map((err:any) => {
               dispatch(showToastAction.request(generateErrorToast(err)));
            });
         }
      },
      addButtonClickListner: () => {
         setForm({
            name: '',
            photo: null,
            order: 1,
            description: ''
         })
         setFormType('create')
         setPopupVisiablity(true)
      },
      removeBrands: () => {
         Requests.removeBrands(
            selectedItems.map((index) => brands.data[index]._id)
         )
         setRemoveButtonState(false)
         setSelectedItems([])
      },
      paginateDropDownChangeHandler: (value: TOption) => {
         setForm((form) => ({ ...form, section: value }))
      },
      removeBrandsHandler: (e: SyntheticEvent, index: number) => {
         Requests.removeBrands([brands.data[index]._id])
      },
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },
      openEditButtonClickHandler: async (e: SyntheticEvent, index: number) => {
         await setFormType('edit')
         await setPopupVisiablity(true)
         await setForm({
            name: brands?.data[index]?.title,
            photo: `${api.withImageAPI}/brand/${brands.data[index].image}`,
            _id: brands.data[index]._id,
            order: brands?.data[index].order,
            description: brands?.data[index]?.description
         })
      },
      onChange: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setForm({ ...form, [input.name]: input.value })
      }
   }

   const columns = useMemo(
      () => [
         {
            Header: t('photo'),
            accessor: 'photo',
            width: 350
         },
         {
            Header: t('label'),
            accessor: 'title',
            width: 400,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('description'),
            accessor: 'description',
            width: 400,
            sortToggleHandler: Events.sortToggleHandler
         }
      ],
      []
   )

   const paginationSections = useMemo(
      () => [
         {
            title: t('sections'),
            onClickHandler: () => {
               navigate('/catalog/sections')
            }
         },
         {
            title: t('categories'),
            onClickHandler: () => {
               navigate('/catalog/categories')
            }
         },
         {
            title: t('subcategories'),
            onClickHandler: () => {
               navigate('/catalog/subcategories')
            }
         },
         {
            title: t('brands'),
            active: true
         }
      ],
      []
   )

   // Request to receive data
   useEffect(() => {
      Requests.getBrands()
      setSearchParams({ ...searchParams, page: '' + page })
   }, [page, sortParamsForm])

   // Set default page uri
   useEffect(() => {
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string)
            : 0
      )
   }, [])

   useEffect(() => {
      if (response == BRAND_RESPONSE.CREATED) {
         setPopupVisiablity(false)
         setForm({
            name: '',
            photo: null,
            order: 1,
            description: ''
         })
         Requests.getBrands()
      }

      if (response == BRAND_RESPONSE.REMOVED) {
         Requests.getBrands()
      }

      if (response == BRAND_RESPONSE.EDITED) {
         Requests.getBrands()
      }
   }, [response]);

   return (
     <Container>
        <Header>
           <Title>{t('categories')}</Title>
           <FlexStyledContainer>
              {removeButtonState && (
                <ButtonBlock>
                   <ButtonNew theme="red" onClick={Events.removeBrands}>
                      {t('remove.all')}
                   </ButtonNew>
                </ButtonBlock>
              )}
              <ButtonBlock>
                 <ButtonNew theme="green" onClick={Events.addButtonClickListner}>
                    {t('create.brand')}
                 </ButtonNew>
              </ButtonBlock>
           </FlexStyledContainer>
        </Header>
        <MainContainer>
           <SubPagesBar sections={paginationSections} />

           <Table
             columns={columns}
             data={data}
             sortBy={tableSortBy}
             removeClickHandler={Events.removeBrandsHandler}
             checkboxClickHandler={Events.checkboxClickHandler}
             editClickHandler={Events.openEditButtonClickHandler}
             editable
             removable
           />

           <Pagination
             page={page}
             pageCount={
                brands.meta ? Math.ceil(brands.meta.totalCount / DROP_DOWN_LIST_LIMIT) : 1
             }
             onPageChange={Events.onPageChangeHandler}
           />

           {popupVisiablity && (
             <Popup backgroundClickListener={Events.closePopupClickHandler}>
                <FlexContainer
                  justify="space-between"
                  direction="column"
                  style={{ height: '100%' }}
                  wrap="nowrap">
                   <FlexContainer gap="20px" align="center" justify="center">
                      {formType == 'create' && (
                        <H2 style={{ marginTop: '40px' }}>{t('create.brand')}</H2>
                      )}
                      {formType == 'edit' && (
                        <H2 style={{ marginTop: '40px' }}>{t('edit.brand')}</H2>
                      )}

                      <FlexContainer
                        gap="30px"
                        align="center"
                        justify="center"
                        style={{
                           padding: '30px 0px',
                           borderBottom: `1px solid ${colors.gray}`
                        }}>
                         <Input
                           name="name"
                           label={t('name')}
                           placeholder={t('enter.name')}
                           value={form.name}
                           onChange={Events.inputChangeHandler}
                         />
                      </FlexContainer>
                      <FlexContainer
                        style={{
                           paddingLeft: '60px',
                        }}
                        gap='30px'
                        justify={"center"}>
                         <Textarea
                           width='500px'
                           name="description"
                           height="150px"
                           placeholder={t('enter.description')}
                           label={t('description')}
                           onChange={Events.inputChangeHandler}>
                            {form.description}
                         </Textarea>
                      </FlexContainer>
                      <PhotoPicker
                        width={150} height={150}
                        label={t('photo')}
                        onChange={Events.onPhotoChange}
                        value={formType == 'edit' ? (form.photo as string) : null}
                      />
                      <FlexContainer
                        justify="center"
                        direction="column"
                        align="center"
                        gap="20px">
                      </FlexContainer>
                   </FlexContainer>
                   <FlexContainer
                     style={{ marginBottom: '40px', marginTop: '20px' }}
                     gap="30px"
                     justify="center">
                      <ButtonNew
                        theme="gray"
                        style="transparant"
                        onClick={Events.closePopupClickHandler}>
                         {t('cancel')}
                      </ButtonNew>

                      {formType == 'create' && (
                        <ButtonNew theme="green" onClick={Events.createButtonClickHandler}>
                           {t('create')}
                        </ButtonNew>
                      )}
                      {formType == 'edit' && (
                        <ButtonNew theme="green" onClick={Events.editButtonClickHandler}>
                           {t('edit')}
                        </ButtonNew>
                      )}
                   </FlexContainer>
                </FlexContainer>
             </Popup>
           )}
        </MainContainer>
     </Container>
   )
}

export default IndexPage
