import styled from 'styled-components'
import { Assets } from '../../assets'
import { BORDER, colors, FONT } from "../../styles";

export const Container = styled.div`
   display: inline-flex;
   position: relative;
`

export const DateInputTemplate = styled.input.attrs({ type: 'date' })`
   width: 150px;
   height: 45px;

   ${BORDER({ color: colors.lines })}
   border-radius: 8px;
   padding-left: 46px;

   ${FONT({ color: colors.gray, size: '16px' })}
   position:relative;

   &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
   }
`

export const CalendarIcon = styled.img.attrs({
   src: Assets.CALENDAR
})`
   position: absolute;

   top: 10px;
   left: 14px;
`
