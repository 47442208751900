import React, { SyntheticEvent, useEffect, useMemo } from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
	ButtonNew,
	Divider,
	Table,
	useTypedSelector
} from "../../../../common";
import { api } from '../../../../common/config/api';

import {
	Image,
	MainContainer,
	ColorBlock
} from "./styled";
import { getMainProductSelector, productActions } from "../../../../store";
import {useParams} from "react-router-dom";
import {getLocale} from "../../../../types";

const VariationList = (
	{
		handleActiveEditVariation
	}: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { id } = useParams();
	const local = getLocale();

	const { productsGroup, newProduct } = useTypedSelector(getMainProductSelector);

	const Requests = {
		removeProduct: (_id: string[]) => {
			dispatch(productActions.removeProduct({_id}))
		},
		getGroupProducts: () => {
			dispatch(productActions.getProductsGroup({
				field: 'group._id',
				value: newProduct?.group._id
			}))
		}
	};

	const getGroupProductsList = async () => {
		await dispatch(productActions.getProduct({_id: id as string, lang: local}));
		await Requests.getGroupProducts()
	}

	const Events = {
		removeProductHandler: (e: SyntheticEvent, index: number) => {
			Requests.removeProduct([productsGroup?.data[index]._id]);
			Requests.getGroupProducts();
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: t('id'),
				accessor: 'id', // accessor is the "key" in the data
				width: 50
			},
			{
				Header: t('photo'),
				accessor: 'photo',
				width: 300
			},
			{
				Header: t('name'),
				accessor: 'name',
				width: 300
			},
			{
				Header: t('date'),
				accessor: 'date',
				width: 250
			},
			{
				Header: t('total'),
				accessor: 'total',
				width: 200
			},
			{
				Header: t('color'),
				accessor: 'color',
				width: 200
			},
			{
				Header: t('text'),
				accessor: 'text',
				width: 200
			},
			{
				Header: t('price'),
				accessor: 'price',
				width: 200
			},
			{
				Header: t('status'),
				accessor: 'status',
				width: 400
			}
		],
		[]
	);

	useEffect(() => {
		getGroupProductsList()
	}, []);

	const colorTypeProduct = productsGroup?.data?.map((el:any) => el.variations.filter((el:any) => el.variation.type === 'color')[0]);
	const textTypeProduct = productsGroup?.data?.map((el:any) => el.variations.filter((el:any) => el.variation.type === 'text')[0]);

	const data = useMemo(
		() =>
			productsGroup?.data?.map((v: any, index: number) => {
				return {
					id: (<>{v?.sku}</>),
					photo: (<Image style={{width: '40px', height: '40'}} src={`${api.withImageAPI}/product/preview/${v?.preview}`}/>),
					name: (<>{v?.description?.title}</>),
					date: (<>{moment(v?.createdAt).format('YYYY-MM-DD HH:mm')}</>),
					total: (<>{v?.amount}</>),
					color: (<ColorBlock style={{backgroundColor: `${colorTypeProduct[index]?.value?.bgUrl}`}}/>),
					text: (<>{textTypeProduct[index]?.value?.title[0]?.title ?? '-'}</>),
					price: (<>{v?.price}</>),
					status: (<>{t(v?.sellStatus)}</>),
				}
			}),
		[productsGroup]
	);

	return (
		<MainContainer>
			<Table
				columns={columns}
				data={data}
				removable
				removeClickHandler={Events.removeProductHandler}
			/>
			<Divider height={30}/>
			<ButtonNew width={215} height={40} onClick={handleActiveEditVariation}>
				<span>{t('add.variation')}</span>
			</ButtonNew>
		</MainContainer>
	);
};

export default VariationList;