import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	ButtonNew,
	ColorPicker,
	DropDownList,
	PhotoPicker,
	useTypedSelector
} from "../../common";

import { getUserSelector } from "../../store/user";
import { getMainSettingSelector, settingAction } from '../../store';
import useLocalization from "../../common/hooks/useLocalization";
import { TLocales } from '../../common/locales/types';
import { api } from "../../common/config/api";

import {
	Container,
	Header,
	MainContainer,
	Title,
	StyledDropDown,
} from "./styled";

const Settings = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

  const { permissions } = useTypedSelector(getUserSelector);
	const [language, setLanguage] = useLocalization();

	const options = [
		{value: 'ua', name: t('ua')},
		{value: 'de', name: t('de')},
		{value: 'en', name: t('en')},
	];

	const [formLanguage, setFormLanguage] = useState<any>(language);
	const { logo } = useTypedSelector(getMainSettingSelector);
	const [form, setForm] = useState({
		photo: null,
	});
	const [colorPicker, setColorPicker] = useState<any>({
		color: `${logo?.data?.mainColor}`
	});

	const Request = {
		editLogo: () => {
			const data = new FormData()
			colorPicker && data.append('mainColor', colorPicker?.color)
			form.photo && data.append('image', form.photo as File)

			dispatch(settingAction.editLogo({data}))
			dispatch(settingAction.getLogo({}))
		}
	};

	const Events = {
		onPhotoChange: (file: File | null) => {
			setForm({...form, photo: file as any})
		},
		clickHandler: () => {
			setLanguage(formLanguage)
			Request.editLogo()
		},
		langChangeHandler: (e: React.SyntheticEvent) => {
			const select = e.target as HTMLSelectElement
			const selected_item = select.value as TLocales

			setFormLanguage(selected_item)
		},
		handleChange: (color: any) => {
			setColorPicker({color: color?.hex})
		}
	};

	const config = permissions.filter((el:string) => el === 'config').join();
	if(config !== 'config') navigate('/404')

	return (
		<>
			<Container>
				<Header>
					<Title>{t('settings')}</Title>
					<ButtonNew onClick={Events.clickHandler} theme={'green'}>{t('save')}</ButtonNew>
				</Header>
				<MainContainer>
					<StyledDropDown>
						<DropDownList  label={t('lang')} options={options} onChange={Events.langChangeHandler} value={formLanguage}/>
					</StyledDropDown>
					<ColorPicker color={colorPicker?.color} onChange={Events.handleChange}/>
					<PhotoPicker
						width={150} height={150}
						label={t('app.photo')}
						onChange={Events.onPhotoChange}
						value={`${api.withImageAPI}/logo/${logo?.data?.logo}`}
					/>
				</MainContainer>
			</Container>
		</>
	);
};

export default Settings;
