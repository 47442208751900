import { createReducer } from '@reduxjs/toolkit'
import { bannerActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   banners: [],
   loading: false
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(bannerActions.setBanners, (state, { payload }) => ({ ...state, banners: payload }))
      .addCase(bannerActions.setBannerState, (state, { payload }) => ({ ...state, response: null, ...payload }))
})

export default reducer
