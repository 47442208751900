import { createReducer } from '@reduxjs/toolkit'
import { bonusActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   bonuses: {
      data: [],
      meta: null,
      loading: false
   }
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(bonusActions.setBonuses, (state, { payload }) => ({
         ...state,
         bonuses: { ...state.bonuses, ...payload }
      }))
      .addCase(bonusActions.getBonusesRequest, (state) => ({ ...state, bonuses: { ...state.bonuses } }))
      .addCase(bonusActions.setBonusesState, (state, { payload }) => ({
         ...state,
         bonuses: { ...state.bonuses, ...payload }
      }))
})

export default reducer
