import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({ justify: "space-between" })}
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexStyledContainer = styled.div`
  width: 650px;

  display: flex;
  justify-content: space-between;
`;

export const InputDeleteBonus = styled.div`
  width: 550px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DateBlock = styled.div`
  width: 450px;

  display: flex;
  justify-content: space-between;

  ${FONT({ weight: "400", size: "12px", color: colors.gray })};
`;

export const DateText = styled.p``;

export const Image = styled.img`
  cursor: pointer;
`;

export const Title = styled.h1`
  ${FONT({ weight: "700", size: "24px", color: colors.black })};

  margin-left: 14px;
  margin-right: 60px;
`;

export const MainContainer = styled.main`
  position: relative;

  max-width: 1609px;
  height: 925px;

  margin-top: 26px;

  padding: 30px 30px;

  background: white;

  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);

  border-radius: 22px;
`;

export const FormContainer = styled.div``;

export const FormStyled = styled.div`
  display: flex;
  @media (max-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

export const InputBlock = styled.div`
  width: 318px;
  margin-right: 62px;
  margin-top: 30px;
`;

export const PhotoBlock = styled.div`
  margin-top: 30px;
`;

export const Label = styled.p`
  margin-bottom: 8px;
`;

export const Photo = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 50%;
`;
