import React, { useState } from 'react'
import EditVariation from "../EditVariation/EditVariation";
import VariationList from '../VariationList/VariationList';

const Variations = (
	{
		form,
		formPhotos,
		productTypeOptions,
		newProduct,
		children,
		setResultPhoto,
		setProductGroup,
		statusOptions,
		visibilityOptions,
		productGroup,
		setTypeMethod,
		Events,
		removePhotoHandler,
		resultPhotos
	}: any) => {

  const [activeEditVariation, setActiveEditVariation] = useState(false);

	const handleActiveEditVariation = () => {
		setActiveEditVariation(true);
		setTypeMethod('create')
		setResultPhoto([])
	};

	return (
		<>
			{
				form.type !== productTypeOptions[1].value ? children :
					<>
						{ !activeEditVariation ?
							<VariationList
								handleActiveEditVariation={handleActiveEditVariation}
							/>
							:
						<EditVariation
							removePhotoHandler={removePhotoHandler}
							resultPhotos={resultPhotos}
							setProductGroup={setProductGroup}
							newProduct={newProduct}
							statusOptions={statusOptions}
							Events={Events}
							form={form}
							formPhotos={formPhotos}
							visibilityOptions={visibilityOptions}
							productGroup={productGroup}
						/>
						}
					</>
			}
		</>
	);
};

export default Variations
