import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { sectionActions } from './actions'
import { ApiSectionService } from './api.service'
import { SECTION_RESPONSE } from './consts'
import { TCreateSectionPayload, TEditSectionPayload, TRemoveSectionsPayload, TSectionPayload } from './types'
import {getAccessToken} from "../user";
import {showToastAction} from "../toasts";
import {generateErrorToast, generateSuccessToast} from "../../common";
import {i18n} from "../../common/config";
import {TGetDescriptionPayload} from "../category/types";

function* getSectionWorker({ payload }: TDataWrapper<TSectionPayload>) {
   yield put(sectionActions.setSectionState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiSectionService, ApiSectionService.getSections], { ...payload, token })
      if (response.data) yield put(sectionActions.setSections(response.data))
   } catch (e) {
      yield put(sectionActions.setSections({ data: [], meta: null }))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.section") as string)));
   }

   yield put(sectionActions.setSectionState({ loading: false }))
}

function* getDescriptionWorker({payload}: TDataWrapper<TGetDescriptionPayload>) {
   yield put(sectionActions.setSectionState({loading: true}))

   const {_id} = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiSectionService, ApiSectionService.getDescription], {_id, token})
      if (response.data) {
         yield put(sectionActions.setDescriptionSection(response.data))
         yield put(sectionActions.setSectionState({loading: false}))
      } else {
         yield put(sectionActions.setSectionState({loading: false }))
      }
   } catch (e) {
      yield put(sectionActions.setSectionState({loading: false }))
   }
}

function* createSectionWorker({ payload }: TDataWrapper<TCreateSectionPayload>) {
   yield put(sectionActions.setSectionState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { data } = payload

   try {
      yield call([ApiSectionService, ApiSectionService.createSection], { token, data })
      yield put(
        showToastAction.request(
          generateSuccessToast(i18n.t("section.was.created.successfully") as string),
        ),
      )
   } catch (e) {
      yield put(
        showToastAction.request(
          generateErrorToast(i18n.t("section.creating.error") as string),
        ),
      )
   }

   yield put(sectionActions.setSectionState({ loading: false, response: SECTION_RESPONSE.CREATED }))
}

function* editSectionWorker({ payload }: TDataWrapper<TEditSectionPayload>) {
   yield put(sectionActions.setSectionState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { data, id } = payload

   try {
      yield call([ApiSectionService, ApiSectionService.editSection], { token, data, id })
      yield put(showToastAction.request(generateSuccessToast(i18n.t("section.updated.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.update.section") as string)));
   }

   yield put(sectionActions.setSectionState({ loading: false, response: SECTION_RESPONSE.EDITED }))
}

function* removeSectionsWorker({ payload }: TDataWrapper<TRemoveSectionsPayload>) {
   yield put(sectionActions.setSectionState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { ids } = payload

   try {
      for (let id of ids) {
         yield call([ApiSectionService, ApiSectionService.removeSection], { id, token })
      }
      yield put(showToastAction.request(generateSuccessToast(i18n.t("section.removed.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.section") as string)));
   }

   yield put(sectionActions.setSectionState({ loading: false, response: SECTION_RESPONSE.REMOVED }))
}

export function* sectionWatcher() {
   yield takeLatest(sectionActions.getSections, getSectionWorker)
   yield takeLatest(sectionActions.createSection, createSectionWorker)
   yield takeLatest(sectionActions.editSection, editSectionWorker)
   yield takeLatest(sectionActions.removeSections, removeSectionsWorker)
   yield takeLatest(sectionActions.getDescriptionSection, getDescriptionWorker)
}
