import { call, put, takeLatest } from 'redux-saga/effects'
import { i18n } from '../../common/config'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { subcategoryActions, SUBCATEGORY_RESPONSE } from './actions'
import { ApiSubcategoryService } from './api.service'
import {
   TCreateSubcategoryPayload,
   TEditSubcategoryPayload,
   TGetSubcategoriesByCategoryPayload,
   TGetSubcategoriesPayload,
   TRemoveSubcategoriesPayload
} from './types'
import {showToastAction} from "../toasts";
import {ELocales, generateErrorToast, generateSuccessToast} from "../../common";
import {TGetDescriptionPayload} from "../category/types";

function* getSubcategoriesWorker({ payload }: TDataWrapper<TGetSubcategoriesPayload>) {
   yield put(subcategoryActions.setSubcategoryState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiSubcategoryService, ApiSubcategoryService.getSubcategories],
         {
            token,
            lang: i18n.language as ELocales,
            ...payload
         }
      )

      if (response.data.data)
         yield put(subcategoryActions.setSubcategories(response.data))
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.subcategories") as string)));
   }

   yield put(subcategoryActions.setSubcategoryState({ loading: false }))
}

function* getDescriptionWorker({payload}: TDataWrapper<TGetDescriptionPayload>) {
   yield put(subcategoryActions.setSubcategoryState({loading: true}))

   const {_id} = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiSubcategoryService, ApiSubcategoryService.getDescription], {_id, token})
      if (response.data) {
         yield put(subcategoryActions.setSubcategoryDescription(response.data))
         yield put(subcategoryActions.setSubcategoryState({loading: false}))
      } else {
         yield put(subcategoryActions.setSubcategoryState({loading: false }))
      }
   } catch (e) {
      yield put(subcategoryActions.setSubcategoryState({loading: false }))
   }
}

function* getSubcategoriesByCategoryWorker({ payload }: TDataWrapper<TGetSubcategoriesByCategoryPayload>) {
   yield put(subcategoryActions.setSubcategoryState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
        [ApiSubcategoryService, ApiSubcategoryService.getSubcategoriesByCategory],
        {
           token,
           ...payload
        }
      )
      if (response.data.data)
         yield put(subcategoryActions.setSubcategoriesByCategory(response.data))
   } catch (e) {
      yield put(subcategoryActions.setSubcategoriesByCategory({ data: [] }))
   }
}

function* createSubcategoryWorker({ payload }: TDataWrapper<TCreateSubcategoryPayload>) {
   yield put(subcategoryActions.setSubcategoryState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { data } = payload

   try {
      yield call([ApiSubcategoryService, ApiSubcategoryService.createSubcategory], {
         token,
         data
      })
      yield put(
        showToastAction.request(
          generateSuccessToast(i18n.t("subcategory.was.created.successfully") as string),
        ),
      )
   } catch (e) {
      yield put(
        showToastAction.request(
          generateErrorToast(i18n.t("subcategory.creating.error") as string),
        ),
      )
   }

   yield put(
      subcategoryActions.setSubcategoryState({
         loading: false,
         response: SUBCATEGORY_RESPONSE.CREATED
      })
   )
}

function* editSubcategoryWorker({ payload }: TDataWrapper<TEditSubcategoryPayload>) {
   yield put(subcategoryActions.setSubcategoryState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { data, _id } = payload

   try {
      yield call([ApiSubcategoryService, ApiSubcategoryService.editSubcategory], {
         token,
         data,
         _id
      })
      yield put(showToastAction.request(generateSuccessToast(i18n.t("subcategory.updated.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.update.subcategory") as string)));
   }

   yield put(
      subcategoryActions.setSubcategoryState({
         loading: false,
         response: SUBCATEGORY_RESPONSE.EDITED
      })
   )
}

function* removeSubcategoriesWorker({
   payload
}: TDataWrapper<TRemoveSubcategoriesPayload>) {
   yield put(subcategoryActions.setSubcategoryState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { _ids } = payload

   try {
      for (let _id of _ids) {
         yield call([ApiSubcategoryService, ApiSubcategoryService.removeSubcategory], {
            token,
            _id
         })
      }
      yield put(showToastAction.request(generateSuccessToast(i18n.t("subcategory.removed.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.subcategory") as string)));
   }

   yield put(
      subcategoryActions.setSubcategoryState({
         loading: false,
         response: SUBCATEGORY_RESPONSE.REMOVED
      })
   )
}

export function* subcategoryWatcher() {
   yield takeLatest(subcategoryActions.getSubcategories, getSubcategoriesWorker)
   yield takeLatest(subcategoryActions.createSubcategory, createSubcategoryWorker)
   yield takeLatest(subcategoryActions.editSubcategory, editSubcategoryWorker)
   yield takeLatest(subcategoryActions.removeSubcategories, removeSubcategoriesWorker)
   yield takeLatest(subcategoryActions.getSubcategoriesByCategory, getSubcategoriesByCategoryWorker)
   yield takeLatest(subcategoryActions.getSubcategoryDescription, getDescriptionWorker)
}
