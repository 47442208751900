import { createReducer } from '@reduxjs/toolkit'
import { deliveryActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
	delivery:{
		data: [],
		meta: null
	},
	deliveryRef: {
		data: [],
		meta: null
	},
	loading: false,
	response: null,
	deliveryWarehouse: {
		data: [],
		meta: null
	},
	calculate: null,
	deliveryConfig: {
		data: null
	}
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
	builder
		.addCase(deliveryActions.setDelivery, (state, { payload }) => ({ ...state, delivery: payload }))
		.addCase(deliveryActions.setDeliveryConfig, (state, { payload }) => ({ ...state, deliveryConfig: payload }))
		.addCase(deliveryActions.setDeliveryWarehouse, (state, { payload }) => ({ ...state, deliveryWarehouse: payload }))
		.addCase(deliveryActions.setCalculate, (state, { payload }) => ({ ...state, calculate: payload }))
		.addCase(deliveryActions.setDeliveryRef, (state, { payload }) => ({ ...state, deliveryRef: payload }))
		.addCase(deliveryActions.setDeliveryState, (state, { payload }) => ({ ...state, respones: null, ...payload }))
})

export default reducer
