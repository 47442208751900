import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { orderActions } from './actions'
import { ApiOrderService } from './api.service'
import { ORDER_RESPONSE, RESPONSE } from './consts'
import {
   TEditOrderPayload,
   TGetArchivePayload, TGetDeliveryPayload,
   TGetOrderPayload,
   TGetOrdersPayload, TGetStatisticPayload,
   TRemoveOrderRequestPayload
} from './types'
import {showToastAction} from "../toasts";
import {generateErrorToast, generateSuccessToast} from "../../common";
import {i18n} from "../../common/config";

function* getOrdersWorker({ payload }: TDataWrapper<TGetOrdersPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiOrderService, ApiOrderService.getOrders], { ...payload, token })
      if (response.data) yield put(orderActions.setOrders(response.data))
   } catch (e) {
      yield put(orderActions.setOrders({ data: [], meta: null }))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.orders") as string)));
   }

   yield put(orderActions.setOrderState({ loading: false }))
}

function* getDeliveryWorker({ payload }: TDataWrapper<TGetDeliveryPayload>) {
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiOrderService, ApiOrderService.getDelivery], { ...payload, token })
      if (response.data) yield put(orderActions.setDelivery(response.data))
   } catch (e) {
      yield put(orderActions.setDelivery({ data: [], meta: null }))
   }
}

export function* getOrderWorker({ payload }: TDataWrapper<TGetOrderPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))
   const token: string = yield call(getAccessToken)
   const { _id } = payload
   try {
      const response: TResponse = yield call([ApiOrderService, ApiOrderService.getOrder], { token, _id })
      if (response.data.data) yield put(orderActions.setOrder(response.data.data))
      yield put(orderActions.setOrderState({ loading: false, response: ORDER_RESPONSE.GET_ORDER_SUCCESS }))
   } catch (e) {
      yield put(orderActions.setOrderState({ loading: false, response: ORDER_RESPONSE.GET_ORDER_ERROR }))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.order") as string)));
   }
}

function* getArchiveWorker({ payload }: TDataWrapper<TGetArchivePayload>) {
   yield put(orderActions.setOrderState({ loading: true }))
   const token: string = yield call(getAccessToken)
   try {
      const response: TResponse = yield call([ApiOrderService, ApiOrderService.getArchive], { ...payload, token })
      if (response.data) yield put(orderActions.setArchive(response.data))
   } catch (e) {
      yield put(orderActions.setOrders({ data: [], meta: null }))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.archive") as string)));
   }

   yield put(orderActions.setOrderState({ loading: false }))
}

function* editOrderWorker({ payload }: TDataWrapper<TEditOrderPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))
   const token: string = yield call(getAccessToken)
   const { data, _id } = payload
   try {
      yield call([ApiOrderService, ApiOrderService.editOrder], { token, _id, data })
      yield put(orderActions.setOrderState({ loading: false, response: ORDER_RESPONSE.EDIT_SUCCESS }))
      yield put(showToastAction.request(generateSuccessToast(i18n.t("order.updated.successfully") as string)));
   } catch (e) {
      yield put(orderActions.setOrderState({ loading: false, response: ORDER_RESPONSE.EDIT_ERROR }))
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.update.order") as string)));
   }
}

function* removeOrderWorker({ payload }: TDataWrapper<TRemoveOrderRequestPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _ids } = payload

   try {
      for (let _id of _ids) {
         yield call([ApiOrderService, ApiOrderService.removeOrder], { token, _id })
      }
      yield put(orderActions.setOrders({ data: [], meta: { needReload: true } }))
      yield put(showToastAction.request(generateSuccessToast(i18n.t("order.removed.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.order") as string)));
   }

   yield put(orderActions.setOrderState({ loading: false, response: RESPONSE.REMOVED as any }))
}

function* getStatisticWorker({ payload }: TDataWrapper<TGetStatisticPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiOrderService, ApiOrderService.getStatistic], { ...payload, token })
      if (response.data) yield put(orderActions.setStatistic(response.data))
   } catch (e) {
      yield put(orderActions.setStatistic({ data: [], meta: null }))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.statistic") as string)));
   }

   yield put(orderActions.setOrderState({ loading: false }))
}

function* getGrafWorker({ payload }: TDataWrapper<TGetStatisticPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiOrderService, ApiOrderService.getGraf], { ...payload, token })
      if (response.data) yield put(orderActions.setGraf(response.data))
   } catch (e) {
      yield put(orderActions.setGraf({ data: [], meta: null }))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.statistic") as string)));
   }

   yield put(orderActions.setOrderState({ loading: false }))
}

export function* orderWatcher() {
   yield takeLatest(orderActions.getOrders, getOrdersWorker)
   yield takeLatest(orderActions.getOrder, getOrderWorker)
   yield takeLatest(orderActions.getArchive, getArchiveWorker)
   yield takeLatest(orderActions.editOrder, editOrderWorker)
   yield takeLatest(orderActions.removeOrder, removeOrderWorker)
   yield takeLatest(orderActions.getStatistic, getStatisticWorker)
   yield takeLatest(orderActions.getGraf, getGrafWorker)
   yield takeLatest(orderActions.getDelivery, getDeliveryWorker)
}
