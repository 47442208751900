export const GET_ORDERS = 'GET_ORDERS'
export const SET_ORDERS = 'SET_ORDERS'
export const GET_ORDER = 'GET_ORDER'
export const SET_ORDER = 'SET_ORDER'
export const EDIT_ORDER = 'EDIT_ORDER'
export const SET_ORDER_STATE = 'SET_ORDER_STATE'
export const GET_ARCHIVE = 'GET_ARCHIVE'
export const SET_ARCHIVE = 'SET_ARCHIVE'
export const REMOVE_ORDER = 'REMOVE_ORDER'
export const GET_STATISTIC = 'GET_STATISTIC'
export const SET_STATISTIC = 'SET_STATISTIC'
export const GET_GRAF = 'GET_GRAF'
export const SET_GRAF = 'SET_GRAF'
export const GET_DELIVERY = 'GET_DELIVERY'
export const SET_DELIVERY = 'SET_DELIVERY'

export enum ORDER_RESPONSE  {
   GET_ORDER_SUCCESS,
   GET_ORDER_ERROR,
   EDIT_SUCCESS,
   EDIT_ERROR,
}

export const RESPONSE = {
   NOT_FOUND: 'NOT_FOUND',
   CREATED: 'CREATED',
   EDITED:'EDITED',
   REMOVED:'REMOVED'
}
