import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";

import {
	Assets,
	colors,
	DropDownList,
	FlexContainer,
	PhotoPicker, RelativePreloader,
	Textarea
} from "../../../../common";
import { Input } from "../../../../common/components/Input";
import { PRODUCT_IMAGES_LENGTH } from "../../consts";
import { VariationType } from "../../../../store/product/types";
import { api } from "../../../../common/config/api";
import { VariationContainer } from '../VariationContainer';
import { FormBlock } from "../../../CreateProduct/styled";

import {
	FormContainer,
	MainContainer,
	Label,
	ImageClose,
	PhotoBlock
} from "../../styled";

const BasicData = (
	{
		Events,
		form,
		loading,
		brandOptions,
		formPhotos,
		variationsData,
		sectionOptions,
		productTypeOptions,
		visibilityOptions,
		statusOptions,
		categoryOptions,
		subCategoryOption,
		resultPhotos,
		removePhotoHandler,
		selectColor
	}: any) => {

	const { t } = useTranslation();

	const Utils = {
		totalPhotos: (): number => {
			return PRODUCT_IMAGES_LENGTH
		},
		totalValidPhotos: (): number => {
			return formPhotos.photos.length
		}
	};

	const variationTypeOptions = useMemo(
		() =>
			Object.keys(VariationType).map((prop) => ({
				name: t(prop),
				value: VariationType[prop as keyof typeof VariationType]
			})),
		[]
	);

	return (
		<MainContainer>
			<FormContainer>
				<FlexContainer gap="62px">
					<DropDownList
						name="type"
						label={t('product.type')}
						placeholder={t('enter.type.product')}
						options={productTypeOptions}
						value={form.type}
						onChange={Events.inputChangeHandler}
					/>
					{form.type === productTypeOptions[0].value && (
						<>
							<DropDownList
								name="show"
								label={t('visibility')}
								options={visibilityOptions}
								value={form.show}
								onChange={Events.inputChangeShowHandler}
							/>
							<Input
								name="sku"
								type={'number'}
								label={t('SKU')}
								placeholder={t('enter.code')}
								value={form.sku}
								onChange={Events.inputChangeHandler}
							/>
						</>
					)}
				</FlexContainer>
				<FormBlock>
					<Input
						name="nameEn"
						width="275px"
						label={t('name')}
						placeholder={t('enter.name.en')}
						value={form.nameEn}
						onChange={Events.inputChangeHandler}
					/>
					<Input
						name="nameUa"
						width="275px"
						label={t('name')}
						placeholder={t('enter.name.ua')}
						value={form.nameUa}
						onChange={Events.inputChangeHandler}
					/>
					<Input
						name="nameDe"
						width="275px"
						label={t('name')}
						placeholder={t('enter.name.de')}
						value={form.nameDe}
						onChange={Events.inputChangeHandler}
					/>
				</FormBlock>
				<FormBlock>
					<Textarea
						width='275px'
						name="descriptionEn"
						height="260px"
						placeholder={t('enter.description.en')}
						label={t('description')}
						onChange={Events.inputChangeHandler}
					>
						{form.descriptionEn}
					</Textarea>
					<Textarea
						width='275px'
						name="descriptionUa"
						height="260px"
						placeholder={t('enter.description.ua')}
						label={t('description')}
						onChange={Events.inputChangeHandler}>
						{form.descriptionUa}
					</Textarea>
					<Textarea
						width='275px'
						name="descriptionDe"
						height="260px"
						placeholder={t('enter.description.de')}
						label={t('description')}
						onChange={Events.inputChangeHandler}>
						{form.descriptionDe}
					</Textarea>
				</FormBlock>


				{form.type === productTypeOptions[0].value ?
					<FlexContainer gap="30px">
						<FlexContainer gap="62px">
							<Input
								name="price"
								type="number"
								label={t('price')}
								placeholder={t('enter.price')}
								value={form.price}
								onChange={Events.inputChangeHandler}
							/>
							<Input
								name="discountPrice"
								type="number"
								label={t('discount.price')}
								placeholder={t('enter.discount.price')}
								value={form.discountPrice}
								onChange={Events.inputChangeHandler}
							/>
						</FlexContainer>
						<FlexContainer gap="62px">
							<Input
								name="amount"
								label={t('amount')}
								placeholder={t('enter.amount')}
								value={form.amount}
								onChange={Events.inputChangeHandler}
							/>
							<DropDownList
								name="sellStatus"
								label={t('status')}
								options={statusOptions}
								placeholder={t('enter.status')}
								value={form.sellStatus}
								onChange={Events.inputChangeHandler}
							/>
						</FlexContainer>
					</FlexContainer>
					:
					<>
						<>
							{
								!loading ?	<VariationContainer
									selectColor={selectColor}
									variationsData={variationsData}
									variationTypeOptions={variationTypeOptions}
								/> : <RelativePreloader  loading={loading}/>
							}
						</>
						<FlexContainer gap="30px">
							<FlexContainer gap="62px">
								<Input
									name="price"
									type="number"
									label={t('price')}
									placeholder={t('enter.price')}
									value={form.price}
									onChange={Events.inputChangeHandler}
								/>
								<Input
									name="discountPrice"
									type="number"
									label={t('discount.price')}
									placeholder={t('enter.discount.price')}
									value={form.discountPrice}
									onChange={Events.inputChangeHandler}
								/>
							</FlexContainer>
							<FlexContainer gap="62px">
								<Input
									name="amount"
									label={t('amount')}
									placeholder={t('enter.amount')}
									value={form.amount}
									onChange={Events.inputChangeHandler}
								/>
								<DropDownList
									name="sellStatus"
									label={t('status')}
									options={statusOptions}
									placeholder={t('enter.status')}
									value={form.sellStatus}
									onChange={Events.inputChangeHandler}
								/>
							</FlexContainer>
						</FlexContainer>
					</>

				}
			</FormContainer>
			<FlexContainer direction="column" align="center" gap="">
				<FlexContainer direction="column" gap="30px" style={{marginTop: '8px', padding: '24px 30px'}}>
					<DropDownList
						name="section"
						label={t('section')}
						options={sectionOptions}
						value={form.section}
						placeholder={t('choose.category')}
						width="100%"
						onChange={Events.inputChangeHandler}
					/>
					<DropDownList
						name="category"
						label={t('category')}
						options={categoryOptions}
						value={form.category}
						placeholder={t('choose.category')}
						width="100%"
						onChange={Events.inputChangeHandler}
					/>
					<DropDownList
						name="subcategory"
						label={t('subcategory')}
						options={subCategoryOption}
						value={form.subcategory}
						placeholder={t('choose.subcategory')}
						width="100%"
						onChange={Events.inputChangeHandler}
					/>
					<DropDownList
						name="brand"
						label={t('brand')}
						options={brandOptions}
						value={form.brand}
						placeholder={t('choose.brand')}
						width="100%"
						onChange={Events.inputChangeHandler}
					/>
				</FlexContainer>
				<FlexContainer
					direction="column"
					style={{borderTop: `1px solid ${colors.lines}`, marginTop: '8px', padding: '24px 30px'}}>
					<Label>{t('photo')}</Label>
					<FlexContainer gap="28px">
						{
							resultPhotos?.map((photo:any, index:any) => (
								<PhotoBlock key={index}>
									<ImageClose onClick={() => removePhotoHandler(index)} src={Assets.CROSS}/>
									<PhotoPicker
										width={150} height={150}
										name="image"
										index={index}
										value={`${api.withImageAPI}/product/gallery/${photo}`}
									/>
								</PhotoBlock>
							))
						}
						{Utils.totalValidPhotos() < Utils.totalPhotos() && (
							<PhotoPicker
								width={150} height={150}
								name={'photo' + (Utils.totalValidPhotos() + 1)}
								onChange={Events.onPhotoChange}
							/>
						)}
					</FlexContainer>
				</FlexContainer>
			</FlexContainer>
		</MainContainer>
	);
};

export default BasicData;