import React, {
	FC,
	SyntheticEvent,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from "moment";

import {
	ButtonNew,
	DataTotal,
	DateInput,
	FiltersWrapper,
	FlexContainer,
	HeaderWrapper,
	Pagination,
	ProfileImage,
	SearchBar,
	Table,
	TSort,
	useTypedSelector
} from "../../common";
import {
	CLIENT_RESPONSE,
	clientActions,
	getMainClientSelector
} from "../../store";
import { api } from '../../common/config/api';
import { getUserSelector } from "../../store/user";

import {
	MainContainer,
	Container,
	Header,
	TitleBlock,
	FlexStyledContainer,
	ButtonBlock,
	Title,
	Photo
} from './styled';
import { ITEM_PER_PAGE } from './consts';

const Customer: FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { clients, response } = useTypedSelector(getMainClientSelector);
	const { permissions } = useTypedSelector(getUserSelector);

	const [page, setPage] = useState<number>(0)
	const [searchParams, setSearchParams] = useSearchParams({})
	const [searchForm, setSearchForm] = useState({
		searchValue: '',
		dateStart: '',
		dateEnd: ''
	});
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

	const [sortParamsForm, setSortParamsForm] = useState({
		sortBy: '',
		order: '' as TSort
	});

	const tableSortBy = useMemo(() => {{
			if (!sortParamsForm.order) {
				return { id: null, desc: false }
			}
			return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }}
	}, [sortParamsForm]);

	const Requests = {
		getClients: () => {
			dispatch(clientActions.getClients({
				limit: ITEM_PER_PAGE,
				page,
				regex: searchForm.searchValue,
				start: searchForm.dateStart,
				end: searchForm.dateEnd,
				...sortParamsForm
			}))
		},
		removeClients: (_id: string) => {
			dispatch(clientActions.removeClient({_id}))
		},
	};

	const Events = {
		onPageChangeHandler: ({ selected }: { selected: number }) => {
			setPage(selected)
		},
		onChangeHandler: (e: React.SyntheticEvent) => {
			const input = e.target as HTMLInputElement
			setSearchForm({ ...searchForm, [input.name]: input.value })
			Requests.getClients()
		},
		onSubmitSearchHandler: (e: SyntheticEvent) => {
			e.preventDefault()
			Requests.getClients()
		},
		sortToggleHandler: (sortBy: string, order: TSort) => {
			setSortParamsForm({ sortBy, order })
		},
		createButtonClickHandler: () => {
			navigate('./create')
		},
		editButtonClickHandler: (e: SyntheticEvent, index: number) => {
			clients && navigate(`/customer/profile/${clients.data[index]._id}`)
		},
		checkboxClickHandler: (e: SyntheticEvent, hasCheckboxesActiveState: boolean, ckeckedItemsIndex: number[]) => {
			setRemoveButtonState(hasCheckboxesActiveState)
			setSelectedItems(ckeckedItemsIndex)
		},
		removeClients: (e: SyntheticEvent, index: number) => {
			const { _id } = clients?.data[index]
			Requests.removeClients(_id)
		},
		removeCheckedItemsHandler: () => {
			selectedItems.forEach((index) => {
				const { _id } = clients?.data[index]
				Requests.removeClients(_id)
			})
			setRemoveButtonState(false)
			setSelectedItems([])
		}
	};

	const data = useMemo(
		() =>
			clients.data.map((client:any) => {
				return {
					id: client.id,
					photo: <>
							{
								client?.picture?.includes('https') ? <Photo src={client?.picture} />
									: <ProfileImage src={`${api.withImageAPI}/customer/${client?.picture}`} />
							}
						</>,
					name: client.name ?? '-',
					contacts: client.email,
					createdAt: moment(client.createdAt).format("DD.MM.YYYY HH:mm"),
					client_status: client?.group?.name ?? '-',
					orders: client.orders
				}
			}),
		[clients]
	);

	const columns = useMemo(
		() => [
			{
				Header: t('clients.id'),
				accessor: 'id', // accessor is the "key" in the data
				width: 150
			},
			{
				Header: t('photo'),
				accessor: 'photo',
				width: 50
			},
			{
				Header: t('client'),
				accessor: 'name',
				sortToggleHandler: Events.sortToggleHandler,
				width: 300
			},
			{
				Header: t('contacts'),
				accessor: 'contacts',
				width: 250
			},
			{
				Header: t('registration.date'),
				accessor: 'createdAt',
				width: 200,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('client.status'),
				accessor: 'client_status',
				width: 200
			},
			{
				Header: t('count.orders'),
				accessor: 'orders'
			}
		],
		[]
	);

	useEffect(() => {
		Requests.getClients()
		setSearchParams({ ...searchParams, page: '' + page })
	}, [page, sortParamsForm, searchForm]);

	// Set default page uri
	useEffect(() => {
		setPage(!isNaN(parseInt(searchParams.get('page') as string)) ? parseInt(searchParams.get('page') as string) : 0)
	}, [searchParams]);

	useEffect(() => {
		if (response == CLIENT_RESPONSE.REMOVED) {
			Requests.getClients()
		}
	}, [response]);

	const client = permissions.filter((el:string) => el === 'client').join();
	if(client !== 'client') navigate('/404')
	return (
		<Container>
			<Header>
				<TitleBlock>
					<Title>{t('manage.clients')}</Title>
				</TitleBlock>
				<FlexStyledContainer>
					{removeButtonState && (
						<ButtonBlock>
							<ButtonNew theme="red" onClick={Events.removeCheckedItemsHandler}>
								{t('remove.all')}
							</ButtonNew>
						</ButtonBlock>
					)}
				</FlexStyledContainer>
			</Header>
			<MainContainer>
				<FlexContainer gap="30px" direction="column">
					<HeaderWrapper>
						<FiltersWrapper>
							<SearchBar
								name="searchValue"
								placeholder={t('search')}
								value={searchForm.searchValue}
								onChange={Events.onChangeHandler}
								onSubmit={Events.onSubmitSearchHandler}
							/>

							<DateInput name="dateStart" value={searchForm.dateStart} onChange={Events.onChangeHandler} />
							<DateInput name="dateEnd" value={searchForm.dateEnd} onChange={Events.onChangeHandler} />
						</FiltersWrapper>

						<DataTotal>
							{t('total.client')} {clients.meta?.totalCount}
						</DataTotal>
					</HeaderWrapper>

					<Table
						columns={columns}
						data={data}
						sortBy={tableSortBy}
						editable
						editClickHandler={Events.editButtonClickHandler}
						checkboxClickHandler={Events.checkboxClickHandler}
					/>
				</FlexContainer>

				<Pagination
					page={page}
					pageCount={clients.meta ? Math.ceil(clients.meta.totalCount / ITEM_PER_PAGE) : 0}
					onPageChange={Events.onPageChangeHandler}
				/>
			</MainContainer>
		</Container>
	)
}

export default Customer
