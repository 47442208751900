import { createAction } from '@reduxjs/toolkit'
import { TStatePayload } from '../types'
import {
   GET_BONUSES_REQUEST,
   SET_BONUSES_STATE,
   REMOVE_BONUS_REQUEST,
   SAVE_BONUSES_REQUEST,
   SET_BONUSES
} from './consts'
import { TBonus, TBonuses, TDeleteBonusPayload } from './types'

export const bonusActions = {
   getBonusesRequest: createAction(GET_BONUSES_REQUEST),
   setBonusesState: createAction(SET_BONUSES_STATE, (payload: TStatePayload) => ({ payload })),
   setBonuses: createAction(SET_BONUSES, (payload: TBonuses) => ({ payload })),
   saveBonusesRequest: createAction(SAVE_BONUSES_REQUEST, (payload: TBonus[]) => ({ payload })),
   deleteBonusRequest: createAction(REMOVE_BONUS_REQUEST, (payload: TDeleteBonusPayload) => ({ payload }))
}
