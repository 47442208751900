import { createReducer } from '@reduxjs/toolkit'
import { subcategoryActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   subcategories: {
      meta: null,
      data: []
   },
   loading: false,
   response: null,
  subCategoriesByCategory: {
     data: [],
     meta: null
  },
  description: {
     data: [],
     meta: null
  }
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(subcategoryActions.setSubcategories, (state, { payload }) => ({ ...state, subcategories: payload }))
      .addCase(subcategoryActions.setSubcategoriesByCategory, (state, { payload }) => ({ ...state, subCategoriesByCategory: payload }))
      .addCase(subcategoryActions.setSubcategoryDescription, (state, { payload }) => ({ ...state, description: payload }))
      .addCase(subcategoryActions.setSubcategoryState, (state, { payload }) => ({
         ...state,
         response: null,
         ...payload
      }))
})

export default reducer
