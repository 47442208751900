import { FC } from 'react';

import { Label } from '../Styled'
import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from '../../../store';

import { Container, Option, SelectList } from './styled';
import { TDropDownList } from './types';

const DropDownList: FC<TDropDownList> = ({
   name = '',
   label,
   options,
   value = '',
   onChange,
   placeholder,
   width = '318px',
   height = '45px',
  disabled = false
}) => {
   const { logo } = useTypedSelector(getMainSettingSelector);
   return (
      <Container width={width}>
         {label && <Label>{label}</Label>}
         <SelectList disabled={disabled} color={logo?.data?.mainColor} name={name} onChange={onChange} value={value} height={height}>
            <Option value="" disabled>
               {placeholder}
            </Option>

            {options.map((option, index) => (
               <Option key={index} value={option.value}>
                  {option.name}
               </Option>
            ))}
         </SelectList>
      </Container>
   )
}

export default DropDownList
