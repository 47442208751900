import { createReducer } from "@reduxjs/toolkit";

import { TInitialState } from "./types";
import {
	createShopAction,
	getShopAction,
	shopAction
} from "./actions";

const InitialState: TInitialState = {
	shops: [],
	loading: false,
	newShop: null,
	response: null
}

const reducer = createReducer<TInitialState>(InitialState, builder => {
	builder.addCase(shopAction.setShopState, (state, { payload }) => ({ ...state, response: null, ...payload }))
	builder.addCase(getShopAction.request, state => ({
		...state,
		loading: true,
	}));
	builder.addCase(getShopAction.success, (state, {payload}) => ({
		...state,
		shops: payload.shops,
		loading: false,
	}));
	builder.addCase(getShopAction.failure, (state, {payload}) => ({
		...state,
		error: payload,
		loading: false,
	}));

	builder.addCase(createShopAction.request, state => ({
		...state,
		loading: true,
	}));
	builder.addCase(createShopAction.success, (state, {payload}) => ({
		...state,
		newShop: payload.data,
		loading: false,
	}));
	builder.addCase(createShopAction.failure, (state, {payload}) => ({
		...state,
		error: payload,
		loading: false,
	}));
})

export default reducer