import { createReducer } from '@reduxjs/toolkit'
import { userActions } from './action'
import { TInitialState } from './types'

const initialState: TInitialState = {
   name: null,
   secondName: null,
   accessToken: null,
   refreshToken: null,
   login: null,
   exp: null,
   role: null,
   image: null,
   permissions: []
}

const reducer = createReducer<TInitialState>(initialState, (builder) => {
   builder
      .addCase(userActions.auth, (state, { payload }) => ({
         ...state,
         ...payload
      }))
      .addCase(userActions.logout, (state) => ({
         ...state,
         login: null,
         accessToken: null,
         refreshToken: null,
         fullName: null,
         exp: null,
         permissions: []
      }))
})

export default reducer
