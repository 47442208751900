import styled from "styled-components";

import { colors, FLEX, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({ justify: "space-between" })}
`;

export const Title = styled.h1`
  ${FONT({ weight: "700", size: "24px", color: colors.black })}
`;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;

  margin-top: 26px;

  padding: 20px 30px;

  background: white;

  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);

  border-radius: 22px;
`;

export const Wrapper = styled.div`
  margin-top: 20px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const FormBlock = styled.div`
  margin-right: 40px;
  width: 100%;
`;
