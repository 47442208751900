import styled, { css } from 'styled-components'
import { TButton } from './types'
import { FONT } from "../../styles";

export const Container = styled.div``

const ActiveButtonCss = css<any>`
   padding-bottom: 16px;
   
   color:  ${({ color }) => `${color}`};
   border-bottom: 3px solid ${({ color }) => `${color}`};
`

export const Button = styled.button<TButton>`
   cursor: pointer;
   height: 100%;
   border: none;
   background-color: transparent;
   margin-right: 60px;
   ${FONT({ size: '16px', weight: '500' })}

   &:hover {
      ${ActiveButtonCss}
   }
   ${({ active }) => active && ActiveButtonCss}
`
