import { FC } from 'react';
import ReactPaginate from 'react-paginate';

import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from "../../../store";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Styles
} from './styled';
import { TPagination } from './types';

const Pagination: FC<TPagination> = ({ page = undefined, pageCount = 1, onPageChange }) => {
  const { logo } = useTypedSelector(getMainSettingSelector);

   return (
      <Styles color={logo?.data?.mainColor}>
         <ReactPaginate
            previousLabel={<ArrowLeftIcon />}
            nextLabel={<ArrowRightIcon />}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            onPageChange={onPageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={page}
         />
      </Styles>
   )
}

export default Pagination
