import { createAction } from '@reduxjs/toolkit'
import {
   CREATE_ADMIN,
   EDIT_ADMIN,
   GET_ADMIN,
   GET_ADMINS,
   REMOVE_ADMIN,
   SET_ADMIN,
   SET_ADMINS,
   SET_ADMIN_STATE,
   SET_NEW_ADMIN,
} from './consts'
import {
   TCreateAdminPayload,
   TEditAdminPayload,
   TGetAdminPayload,
   TGetAdminsPayload,
   TRemoveAdminPayload,
   TStatePayload,
} from './types'

export const adminActions = {
   getAdmins: createAction(GET_ADMINS, (payload: TGetAdminsPayload) => ({payload})),
   setAdmins: createAction(SET_ADMINS, (payload) => ({payload})),
   createAdmin: createAction(CREATE_ADMIN, (payload: TCreateAdminPayload) => ({payload})),
   setAdmin: createAction(SET_ADMIN, (payload) => ({payload})),
   setNewAdmin: createAction(SET_NEW_ADMIN, (payload: any) => ({payload})),
   setAdminState: createAction(SET_ADMIN_STATE, (payload: TStatePayload) => ({ payload })),
   removeAdmin: createAction(REMOVE_ADMIN, (payload: TRemoveAdminPayload) => ({ payload })),
   getAdmin: createAction(GET_ADMIN, (payload: TGetAdminPayload) => ({ payload })),
   editAdmin: createAction(EDIT_ADMIN, (payload: TEditAdminPayload) => ({ payload }))
}
