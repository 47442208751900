import {AxiosResponse} from 'axios'
import {TResponse} from '../types'
import {TEditFeedbackRequestPayload, TGetFeedbacksRequestPayload, TRemoveFeedbackRequestPayload,} from './types'
import {FEEDBACK_URL} from "./config";
import { HttpService } from '../http.service';

export class ApiFeedbackService extends HttpService {
	static getFeedbacks(
		{
			token,
			limit = 10,
			page = 0,
			sortBy = '',
			order = '',
			date_start = '',
			date_end = '',
			search   = '',
			lang = 'ua'
		}: TGetFeedbacksRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${FEEDBACK_URL.getFeedbacks}`,
			method: 'GET',
			headers: {
				Authorization: token
			},
			params: {
				limit,
				skip: limit * page,
				sortBy,
				order,
				date_start,
				date_end,
				search,
				lang
			}
		})
	}

	static getFeedback({ _id, token }: TGetFeedbacksRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${FEEDBACK_URL.getFeedback}/${_id}`,
			method: 'GET',
			headers: {
				Authorization: token
			}
		})
	}

	static removeFeedback({ token, _id }: TRemoveFeedbackRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: `${FEEDBACK_URL.removeFeedback}/${_id}`,
			method: 'DELETE',
			headers: {
				Authorization: token
			}
		})
	}

	static editFeedback({ token, _id, data }: TEditFeedbackRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: `${FEEDBACK_URL.editFeedback}/${_id}`,
			method: 'PATCH',
			data: data,
			headers: {
				Authorization: token
			}
		})
	}

	static getFeedbacksProduct({_id, token}: TGetFeedbacksRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${FEEDBACK_URL.getFeedbacksProduct}/${_id}/byProduct`,
			method: 'GET',
			headers: {
				Authorization: token
			},
		})
	}
}
