import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { feedbackActions } from './action'
import { ApiFeedbackService } from './api.service'
import { getAccessToken } from "../user";
import { TEditFeedbackPayload, TFeedbackResponse, TGetFeedbackPayload, TRemoveFeedbackRequestPayload } from "./types";
import { TGetClientPayload } from "../client/types";
import { RESPONSE } from "./consts";
import { showToastAction } from "../toasts";
import { generateErrorToast, generateSuccessToast } from "../../common";
import { i18n } from "../../common/config";

function* getFeedbacksWorker({payload}: TDataWrapper<TGetFeedbackPayload>) {
	yield put(feedbackActions.setFeedbackState({loading: true}))
	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiFeedbackService, ApiFeedbackService.getFeedbacks], {...payload, token})
		if (response.data) yield put(feedbackActions.setFeedbacks(response.data))
	} catch (e) {
		yield put(feedbackActions.setFeedbacks({data: [], meta: null}))
		yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.feedbacks") as string)));
	}

	yield put(feedbackActions.setFeedbackState({loading: false}))
}

function* getFeedbackWorker({payload}: TDataWrapper<TGetClientPayload>) {
	yield put(feedbackActions.setFeedbackState({loading: true}))

	const {_id} = payload
	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiFeedbackService, ApiFeedbackService.getFeedback], {_id, token})
		if (response.data.data) {
			yield put(feedbackActions.setFeedback({...response.data.data}))
			yield put(feedbackActions.setFeedbackState({loading: false}))
		} else {
			yield put(feedbackActions.setFeedbackState({loading: false, response: RESPONSE.NOT_FOUND as TFeedbackResponse}))
		}
	} catch (e) {
		yield put(feedbackActions.setFeedbackState({loading: false, response: RESPONSE.NOT_FOUND as TFeedbackResponse}))
		yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.feedback") as string)));
	}
}

function* removeFeedbackWorker({ payload }: TDataWrapper<TRemoveFeedbackRequestPayload>) {
	yield put(feedbackActions.setFeedbackState({ loading: true }))
	const token: string = yield call(getAccessToken)

	const { _ids } = payload

	try {
		for (let _id of _ids) {
			yield call([ApiFeedbackService, ApiFeedbackService.removeFeedback], { token, _id })
		}
		yield put(feedbackActions.setFeedbacks({ data: [], meta: { needReload: true } }))
		yield put(showToastAction.request(generateSuccessToast(i18n.t("feedback.removed.successfully") as string)));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.feedback") as string)));
	}

	yield put(feedbackActions.setFeedbackState({ loading: false, response: RESPONSE.REMOVED as TFeedbackResponse }))
}

function* editFeedbackWorker({ payload }: TDataWrapper<TEditFeedbackPayload>) {
	yield put(feedbackActions.setFeedbackState({ loading: true }))

	const { _id, data } = payload
	const token: string = yield call(getAccessToken)

	try {
		yield call([ApiFeedbackService, ApiFeedbackService.editFeedback], { _id, data, token })
		yield put(showToastAction.request(generateSuccessToast(i18n.t("feedback.updated.successfully") as string)));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.update.feedback") as string)));
	}

	yield put(feedbackActions.setFeedbackState({ loading: false }))
}

function* getFeedbacksProductWorker({payload}: TDataWrapper<TGetFeedbackPayload>) {
	yield put(feedbackActions.setFeedbackState({loading: true}))

	const {_id} = payload
	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiFeedbackService, ApiFeedbackService.getFeedbacksProduct], { _id, token})
		if (response.data) yield put(feedbackActions.setFeedbacksProduct(response.data))
	} catch (e) {
		yield put(feedbackActions.setFeedbacksProduct({data: [], meta: null}))
	}

	yield put(feedbackActions.setFeedbackState({loading: false}))
}

export function* feedbackWatcher() {
	yield takeLatest(feedbackActions.getFeedbacks, getFeedbacksWorker)
  yield takeLatest(feedbackActions.getFeedback, getFeedbackWorker)
	yield takeLatest(feedbackActions.removeFeedback, removeFeedbackWorker)
	yield takeLatest(feedbackActions.editFeedback, editFeedbackWorker)
	yield takeLatest(feedbackActions.getFeedbacksProduct, getFeedbacksProductWorker)
}

