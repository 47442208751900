import styled from 'styled-components'
import { colors, FLEX, FONT } from "../../styles";
import { Assets } from "../../assets";

export const Container = styled.div`
   ${FLEX({})}
   position: relative;
`

export const SearchBarTemplate = styled.input`
   width: 273px;

   margin: 1px;
   padding: 13px 14px;

   outline: none;

   border: 2px solid #D1D1D1;
   border-radius: 8px;

   background: ${colors.white};

   ${FONT({
      size: '14px',
      weight: '700',
      color: colors.black,
   })}

   transition: 0.2s;

   &:focus {
      border: 2px solid  ${({ color }) => `${color}`};
      background: ${colors.white};
   }

   &::placeholder {
      ${FONT({
         size: '16px',
         weight: '400',
         color: colors.gray,
      })}
   }
`

export const SearchIcon = styled.img.attrs({
   src: Assets.SEARCH
})`
   position: absolute;
   right: 13px;
`
