import { FC } from 'react';
import { HashRouter } from 'react-router-dom';

import { useTypedSelector } from '../common';
import { LayoutComponent } from '../pages/Layout';
import { getUserSelector } from "../store/user";

import AuthRoutes from './AuthRoutes';
import NotAuthRoutes from './NotAuthRoutes';

const Router: FC = () => {
	const { accessToken } = useTypedSelector(getUserSelector);

	return (
		<HashRouter basename="/">
			{accessToken ? (
				<LayoutComponent>
					<AuthRoutes />
				</LayoutComponent>
			) : (
				<NotAuthRoutes />
			)}
		</HashRouter>
	);
};

export default Router;