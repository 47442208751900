import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocale } from '../../types';
import { ELocales } from '../enums';

import { ua, de, en } from '../locales'

const locale = getLocale();

i18n.use(initReactI18next).init({
    resources: {
        en: { translations: en },
        ua: { translations: ua },
        de: { translations: de },

    },
    fallbackLng: ELocales.default,
    lng: locale,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },

});

export default i18n;