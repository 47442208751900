import React, {
	FC,
	SyntheticEvent,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";

import {
	brandActions,
	categoryActions,
	getMainBrandSelector,
	getMainCategorySelector,
	getMainProductSelector,
	getMainSectionSelector,
	getMainSettingSelector,
	getMainSubcategorySelector,
	productActions,
	sectionActions,
	showToastAction,
	subcategoryActions
} from "../../store";

import {
	Assets,
	ButtonNew,
	EColors,
	generateErrorToast,
	Preloader,
	useTypedSelector,
	useValidation
} from "../../common";

import {
	BasicData,
	ProductCharacteristics,
	Variations
} from './components';

import {
	Image,
	Container,
	Wrapper,
	Header,
	Title,
	TitleBlock,
	Button,
	SubBarBlock,
	DateBlock,
	DateText,
	FlexStyledContainer,
	Message
} from './styled'
import { getLocale } from "../../types";
import { TCreateProductValidationSchema, TForm } from "./types";

const EditProduct: FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const local = getLocale();

	const menuSections = [
		{
			title: t('main.data'),
			type: 'mainData',
			active: false
		},
		{
			title: t('characteristics'),
			type: 'characteristics',
			active: false
		},
		{
			title: t('variations'),
			type: 'variations',
			active: false
		}
	];

	const productTypeOptions = [
		{name: t('simple'), value: 'simple'},
		{name: t('variated'), value: 'variated'}
	];
	const visibilityOptions = [
		{name: t('show'), value: true},
		{name: t('not.show'), value: false}
	];
	const statusOptions = [
		{name: t('in.stock'), value: 'available'},
		{name: t('out.stock'), value: 'notavailable'}
	];

	const [variationsData, setVariationsData] = useState([
		{
			order: 0,
			title: [{title: 'Color', lang: 'en'}],
			type: '',
			values: [
				{
					order: 0,
					bgUrl: EColors.default,
					color: EColors.default,
					title: [
						{title: '', lang: 'en'}
					],
				}
			]
		}
	]);
	const [typeMethod, setTypeMethod] = useState('update');
	const [formPhotos, setFormPhotos] = useState({
		photos: [] as File[]
	});
	const [productGroup, setProductGroup] = useState([
		{ variation: '', value: ''}
	]);
  const [activePhotoOnChange, setActivePhotoOnChange] = useState(false)
	const [activePage, setActivePage] = useState(menuSections[0].type);
	const [resultPhotos, setResultPhoto] = useState([])
	const [form, setForm] = useState<TForm>({
		type: productTypeOptions[0].value,
		show: visibilityOptions[0].value,
		sku: '',
		nameDe: '',
		nameUa: '',
		nameEn: '',
		descriptionEn: '',
		descriptionUa: '',
		descriptionDe: '',
		category: '',
		subcategory: '',
		price: '',
		amount: '',
		discountPrice: '',
		section: '',
		sellStatus: '',
		brand: '',
	});

	const { categoryBySection } = useTypedSelector(getMainCategorySelector);
	const { subCategoriesByCategory } = useTypedSelector(getMainSubcategorySelector);
	const { newProduct, photoProduct, description, products, loading } = useTypedSelector(getMainProductSelector);
	const { brands } = useTypedSelector(getMainBrandSelector);
	const { logo } = useTypedSelector(getMainSettingSelector);
	const { sections } = useTypedSelector(getMainSectionSelector);

	const [formCharacteristicUa, setFormCharacteristicUa] = useState<any>([]);
	const [formCharacteristicEn, setFormCharacteristicEn] = useState<any>([]);
	const [formCharacteristicDe, setFormCharacteristicDe] = useState<any>([]);

	useEffect(() => {
		if(description){
			setFormCharacteristicUa(description.data[1]?.attributes);
			setFormCharacteristicEn(description.data[0]?.attributes);
			setFormCharacteristicDe(description.data[2]?.attributes);
		}
	}, [description]);

	const sectionOptions = useMemo(() => sections.data.map((section) => ({
		name: section.title,
		value: section._id
	})), [sections]);
	const categoryOptions = useMemo(() => categoryBySection.data.map((category:any) => ({
		name: category.title,
		value: category._id
	})), [categoryBySection]);
	const subCategoryOption = useMemo(() => subCategoriesByCategory.data.map((subCategory: any) => ({
		name: subCategory.title,
		value: subCategory._id
	})), [subCategoriesByCategory]);
	const brandOptions = useMemo(() => brands?.data?.map((brand:any) => ({
		name: brand?.title,
		value: brand?._id
	})), [categoryBySection]);

	const selectColor = variationsData?.filter(el => el.type=="color").reduce(
		(store: Array<string>, prev) => [
			...store,
			...(prev?.values?.map(item => item.color) ?? []),
		],
		[],
	)

	const Requests = {
		removeProduct: (_id: string[]) => {
			dispatch(productActions.removeProduct({_id}))
		},
		createProductPhoto: () => {
			const data = new FormData()
			formPhotos && data.append('preview', formPhotos.photos[formPhotos.photos.length - 1])
			formPhotos && data.append('gallery', formPhotos.photos[formPhotos.photos.length - 1])
			formPhotos.photos && dispatch(productActions.createPhotoProduct({ data }));
			setActivePhotoOnChange(false);
		},
		editProduct: () => {
			if(form.type === productTypeOptions[1].value){
				const data = {
					sku: form.sku,
					sellStatus: form.sellStatus,
					price: +form.price,
					show: form.show,
					category: form.category,
					subCategory: form.subcategory === '' ? undefined : form.subcategory,
					type: form.type,
					gallery: galleryArray,
					discountPrice: form.discountPrice === '' ? 0 : +form.discountPrice,
					amount: form.amount,
					brand: form.brand,
					description: [
						{
							lang:'en',
							title: form.nameEn,
							description: form.descriptionEn,
							attributes: formCharacteristicEn
						},
						{
							lang:'ua',
							title: form.nameUa,
							description: form.descriptionUa,
							attributes: formCharacteristicUa
						},
						{
							lang:'de',
							title: form.nameDe,
							description: form.descriptionDe,
							attributes: formCharacteristicDe
						}
					],
					group: newProduct?.group?._id,
					dimensions: {
						weight: 0.1,
						width: 0.01,
						height: 0.01,
						length: 0.01
					}
				}
				dispatch(productActions.editProduct({data, _id: id as string}));
				navigate('/products-management');
			} else {
				const data = {
					sku: form.sku,
					sellStatus: form.sellStatus,
					price: +form.price,
					show: form.show,
					category: form.category,
					subCategory: form.subcategory === '' ? undefined : form.subcategory,
					type: form.type,
					gallery: galleryArray,
					discountPrice: form.discountPrice === '' ? 0 : +form.discountPrice,
					amount: +form.amount,
					brand: form.brand,
					description: [
						{
							lang:'en',
							title: form.nameEn,
							description: form.descriptionEn,
							attributes: formCharacteristicEn
						},
						{
							lang:'ua',
							title: form.nameUa,
							description: form.descriptionUa,
							attributes: formCharacteristicUa
						},
						{
							lang:'de',
							title: form.nameDe,
							description: form.descriptionDe,
							attributes: formCharacteristicDe
						}
					],
					dimensions: {
						weight: 0.1,
						width: 0.01,
						height: 0.01,
						length: 0.01
					}
				}
				dispatch(productActions.editProduct({data, _id: id as string}))
				navigate('/products-management')
			}
		},
		createProduct: () => {
			const data = {
				sku: form.sku,
				sellStatus: form.sellStatus,
				price: +form.price,
				show: form.show,
				category: form.category,
				subCategory: form.subcategory === '' ? undefined : form.subcategory,
				type: form.type,
				preview: photoProduct?.data?.preview,
				gallery: galleryArray,
				variations: productGroup,
				discountPrice: form.discountPrice === '' ? 0 : +form.discountPrice,
				amount: +form.amount,
				brand: form.brand,
				description: [
					{
						lang:'en',
						title: form.nameEn,
						description: form.descriptionEn,
						attributes: formCharacteristicEn
					},
					{
						lang:'ua',
						title: form.nameUa,
						description: form.descriptionUa,
						attributes: formCharacteristicUa
					},
					{
						lang:'de',
						title: form.nameDe,
						description: form.descriptionDe,
						attributes: formCharacteristicDe
					}
				],
				group: newProduct?.group?._id,
				dimensions: {
					weight: 0.1,
					width: 0.01,
					height: 0.01,
					length: 0.01
				}
			}
			dispatch(productActions.createProduct({ data }));
			navigate('/products-management')
		}
	};

	useEffect(() => {
		if(photoProduct){
			const data = photoProduct?.data?.gallery
			// @ts-ignore
			setResultPhoto((prevState) => ([...prevState, data]))
		}
	}, [photoProduct]);

	const filterPhoto = resultPhotos.flat().filter((el:File) => el !== undefined);

	const galleryArray = filterPhoto?.map((el:string, index:number) => {
		return {
			order: index,
			image: el
		}
	});

	const removePhotoHandler = (idx:number) => {
		setResultPhoto(resultPhotos.filter((el:string, index: number) => idx !== index))
	};

	const getProduct = async () => {
		await dispatch(productActions.getProduct({_id: id as string}))
		await dispatch(productActions.getDescription({_id: id}))
	};

	const SKU = products?.data?.map((el) => el.sku);

	useEffect(() => {
		if(+form.amount <= 0 ){
			setForm((form) => ({...form, sellStatus: statusOptions[1].value}))
		}
		if(form.amount > '0'){
			setForm((form) => ({...form, sellStatus: statusOptions[0].value}))
		}
	}, [form.amount])

	//eslint-disable-next-line
	const schema = useMemo<TCreateProductValidationSchema>(
		() => ({
			sku: {
				condition: !!form.sku && !SKU.includes(form.sku) && form.sku >= 0,
				error: t('enter.the.product.code.or.the.product.already.exists.with.this.code')
			},
			name:{
				condition: !!form.nameDe && !!form.nameEn && !!form.nameUa,
				error: t('enter.the.product.name.in.all.languages')
			},
			description: {
				condition: !!form.descriptionDe && !!form.descriptionUa && !!form.descriptionEn,
				error: t('enter.the.product.description.in.all.languages')
			},
			category: {
				condition: !!form.category,
				error: t('choose.a.category')
			},
			price: {
				condition: !!form.price && +form.price > 0,
				error: t('the.price.should.hit.more.than.zero')
			},
			amount: {
				condition: !!form.amount && form.amount >= 0,
				error: t('enter.amount')
			}
		}),
		[form]
	);

	const {
		validation,
		enableValidation,
		disableValidation,
		//eslint-disable-next-line
	} = useValidation(schema);

	const Events = {
		backButtonClickHandler: () => {
			navigate('../products-management')
		},
		inputChangeHandler: (e: SyntheticEvent) => {
			const {name, value} = e.target as HTMLInputElement
			setForm((props) => ({...props, [name]: value}))
		},
		inputChangeShowHandler: (e: SyntheticEvent) => {
			const {name, value} = e.target as HTMLInputElement;
			setForm((props) => ({...props, [name]: value === "true"}))
		},
		onPhotoChange: useCallback((file: File | null) => {
			file &&
			!formPhotos.photos.includes(file as File) &&
			setFormPhotos(() => ({ photos: [...formPhotos.photos].concat(file)}));
			setActivePhotoOnChange(true);
			activePhotoOnChange && Requests.createProductPhoto();
		}, [formPhotos]),
		editProductHandler: () => {
			Requests.editProduct()
		},
		createProductHandler: async () => {
			try {
				enableValidation();
				await validation();
				Requests.createProduct();
				disableValidation()
			} catch (error:any) {
				error?.map((err:any) => {
					dispatch(showToastAction.request(generateErrorToast(err)));
				});
			}
		},
		removeProductHandler: () => {
			Requests.removeProduct([newProduct._id])
			navigate('/products-management')
		}
	};

	useEffect(() => {
		getProduct()
	}, []);

	useEffect(() => {
		dispatch(productActions.getProducts({}))
		dispatch(brandActions.getBrands({}))
	}, [])

	useEffect(() => {
		if(form.section){
			dispatch(categoryActions.getCategoryBySection({_id: form.section, lang: local}))
		}
	}, [form.section]);

	useEffect(() => {
		if(form.category){
			dispatch(subcategoryActions.getSubcategoriesByCategory({_id: form.category, lang: local}))
		}
	}, [form.category]);

	useEffect(() => {
		dispatch(sectionActions.getSections({limit: 30, lang: local}))
	},[]);

	useEffect(() => {
		if (newProduct) {
			setForm({
				...form,
				sku: newProduct?.sku as any,
				price: newProduct?.price as any,
				amount: newProduct?.amount as any,
				section: newProduct?.section?._id,
				sellStatus: newProduct?.sellStatus,
				category: newProduct?.category?._id as any,
				nameEn: description?.data[0]?.title,
				nameUa: description?.data[1]?.title,
				nameDe: description?.data[2]?.title,
				descriptionEn: description?.data[0]?.description,
				descriptionUa: description?.data[1]?.description,
				descriptionDe: description?.data[2]?.description,
				subcategory: newProduct?.subCategory?.subCategory as any,
				show: newProduct?.show,
				type: newProduct?.type,
				discountPrice: newProduct?.discountPrice,
				brand: newProduct?.brand
			});
      setResultPhoto(() => newProduct?.gallery.map((photo:any) => (photo.image)))
		}
	}, [newProduct]);

	useEffect(() => {
		if(newProduct){
			setVariationsData(() => newProduct?.group?.variations?.map((variation:any, idx: number) => ({
				id: variation?.variation?._id,
				order: variation?.variation?.order ? variation?.variation?.order : idx,
				title: variation?.variation?.title[0]?.title,
				type: variation?.variation?.type,
				values: variation?.values?.map((value:any, idx: number) => ({
					id: value?._id,
					order: value?.order ? value?.order : idx,
					bgUrl: value?.bgUrl,
					color: value?.color,
					title: value?.title?.map((t:any) => ({
						title: t?.title,
						lang: t?.lang
					}))
				}))
			})));
		}
	}, [newProduct])

	useEffect(() => {
		if(description){
			setForm({
				...form,
				sku: newProduct?.sku,
				price: newProduct?.price,
				amount: newProduct?.amount,
				section: newProduct?.section?._id,
				sellStatus: newProduct?.sellStatus,
				category: newProduct?.category?._id,
				nameEn: description?.data[0]?.title,
				nameUa: description?.data[1]?.title,
				nameDe: description?.data[2]?.title,
				descriptionEn: description?.data[0]?.description,
				descriptionUa: description?.data[1]?.description,
				descriptionDe: description?.data[2]?.description,
				subcategory: newProduct?.subCategory?.subCategory,
				show: newProduct?.show,
				type: newProduct?.type,
				discountPrice: newProduct?.discountPrice,
				brand: newProduct?.brand
			})
		}
	}, [description]);

	useEffect(() => {
			setVariationsData(() => newProduct?.group?.variations?.map((variation:any, idx: number) => ({
				id: variation?.variation?._id,
				order: variation?.variation?.order ? variation?.variation?.order : idx,
				title: variation?.variation?.title[0]?.title,
				type: variation?.variation?.type,
				values: variation?.values?.map((value:any, idx: number) => ({
					id: value?._id,
					order: value?.order ? value?.order : idx,
					bgUrl: value?.bgUrl,
					color: value?.color,
					title: value?.title?.map((t:any) => ({
						title: t?.title,
						lang: t?.lang
					}))
				}))
			})));
	}, [])

	return (
		<>
			 <Container>
					<Header>
						<TitleBlock>
							<Image onClick={Events.backButtonClickHandler} src={Assets.ARROW_LEFT}/>
							<Title>{t('edit')}</Title>
							{
								typeMethod === 'update' &&
								<DateBlock>
									<DateText>{t('createdAt.date')} {moment(newProduct?.createdAt).format('YYYY-MM-DD HH:mm')}</DateText>
									<DateText>{t('updatedAt.date')} {moment(newProduct?.updatedAt).format('YYYY-MM-DD HH:mm')}</DateText>
								</DateBlock>
							}
						</TitleBlock>
						<FlexStyledContainer width={typeMethod === 'update' ? '450px' : '200px'}>
							{
								typeMethod === 'update' &&
								<ButtonNew
									onClick={Events.removeProductHandler}
									theme={'red'}
								> {t('delete')} </ButtonNew>
							}
							{
								typeMethod === 'update' ?
									<ButtonNew theme="green" height={46} onClick={Events.editProductHandler}>
										{t('save')}
									</ButtonNew> :
									<ButtonNew theme="green" height={46} onClick={Events.createProductHandler}>
										{t('create')}
									</ButtonNew>
							}
						</FlexStyledContainer>
					</Header>
					<Wrapper>
						<SubBarBlock>
							{menuSections.map((section, key) => (
								<Button color={logo?.data?.mainColor} key={key} value={activePage} onClick={() => {
									setActivePage(section.type)
								}} active={section.type === activePage && true}>
									{section.title}
								</Button>
							))}
						</SubBarBlock>
						<>
							{activePage === 'mainData' &&
								<>
									{
										!loading ?
											<BasicData
												loading={loading}
												selectColor={selectColor}
												brandOptions={brandOptions}
												description={description}
												removePhotoHandler={removePhotoHandler}
												resultPhotos={resultPhotos}
												formPhotos={formPhotos}
												sectionOptions={sectionOptions}
												newProduct={newProduct}
												productTypeOptions={productTypeOptions}
												visibilityOptions={visibilityOptions}
												statusOptions={statusOptions}
												Events={Events}
												variationsData={variationsData}
												setVariationsData={setVariationsData}
												form={form}
												categoryOptions={categoryOptions}
												subCategoryOption={subCategoryOption}
											/>: <Preloader loading={loading}/>
									}
									</>
							}
							{activePage === 'characteristics' &&
								<ProductCharacteristics
									formCharacteristicUa={formCharacteristicUa}
									setFormCharacteristicUa={setFormCharacteristicUa}
									formCharacteristicEn={formCharacteristicEn}
									setFormCharacteristicEn={setFormCharacteristicEn}
									formCharacteristicDe={formCharacteristicDe}
									setFormCharacteristicDe={setFormCharacteristicDe}
								/>
							}
							{activePage === 'variations' &&
								<Variations
									removePhotoHandler={removePhotoHandler}
									resultPhotos={resultPhotos}
									formPhotos={formPhotos}
									setTypeMethod={setTypeMethod}
									setProductGroup={setProductGroup}
									statusOptions={statusOptions}
									Events={Events}
									setResultPhoto={setResultPhoto}
									form={form}
									visibilityOptions={visibilityOptions}
									productGroup={productGroup}
									newProduct={newProduct}
									productTypeOptions={productTypeOptions}
								>	<Message>{t('select.product.type')}</Message> </Variations>
							}
						</>
					</Wrapper>
				</Container>
		</>
	)
}

export default EditProduct