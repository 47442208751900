import { createAction } from '@reduxjs/toolkit'
import {
   GET_AVAILABILITYS,
   SET_AVAILABILITYS,
   SET_AVAILABILITY_STATE,
   REMOVE_AVAILABILITY
} from './consts';
import {
   TGetAvailabilitysPayload,
   TSetAvailabilityPayload,
   TRemoveAvailability
} from './types';

export const availabilityActions = {
   getAvailabilitys: createAction(GET_AVAILABILITYS, (payload: TGetAvailabilitysPayload) => ({ payload })),
   setAvailabilitys: createAction(SET_AVAILABILITYS, (payload) => ({ payload })),
   setAvailabilityState: createAction(SET_AVAILABILITY_STATE, (payload: TSetAvailabilityPayload) => ({ payload })),
   removeAvailability: createAction(REMOVE_AVAILABILITY, (payload: TRemoveAvailability) => ({payload}))
}
