import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";

import {
	Container,
	Delete,
	Image,
	Name,
	VariationBlock,
	VariationsContainer,
	VariationsHeader,
	VariationsMain,
	VariationWrapper,
	DropdownListBlock
} from "../../styled";

import { VariationType } from "../../../../store/product/types";

import {
	Assets,
	ButtonNew,
	Divider,
	DropDownList, EColors,
	FlexContainer,
	Input
} from "../../../../common";
import { VariationValue } from "../index";
import {TValues, TVariationsData} from "../../types";

const VariationContainer = ({ variationsData, EventsVariations, setVariationData, selectColor }: any) => {
	const { t } = useTranslation();

	const removeVariationHandler = (id: string) => {
		setVariationData(variationsData.filter((el:TVariationsData) => el.order !== id))
	};

	const variationTypeOptions = useMemo(
		() =>
			Object.keys(VariationType).map((prop) => ({
				name: t(prop),
				value: VariationType[prop as keyof typeof VariationType]
			})),
		[]
	);

	const addValues = (id: number) => {
		setVariationData(variationsData.map((el:TVariationsData) => el.order === id ? {
			...el, values: [...el.values, {
				order: Math.random(),
				bgUrl: '',
				color: '',
				title: [
					{title: '', lang: 'en'}
				],
			}]
		} : el))
	};

	return (
		<Container className={'variations_form_container'}>
			{
				variationsData.map((variationTitle:any, index: number) => variationTitle && (
					<VariationWrapper className={'variation_form_container'} key={index}>
						<VariationsContainer>
							<VariationBlock>
								<VariationsHeader>
									<Name>{t('create.variation')}</Name>
									<Delete onClick={() => removeVariationHandler(variationTitle.order)}><Image src={Assets.CROSS}/>{t('delete')}</Delete>
								</VariationsHeader>
								<VariationsMain>
									<FlexContainer gap={'62px'} style={{padding: '10px 0'}} align={'center'}>
										<Input
											name="title"
											label={t('title')}
											placeholder={t('enter.title')}
											value={variationTitle?.title[0].title}
											onChange={(event) => EventsVariations.inputChangeHandler(event, variationTitle.order)}
										/>
										<DropdownListBlock>
											<DropDownList
												name="type"
												label={t('display.type')}
												options={variationTypeOptions}
												placeholder={t('enter.display.type')}
												value={variationTitle?.type}
												onChange={(event) => EventsVariations.inputChangeHandler(event, variationTitle.order)}
											/>
										</DropdownListBlock>
									</FlexContainer>
								</VariationsMain>
							</VariationBlock>
							<Container className={'variationsValue_form_container'}>
								{variationTitle.values.map((formVariations: TValues) => (
									<VariationValue
										variationsData={variationsData}
										setVariationData={setVariationData}
									  selectColor={selectColor}
										EventsVariations={EventsVariations}
										formVariations={formVariations}
										variationTitle={variationTitle}
									/>
								))
								}
								<ButtonNew onClick={() => addValues(variationTitle.order)} width={139} height={40}>
									<span>{t('add')}</span>
								</ButtonNew>
							</Container>
						</VariationsContainer>
						<Divider height={30}/>
					</VariationWrapper>
				))
			}
			{
				variationsData.length !== 2 ?
					<>
						<ButtonNew onClick={EventsVariations.onAddVariationClickHandler} width={139} height={40}>
							<span>{t('add')}</span>
						</ButtonNew>
						<Divider height={30}/>
					</>
					: ''
			}
			<ButtonNew onClick={
				EventsVariations.createVariationBulk
			} theme={'green'} width={139} height={40}>
				<span>{t('save')}</span>
			</ButtonNew>
		</Container>
	);
};

export default VariationContainer;