import { createReducer } from '@reduxjs/toolkit'
import { productActions } from './actions'
import { TInitialState } from './types'

const InitialState: any = {
   products: {
      data: [],
      meta: null
   },
   groups: {
      data: [],
      meta: null
   },
   variationsBulk: {
      data: [],
      meta: null
   },
   variationsValue: {
      data: [],
      meta: null
   },
   group: null,
   newProduct: null,
   loading: false,
   response: null,
   photoProduct: {
      data: [],
      meta: null
   },
   productsGroup: {
      data: [],
      meta: null
   },
   description: {
      data: [],
      meta: null
   },
   variationValue: null
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(productActions.setProducts, (state, { payload }) => ({ ...state, products: payload }))
      .addCase(productActions.setDescription, (state, { payload }) => ({ ...state, description: payload }))
      .addCase(productActions.setPhotoProduct, (state, { payload }) => ({ ...state, photoProduct: payload }))
      .addCase(productActions.setVariationsValue, (state, {payload}) => ({ ...state, variationValue: payload}))
      .addCase(productActions.setVariationsBulk, (state, { payload }) => ({ ...state, variationsBulk: payload }))
      .addCase(productActions.setGroups, (state, { payload }) => ({ ...state, groups: payload }))
      .addCase(productActions.setProductsGroup, (state, { payload }) => ({ ...state, productsGroup: payload }))
      .addCase(productActions.setGroup, (state, { payload }) => ({ ...state, group: payload }))
      .addCase(productActions.setProduct, (state, {payload}) => ({...state, newProduct: payload}))
      .addCase(productActions.setVariationValue, (state, {payload}) => ({...state, variationValue: payload}))
      .addCase(productActions.setProductState, (state, { payload }) => ({ ...state, response: null, ...payload }))
})

export default reducer
