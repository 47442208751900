import React, { useState } from 'react';
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import {
	ButtonClose,
	Color,
	ColorPickerContainer,
	DisplayColorPicker,
	Label,
	Swatch
} from './styled';

const ColorPicker = ({ color, onChange }:any) => {
	const { t } = useTranslation();

	const [active, setActive] = useState(false);

	const Events = {
		handleClick: () => {
			setActive(true)
		},
	};

	return (
			<ColorPickerContainer>
				<Label>{t('app.color')}</Label>
				<Swatch onClick={Events.handleClick}>
					<Color style={{background: `${color}`}}/>
				</Swatch>
				{active ? <DisplayColorPicker>
					<ButtonClose onClick={() => setActive(false)}>{t('close')}</ButtonClose>
					<SketchPicker color={color} onChangeComplete={onChange}/>
				</DisplayColorPicker> : null}
			</ColorPickerContainer>
	);
};

export default ColorPicker;