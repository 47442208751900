import styled from 'styled-components'
import {colors, FLEX} from "../../styles";
import {Assets} from "../../assets";

export const Styles = styled.div`
   ${FLEX({})}
   align-self: bottom;

   .pagination {
      margin: 40px auto;
      display: flex;
      list-style: none;
      outline: none;
      user-select: none;
   }

   .pagination > .active > a {
      color: #fff;
      border: ${({ color }) => `${color}`};
   }
   .pagination > li > a {
      border-radius: 6px;
      padding: 9px 13px;
      margin: 3px;
      outline: none;
      cursor: pointer;
      border: 1px solid ${colors.lines};
   }
   .pagination > .active > a,
   .pagination > .active > span,
   .pagination > .active > a:hover,
   .pagination > .active > span:hover,
   .pagination > .active > a:focus,
   .pagination > .active > span:focus {
      background-color:  ${({ color }) => `${color}`};
      outline: none;
   }
   .pagination > li > a,
   .pagination > li > span {
      color: ${({ color }) => `${color}`};
   }
   .pagination > li:first-child > a,
   .pagination > li:first-child > span,
   .pagination > li:last-child > a,
   .pagination > li:last-child > span {
      border-radius: unset;
   }

   .pagination > li.previous > a,
   .pagination > li.next > a {
      border: none;
   }
`

export const ArrowLeftIcon = styled.img.attrs({
   src: Assets.ARROW_LEFT
})``

export const ArrowRightIcon = styled.img.attrs({
   src: Assets.ARROW_LEFT,
})`
   transform: rotate(180deg)
`
