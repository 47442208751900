import React, {SyntheticEvent} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {
	colors,
	DropDownList,
	EColors,
	FlexContainer, generateErrorToast,
	Input,
	PhotoPicker,
	Textarea,
} from "../../../../common";
import {PRODUCT_IMAGES_LENGTH} from "../../consts";
import {productActions, showToastAction} from "../../../../store";

import {
	FormContainer,
	MainContainer,
	Label,
	FormBlock
} from "../../styled";

import {Catalog} from '../Catalog';
import {VariationContainer} from "../VariationContainer";

const BasicData = (
	{
		Events,
		formPhoto,
		selectColor,
		form,
		variationsData,
		setVariationData,
		productTypeOptions,
		visibilityOptions,
		statusOptions,
		setForm,
		isDuplicate
	}: any) => {

	const {t} = useTranslation();
	const dispatch = useDispatch();

	const Utils = {
		totalPhotos: (): number => {
			return PRODUCT_IMAGES_LENGTH
		},
		totalValidPhotos: (): number => {
			return formPhoto?.photos?.length
		},
		getVariationIndex: (e: SyntheticEvent): number => {
			const target = e.target as HTMLElement
			const variationsContainer = target.closest('.variations_form_container')
			const variationContainer = target.closest('.variation_form_container')
			return Array.prototype.indexOf.call(variationsContainer?.children, variationContainer)
		},
	};

	const createVariationBulkHandler = () => {
		const data = variationsData;
		dispatch(productActions.createVariationBulk({data}))
		setTimeout(() => {
			dispatch(productActions.createVariationBulk({data}))
		}, 500)
	}

	const EventsVariations = {
		onAddVariationClickHandler: () => {
			setVariationData(variationsData.concat([
				{
					order: variationsData.length + 1,
					title: [{title: '', lang: 'en'}],
					type: '',
					values: [
						{
							order: 1,
							bgUrl: '',
							color: '',
							title: [
								{title: '', lang: 'en'}
							]
						}
					]
				}
			] as typeof variationsData))
		},
		inputChangeHandler: (e: SyntheticEvent, firstId: number, secondId?: number, color?: string) => {
			const {name, value} = e.target as HTMLInputElement
			const variationContainerIndex = Utils.getVariationIndex(e)
			setVariationData((form: any) =>
				form.map((group: any, index: number) => {
					if (index !== variationContainerIndex) return group
					if (!secondId) {
						if (name === 'type') {
							return {
								...group,
								[name]: value
							}
						} else {
							return {
								...group,
								title: [{title: value, lang: 'en'}, {title: value, lang: 'ua'}, {title: value, lang: 'de'}]
							}
						}
					} else {
						return {
							...group,
							values: secondId ? group.values.map((el: any) => el.order === secondId ? {
								...el,
								bgUrl: color || el.bgUrl,
								color: color || el.color,
								title: color ? el.title : [{title: value, lang: 'en'}, {title: value, lang: 'ua'}, {
									title: value,
									lang: 'de'
								}]
							} : el) : group.values
						}
					}
				})
			);
		},
		createVariationBulk: () => {
			isDuplicate !== true ? createVariationBulkHandler()
				: dispatch(showToastAction.request(generateErrorToast(t('the.variation.value.must.not.be.repeated'))));
		}
	};

	return (
		<MainContainer>
			<FormContainer>
				<FlexContainer gap="62px">
					<DropDownList
						name="type"
						label={t('product.type')}
						options={productTypeOptions}
						placeholder={t('enter.type.product')}
						value={form.type}
						onChange={Events.inputChangeHandler}
					/>
					{form.type === productTypeOptions[0].value && (
						<>
							<DropDownList
								name="show"
								label={t('visibility')}
								options={visibilityOptions}
								value={form.show}
								onChange={Events.inputChangeShowHandler}
							/>
							<Input
								name="sku"
								type={'number'}
								label={t('SKU')}
								placeholder={t('enter.code')}
								value={form.sku}
								onChange={Events.inputChangeHandler}
							/>
						</>
					)}
				</FlexContainer>
				<FormBlock>
					<Input
						name="nameEn"
						width="275px"
						label={t('name')}
						placeholder={t('enter.name.en')}
						value={form.nameEn}
						onChange={Events.inputChangeHandler}
					/>
					<Input
						name="nameUa"
						width="275px"
						label={t('name')}
						placeholder={t('enter.name.ua')}
						value={form.nameUa}
						onChange={Events.inputChangeHandler}
					/>
					<Input
						name="nameDe"
						width="275px"
						label={t('name')}
						placeholder={t('enter.name.de')}
						value={form.nameDe}
						onChange={Events.inputChangeHandler}
					/>
					<Textarea
						width='275px'
						name="descriptionEn"
						height="260px"
						placeholder={t('enter.description.en')}
						label={t('description')}
						onChange={Events.inputChangeHandler}>
						{form.descriptionEn}
					</Textarea>
					<Textarea
						width='275px'
						name="descriptionUa"
						height="260px"
						placeholder={t('enter.description.ua')}
						label={t('description')}
						onChange={Events.inputChangeHandler}>
						{form.descriptionUa}
					</Textarea>
					<Textarea
						width='275px'
						name="descriptionDe"
						height="260px"
						placeholder={t('enter.description.de')}
						label={t('description')}
						onChange={Events.inputChangeHandler}>
						{form.descriptionDe}
					</Textarea>
				</FormBlock>

				{form.type === productTypeOptions[0].value ?
					<FlexContainer gap="30px">
						<FlexContainer gap="62px">
							<Input
								name="price"
								type="number"
								label={t('price')}
								placeholder={t('enter.price')}
								value={form.price}
								onChange={Events.inputChangeHandler}
							/>
							<Input
								name="discountPrice"
								type="number"
								label={t('discount.price')}
								placeholder={t('enter.discount.price')}
								value={form.discountPrice}
								onChange={Events.inputChangeHandler}
							/>
						</FlexContainer>
						<FlexContainer gap="62px">
							<Input
								name="amount"
								type={'number'}
								label={t('amount')}
								placeholder={t('enter.amount')}
								value={form.amount}
								onChange={Events.inputChangeHandler}
							/>
							<DropDownList
								name="sellStatus"
								label={t('status')}
								options={statusOptions}
								placeholder={t('enter.status')}
								value={form.sellStatus}
								onChange={Events.inputChangeHandler}
							/>
						</FlexContainer>
					</FlexContainer>
					:
					<VariationContainer
						isDuplicate={isDuplicate}
						selectColor={selectColor}
						setVariationData={setVariationData}
						EventsVariations={EventsVariations}
						variationsData={variationsData}
					/>
				}
			</FormContainer>

			<FlexContainer direction="column" align="center" gap="">
				<Catalog setForm={setForm} form={form}/>
				<FlexContainer
					direction="column"
					style={{borderTop: `1px solid ${colors.lines}`, marginTop: '8px', padding: '24px 30px'}}>
					<Label>{t('photo')}</Label>
					<FlexContainer gap="28px">
						{Object.keys(formPhoto.photos)
							.filter((photo) => formPhoto.photos[photo as keyof typeof formPhoto.photos])
							.map((name, index) => (
								<PhotoPicker width={150} height={150} key={index} file={formPhoto.photos[index]} onChange={Events.onPhotoChange}/>
							))}

						{Utils.totalValidPhotos() < Utils.totalPhotos() && (
							<PhotoPicker
								width={150} height={150}
								name={'photo' + (Utils.totalValidPhotos() + 1)}
								onChange={Events.onPhotoChange}
							/>
						)}
					</FlexContainer>
				</FlexContainer>
			</FlexContainer>
		</MainContainer>
	);
};

export default BasicData;