import { createAction } from '@reduxjs/toolkit'
import { CONNECT_USER, LOGIN_USER, LOGIN_USER_REQUEST, LOGOUT_USER, LOGOUT_USER_REQUEST } from './consts'
import { TUserActionsPayload, TInitialState } from './types'

export const userActions = {
   auth: createAction(LOGIN_USER, (payload: TInitialState) => {
      return {
         payload
      }
   }),

   request: createAction(LOGIN_USER_REQUEST, ({ login, password }: TUserActionsPayload) => {
      return {
         payload: {
            login,
            password
         }
      }
   }),

   logout: createAction(LOGOUT_USER),

   connect: createAction(CONNECT_USER, (payload) => {
      return {
         payload
      }
   }),
   logoutRequest: createAction(LOGOUT_USER_REQUEST)
}
