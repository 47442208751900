import { createReducer } from '@reduxjs/toolkit'
import { connectionActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   isConnected: false
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder.addCase(connectionActions.setConnection, (state, { payload }) => ({
      ...state,
      isConnected: payload.isConnected
   }))
})

export default reducer
