import { FC } from 'react'
import { FlexContainer, Label } from '../Styled'
import { StyledTextarea } from './styled'
import { TTextarea } from './types'
import {useTypedSelector} from "../../hooks";
import { getMainSettingSelector } from '../../../store';

const Textarea: FC<TTextarea> = ({
   children,
   label,
   readonly,
   height = '192px',
   width = '100%',
   placeholder = '',
   onChange,
   name = '',
   disabled,
  defaultValue
}) => {
   const { logo } = useTypedSelector(getMainSettingSelector)
   return (
      <>
         <FlexContainer direction="column">
            <Label>{label}</Label>
            <StyledTextarea
               disabled={disabled}
               color={logo?.data?.mainColor}
               name={name}
               readOnly={readonly}
               value={children as string}
               height={height}
               width={width}
               placeholder={placeholder}
               defaultValue={defaultValue}
               onChange={onChange}
            />
         </FlexContainer>
      </>
   )
}

export default Textarea
