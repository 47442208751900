import {AxiosResponse} from 'axios';
import { HttpService } from '../http.service';
import {TResponse} from '../types';
import {BONUS_URL} from './config';

import {
   TBonusesRequestPayload,
   TChangeBonusRequestPayload,
   TDeleteBonusRequestPayload,
   TSaveBonusesRequestPayload
} from './types'

export class ApiBonusService extends HttpService {
   static getBonuses({ token }: TBonusesRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: BONUS_URL.getBonuses,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })
   }

   static save({ token, data }: TSaveBonusesRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: BONUS_URL.save,
         method: 'POST',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static change({ token, data }: TChangeBonusRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: BONUS_URL.change + `/${data._id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static delete({ token, id }: TDeleteBonusRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: BONUS_URL.delete + `/${id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })
   }
}
