import {
   FC,
   SyntheticEvent,
   useEffect,
   useMemo,
   useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { PAGE_LIMIT } from '../../consts'
import {
   ButtonBlock,
   Container,
   FlexStyledContainer,
   Header,
   Image,
   MainContainer,
   Title
} from '../../styled'
import {
   ButtonNew,
   colors,
   FlexContainer,
   generateErrorToast,
   H2,
   Input,
   Pagination,
   PhotoPicker,
   Popup,
   SubPagesBar,
   Table,
   useTypedSelector,
   useValidation
} from '../../../../common'
import { api } from '../../../../common/config/api';
import {
   getMainSectionSelector,
   sectionActions,
   SECTION_RESPONSE,
   showToastAction,
} from '../../../../store'
import { getLocale } from "../../../../types";

import {
   TForm,
   TFormTypes,
   TSectionValidationSchema
} from './types'

const IndexPage: FC = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const local = getLocale();
   const { sections, response, description } = useTypedSelector(getMainSectionSelector)

   const navigate = useNavigate()
   const [page, setPage] = useState<number>(0)

   const [searchParams, setSearchParams] = useSearchParams({})

   const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false)

   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)
   const [selectedItems, setSelectedItems] = useState<number[]>([])

   const [formType, setFormType] = useState<TFormTypes>('create')

   const [form, setForm] = useState<TForm>({
      nameUa: '',
      nameEn: '',
      nameDe: '',
      photo: null,
      _id: '',
   })

   const data = useMemo(
      () =>
         sections.data.map((section) => {
            return {
               photo: <Image src={`${api.withImageAPI}/section/${section.image}`} />,
               label: section.title,
               totalProducts: <>{section.productsTotalCount}</>,
               totalCategories: <>{section.categoriesTotalCount}</>,
               totalSubcategories: <>{section.subCategoriesTotalCount}</>
            }
         }),
      [sections]
   )

   const columns = useMemo(
      () => [
         {
            Header: t('photo'),
            accessor: 'photo',
            width: 100
         },
         {
            Header: t('label'),
            accessor: 'label',
            width: 250
         },
         {
            Header: t('total.product'),
            accessor: 'totalProducts',
            width: 250
         },
         {
            Header: t('total.categories'),
            accessor: 'totalCategories',
            width: 250
         },
         {
            Header: t('total.subcategories'),
            accessor: 'totalSubcategories',
            width: 250
         }
      ],
      []
   )

   const paginationSections = useMemo(
      () => [
         {
            title: t('sections'),
            active: true
         },
         {
            title: t('categories'),
            onClickHandler: () => {
               navigate('/catalog/categories')
            }
         },
         {
            title: t('subcategories'),
            onClickHandler: () => {
               navigate('/catalog/subcategories')
            }
         },
         {
            title: t('brands'),
            onClickHandler: () => {
               navigate('/catalog/brand')
            }
         }
      ],
      []
   )

   const Requests = {
      getSections: () => {
         dispatch(
            sectionActions.getSections({ limit: PAGE_LIMIT, page: page, lang: local })
         )
      },
      createSection: () => {
         const data = new FormData()

         data.append(
           'description[]',
           JSON.stringify({
              title: form.nameEn,
              lang: 'en'
           })
         )
         data.append(
           'description[]',
           JSON.stringify({
              title: form.nameUa,
              lang: 'ua'
           })
         )
         data.append(
           'description[]',
           JSON.stringify({
              title: form.nameDe,
              lang: 'de'
           })
         )
         data.append('img', form.photo as File)

         dispatch(sectionActions.createSection({ data }))
      },
      editSection: () => {
         const data = new FormData()
         data.append(
           'description[]',
           JSON.stringify({
              title: form.nameEn,
              lang: 'en'
           })
         )
         data.append(
           'description[]',
           JSON.stringify({
              title: form.nameUa,
              lang: 'ua'
           })
         )
         data.append(
           'description[]',
           JSON.stringify({
              title: form.nameDe,
              lang: 'de'
           })
         )
         form.photo instanceof File && data.append('img', form.photo)
         dispatch(sectionActions.editSection({ data, id: form._id as string }))
      },
      removeSections: (ids: string[]) => {
         dispatch(sectionActions.removeSections({ ids }))
      }
   }

   const schema = useMemo<TSectionValidationSchema>(
     () => ({
        name:{
           condition: !!form.nameDe && !!form.nameEn && !!form.nameUa,
           error: t('enter.the.section.name.in.all.languages')
        },
        photo: {
           condition: form.photo !== null,
           error: t('enter.photo')
        }
     }),
     [form]
   );

   const {
      validation,
      enableValidation,
      disableValidation,
      //eslint-disable-next-line
   } = useValidation(schema);

   const Events = {
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      closePopupClickHandler: () => {
         setPopupVisiablity(false)
      },
      addButtonClickListner: () => {
         setForm({
            nameUa: '',
            nameEn: '',
            nameDe: '',
            photo: null,
         })
         setFormType('create')
         setPopupVisiablity(true)
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      onPhotoChange: (file: File | null) => {
         setForm({ ...form, photo: file as File })
      },
      createButtonClickHandler: async () => {
         try {
            enableValidation();
            await validation();
            Requests.createSection()
            setPopupVisiablity(false)
            disableValidation()
         } catch (error:any) {
            error?.map((err:string) => {
               dispatch(showToastAction.request(generateErrorToast(err)));
            });
         }
      },
      editButtonClickHandler: async () => {
         try {
            enableValidation();
            await validation();
            Requests.editSection()
            setPopupVisiablity(false)
            disableValidation()
         } catch (error:any) {
            error?.map((err:string) => {
               dispatch(showToastAction.request(generateErrorToast(err)));
            });
         }
      },
      openEditButtonClickHandler: async (e: SyntheticEvent, index: number) => {
         await dispatch(sectionActions.getDescriptionSection({_id: sections.data[index]._id}))
         await setForm({
            nameUa: description?.data[1]?.title,
            nameEn: description?.data[0]?.title,
            nameDe: description?.data[2]?.title,
            photo: `${api.withImageAPI}/section/${sections.data[index].image}`,
            _id: sections.data[index]._id,
         })
         setFormType('edit')
         setPopupVisiablity(true)
      },
      removeSections: () => {
         Requests.removeSections(selectedItems.map((index) => sections.data[index]._id))
         setRemoveButtonState(false)
         setSelectedItems([])
      },
      removeSectionHandler: (e: SyntheticEvent, index: number) => {
         Requests.removeSections([sections.data[index]._id])
      },
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },
      onChange: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setForm({ ...form, [input.name]: input.value })
      }
   }

   // Request to receive data
   useEffect(() => {
      Requests.getSections()
      setSearchParams({ ...searchParams, page: '' + page })
   }, [page])

   // Set default page uri
   useEffect(() => {
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string)
            : 0
      )
   }, []);

   useEffect(() => {
      if(description){
         setForm((form) => ({
            ...form,
            nameUa: description?.data[1]?.title,
            nameEn: description?.data[0]?.title,
            nameDe: description?.data[2]?.title,
         }))
      }
   }, [description]);

   useEffect(() => {
      if (response == SECTION_RESPONSE.CREATED) {
         setPopupVisiablity(false)
         setForm({
            nameUa: '',
            nameDe: '',
            nameEn: '',
            photo: null,
         })
         Requests.getSections()
      }

      if (response == SECTION_RESPONSE.REMOVED) {
         Requests.getSections()
      }

      if (response == SECTION_RESPONSE.EDITED) {
         Requests.getSections()
      }
   }, [response]);

   return (
     <Container>
        <Header>
           <Title>{t('sections')}</Title>
           <FlexStyledContainer>
              {removeButtonState && (
                <ButtonBlock>
                   <ButtonNew theme="red" onClick={Events.removeSections}>
                      {t('remove.all')}
                   </ButtonNew>
                </ButtonBlock>
              )}
              <ButtonBlock>
                 <ButtonNew theme="green" onClick={Events.addButtonClickListner}>
                    {t('create.section')}
                 </ButtonNew>
              </ButtonBlock>
           </FlexStyledContainer>
        </Header>
        <MainContainer>
           <SubPagesBar sections={paginationSections} />
           <Table
             columns={columns}
             data={data}
             editable
             removable
             removeClickHandler={Events.removeSectionHandler}
             checkboxClickHandler={Events.checkboxClickHandler}
             editClickHandler={Events.openEditButtonClickHandler}
           />
           <Pagination
             page={page}
             pageCount={
                sections.meta ? Math.ceil(sections.meta.totalCount / PAGE_LIMIT) : 1
             }
             onPageChange={Events.onPageChangeHandler}
           />

           {popupVisiablity && (
             <Popup backgroundClickListener={Events.closePopupClickHandler}>
                <FlexContainer
                  justify="space-between"
                  direction="column"
                  style={{ height: '100%' }}
                  wrap="nowrap">
                   <FlexContainer gap="20px" align="center" justify="center">
                      {formType == 'create' && (
                        <H2 style={{ marginTop: '40px' }}>{t('create.section')}</H2>
                      )}
                      {formType == 'edit' && (
                        <H2 style={{ marginTop: '40px' }}>{t('edit.section')}</H2>
                      )}{' '}
                      <FlexContainer
                        gap="30px"
                        align="flex-start"
                        justify="center"
                        style={{
                           padding: '30px 0px',
                           borderBottom: `1px solid ${colors.gray}`
                        }}>
                         <Input
                           name="nameUa"
                           label={t('name')}
                           placeholder={t('enter.name.ua')}
                           value={form.nameUa}
                           onChange={Events.inputChangeHandler}
                         />
                         <Input
                           name="nameEn"
                           label={t('name')}
                           placeholder={t('enter.name.en')}
                           value={form.nameEn}
                           onChange={Events.inputChangeHandler}
                         />
                         <Input
                           name="nameDe"
                           label={t('name')}
                           placeholder={t('enter.name.de')}
                           value={form.nameDe}
                           onChange={Events.inputChangeHandler}
                         />
                         <FlexContainer justify="space-between" width="318px">
                            <PhotoPicker
                              width={150} height={150}
                              label={t('photo')}
                              onChange={Events.onPhotoChange}
                              value={formType == 'edit' ? (form.photo as string) : null}
                            />
                         </FlexContainer>
                      </FlexContainer>
                   </FlexContainer>
                   <FlexContainer
                     style={{ marginBottom: '40px' }}
                     gap="30px"
                     justify="center">
                      <ButtonNew
                        theme="gray"
                        style="transparant"
                        onClick={Events.closePopupClickHandler}>
                         {t('cancel')}
                      </ButtonNew>

                      {formType == 'create' && (
                        <ButtonNew theme="green" onClick={Events.createButtonClickHandler}>
                           {t('create')}
                        </ButtonNew>
                      )}
                      {formType == 'edit' && (
                        <ButtonNew theme="green" onClick={Events.editButtonClickHandler}>
                           {t('edit')}
                        </ButtonNew>
                      )}
                   </FlexContainer>
                </FlexContainer>
             </Popup>
           )}
        </MainContainer>
     </Container>
   )
}

export default IndexPage
