import React, {SyntheticEvent, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";

import {
	ButtonNew,
	Divider,
	FlexContainer,
	Input,
} from '../../../../common';

import {
	MainContainer,
	CharacteristicsWrapper
} from './styled';

const ProductCharacteristics = (
	{
    formCharacteristicUa,
    formCharacteristicEn,
    formCharacteristicDe,
		setFormCharacteristicUa,
		setFormCharacteristicEn,
		setFormCharacteristicDe,
}: any) => {
	const { t } = useTranslation();

	const Events = {
		onAddClickHandler: () => {
			setFormCharacteristicUa((prevState: any) => [...prevState, {value: '', key: ''}]);
			setFormCharacteristicDe((prevState: any) => [...prevState, {value: '', key: ''}]);
			setFormCharacteristicEn((prevState: any) => [...prevState, {value: '', key: ''}]);
		},
		inputHandler: (e: SyntheticEvent, idx:number, setForm: any) => {
			const { name, value } = e.target as HTMLInputElement;

			setForm((formData: any) =>
				formData.map((form: any, index: number) => {
					if (index !== idx) return form;
					return {
						...form,
						[name]: value,
					};
				})
			);
		},
	};

	return (
			<MainContainer>
				<FlexContainer gap="30px" direction="column" className="characteristics_form_container">
					{formCharacteristicUa.map((_: any, index: number) => {
						return (
							<CharacteristicsWrapper key={index} style={{marginBottom: '30px'}}>
								<FlexContainer gap="30px">
									<Input
										width={'568px'}
										name="key"
										label={t('сharacteristic')}
										placeholder={t("enter.characteristic.en")}
										value={formCharacteristicEn[index]?.key}
										onChange={(event) => Events.inputHandler(event, index, setFormCharacteristicEn)}
									/>
									<Input
										width={'568px'}
										name="value"
										label={t('significance')}
										placeholder={t("enter.value.en")}
										value={formCharacteristicEn[index]?.value}
										onChange={(event) => Events.inputHandler(event, index, setFormCharacteristicEn)}
									/>
								</FlexContainer>
								<FlexContainer gap="30px">
									<Input
										width={'568px'}
										name="key"
										label={t('сharacteristic')}
										placeholder={t("enter.characteristic.ua")}
										value={formCharacteristicUa[index]?.key}
										onChange={(event) => Events.inputHandler(event, index, setFormCharacteristicUa)}
									/>
									<Input
										width={'568px'}
										name="value"
										label={t('significance')}
										placeholder={t("enter.value.ua")}
										value={formCharacteristicUa[index]?.value}
										onChange={(event) => Events.inputHandler(event, index, setFormCharacteristicUa)}
									/>
								</FlexContainer>
								<FlexContainer gap="30px">
									<Input
										width={'568px'}
										name="key"
										label={t('сharacteristic')}
										placeholder={t("enter.characteristic.de")}
										value={formCharacteristicDe[index]?.key}
										onChange={(event) => Events.inputHandler(event, index, setFormCharacteristicDe)}
									/>
									<Input
										width={'568px'}
										name="value"
										label={t('significance')}
										placeholder={t("enter.value.de")}
										value={formCharacteristicDe[index]?.value}
										onChange={(event) => Events.inputHandler(event, index, setFormCharacteristicDe)}
									/>
								</FlexContainer>
							</CharacteristicsWrapper>
						)
					})}
				</FlexContainer>
				<Divider height={30} />
				<ButtonNew height={40} width={139} onClick={Events.onAddClickHandler}>
					{
						<>
							{t('add')}
						</>
					}
				</ButtonNew>
			</MainContainer>
	);
};

export default ProductCharacteristics;