import {AxiosResponse} from 'axios'
import { HttpService } from '../http.service'

import {TResponse} from '../types'
import {SUBCATEGORY_URL} from './config'
import {
   TCreateSubcategoryRequestPayload,
   TEditSubcategoryRequestPayload,
   TGetSubcategoriesByCategoryRequestPayload,
   TGetSubcategoriesRequestPayload,
   TGetSubcategoryDescription,
   TRemoveSubcategoryRequestPayload
} from './types'

export class ApiSubcategoryService extends HttpService {
   static getSubcategories({
      token,
      limit = 10,
      page = 0,
      sortBy,
      order,
      lang = 'ua'
   }: TGetSubcategoriesRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request<TResponse>({
         url: SUBCATEGORY_URL.getSubcategories,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: page * limit,
            sortBy,
            order,
            lang
         }
      })
   }

   static getSubcategoriesByCategory({ token, _id, lang = 'ua' }: TGetSubcategoriesByCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request<TResponse>({
         url: `${SUBCATEGORY_URL.getSubcategoriesByCategory}/${_id}/byCategory`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            lang
         }
      })
   }

   static getDescription({ token, _id }: TGetSubcategoryDescription): Promise<AxiosResponse<TResponse>> {
      return this.request<TResponse>({
         url: `${SUBCATEGORY_URL.getSubcategoryDescription}/${_id}/descriptions`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })
   }

   static removeSubcategory({ token, _id }: TRemoveSubcategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request<TResponse>({
         url: `${SUBCATEGORY_URL.removeSubcategory}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })
   }

   static editSubcategory({ token, _id, data }: TEditSubcategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request<TResponse>({
         url: `${SUBCATEGORY_URL.editSubcategory}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static createSubcategory({ token, data }: TCreateSubcategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request<TResponse>({
         url: SUBCATEGORY_URL.createSubcategory,
         method: 'POST',
         data,
         headers: {
            Authorization: token
         }
      })
   }
}
