import { AxiosError } from "axios";

import {
	createShopActionPayload,
	createShopActionResponse,
	GetShopActionPayload,
	GetShopByIdActionResponse
} from "./types";
import { createAction } from "@reduxjs/toolkit";
import { REMOVE_SHOPS, SET_SHOP_STATE } from "./consts";
import { createActionTypes, createApiActions } from "../rootActions";

export const getShopAction = createApiActions<
	GetShopActionPayload,
	GetShopByIdActionResponse,
	AxiosError
	>(createActionTypes('SHOP/GET_SHOPS'))

export const createShopAction = createApiActions<
	createShopActionPayload,
	createShopActionResponse,
	AxiosError
	>(createActionTypes('SHOP/CREATE_SHOP'))

export const shopAction = {
	setShopState: createAction(SET_SHOP_STATE, (payload) => ({ payload })),
	removeShops: createAction(REMOVE_SHOPS, (payload) => ({ payload }))
}