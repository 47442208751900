import {AxiosResponse} from "axios";
import {TResponse} from "../types";
import {TEditLogoRequestPayload, TGetLogoRequestPayload} from "./types";
import {SETTING_URL} from "./config";
import { HttpService } from "../http.service";

export class ApiSettingService extends HttpService {
	static getLogo({token}: TGetLogoRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${SETTING_URL.getLogo}`,
			method: 'GET',
			headers: {
				Authorization: token
			}
		})
	}
	static editLogo({token, data}: TEditLogoRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${SETTING_URL.editLogo}`,
			method: 'POST',
			data: data,
			headers: {
				Authorization: token
			}
		})
	}
}