import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { availabilityActions } from './actions'
import { ApiAvailabilityService } from './api.service'
import { TGetAvailabilitysPayload, TRemoveAvailabilityRequestPayload } from './types'
import { showToastAction } from "../toasts";
import { generateErrorToast, generateSuccessToast } from "../../common";
import { i18n } from "../../common/config";

function* getAvailabilitysWorker({ payload }: TDataWrapper<TGetAvailabilitysPayload>) {
   yield put(availabilityActions.setAvailabilityState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiAvailabilityService, ApiAvailabilityService.getAvailabilitys], { ...payload, token })
      if (response.data) yield put(availabilityActions.setAvailabilitys(response.data))
   } catch (e) {
      yield put(availabilityActions.setAvailabilitys({ data: [], meta: null }))
   }

   yield put(availabilityActions.setAvailabilityState({ loading: false }))
}

function* removeAvailabilityWorker({ payload }: TDataWrapper<TRemoveAvailabilityRequestPayload>) {
   yield put(availabilityActions.setAvailabilityState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _id } = payload

   try {
      yield call([ApiAvailabilityService, ApiAvailabilityService.removeAvailability], { token, _id })
      yield put(availabilityActions.setAvailabilitys({ data: [], meta: { needReload: true } }))
      yield put(showToastAction.request(generateSuccessToast(i18n.t("order.removed.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.order") as string)));
   }

   yield put(availabilityActions.setAvailabilityState({ loading: false }))
}

export function* availabilityWatcher() {
   yield takeLatest(availabilityActions.getAvailabilitys, getAvailabilitysWorker)
   yield takeLatest(availabilityActions.removeAvailability, removeAvailabilityWorker)
}
