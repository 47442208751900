import 'chart.js/auto';
import React from 'react';
import {Doughnut, Line} from 'react-chartjs-2';
import {ChartContainer, Container, DoughnutContainer, H2, P, LineContainer} from './styled';
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../../common";
import {getMainOrderSelector} from "../../../../store";
import {TChart} from "./types";

const Chart = ({doughnut}: TChart) => {
	const {t} = useTranslation();

	const {stats, graf} = useTypedSelector(getMainOrderSelector);

	console.log(stats, 'statistics')

	const totalCardSum =  stats?.data?.totalOrderSum - stats?.data?.totalCashSum

	const dataDoughnut = {
		labels: [t('total.cash.sum'), t('total.card.sum')],
		datasets: [
			{
				data: [stats?.data?.totalCashSum, totalCardSum.toFixed(0)],
				backgroundColor: ["#80A1F7", "#F7B980",]
			}
		],
	};

	const grafSum = graf?.data?.reduce((sum: number, el: any) => sum + +el.orders, 0);

	const dataLines = {
		labels: graf?.data?.map((el: any) => el._id),
		datasets: [
			{
				data: graf?.data?.map((el: any) => el.orders),
				fill: true,
				backgroundColor: "rgba(241, 128, 24, 0.6)",
				borderColor: "#F18018"
			},
		],
	};

	const options: any = {
		offset: 15,
		borderRadius: 50,
		rotation: 250,
		cutout: '75%',
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					display: true,
					usePointStyle: true,
					font: {
						size: 14
					}
				}
			}
		}
	};

	return (
		<>
			{doughnut ?
				<Container width={476}>
					<H2>{t('total.order.sum')}</H2>
					<P>{stats?.data?.totalOrderSum?.toFixed(0)} ₴</P>
					<DoughnutContainer>
						<ChartContainer>
							<Doughnut width={238} height={238} data={dataDoughnut} options={options}/>
						</ChartContainer>
					</DoughnutContainer>
				</Container>
				:
				<Container width={1090}>
					<H2>{t('number.orders')}</H2>
					<P>{grafSum}</P>
					<LineContainer>
						<Line
							data={dataLines}
							height={90}
							options={{
								plugins: {
									legend: {
										display: false
									}
								}
							}}/>
					</LineContainer>
				</Container>
			}
		</>
	);
};

export default Chart;