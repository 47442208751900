import { FC } from 'react';

import { CalendarIcon, Container, DateInputTemplate } from './styled';
import { TDateInputProps } from './types';

const DateInput: FC<TDateInputProps> = ({ name = '', value = '', onChange, onSubmit }) => {
   return (
      <Container>
         <DateInputTemplate name={name} value={value} onChange={onChange} onSubmit={onSubmit} />
         <CalendarIcon />
      </Container>
   )
}

export default DateInput
