import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import {
	ButtonNew,
	Divider,
	FlexContainer,
	Input,
} from '../../../../common';

import {
	MainContainer,
	CharacteristicsWrapper
} from './styled';
import { TProductCharacteristics } from "./types";

const ProductCharacteristics = (
	{
		setFormCharacteristicUa,
		setFormCharacteristicEn,
		setFormCharacteristicDe
	} :TProductCharacteristics ) => {
	const { t } = useTranslation();

	const [formCharacteristic, setFormCharacteristic] = useState([{
		characteristicUa: {
			valueUa: '',
			keyUa: ''
		},
		characteristicEn: {
			valueEn: '',
			keyEn: ''
		},
		characteristicDe: {
			valueDe: '',
			keyDe: ''
		}
	}]);

	const Events = {
		onAddClickHandler: () => {
			setFormCharacteristic(formCharacteristic.concat([{
				characteristicUa: {
					valueUa: '',
					keyUa: ''
				},
				characteristicEn: {
					valueEn: '',
					keyEn: ''
				},
				characteristicDe: {
					valueDe: '',
					keyDe: ''
				}
			}]))
		},
		inputHandler: (e: SyntheticEvent, idx:number) => {
			const {name, value} = e.target as HTMLInputElement
			setFormCharacteristic((characteristic) =>
				characteristic.map((form:any, index: number) => {
					if (index !== idx) return form
					if(name === 'keyUa') {
						return {
							...form,
							characteristicUa: {
								...form.characteristicUa,
								[name]: value,
							}
						}
					}else if(name === 'valueUa') {
						return {
							...form,
							characteristicUa: {
								...form.characteristicUa,
								[name]: value,
							}
						}
					}
						if(name === 'keyEn') {
							return {
								...form,
								characteristicEn: {
									...form.characteristicEn,
									[name]: value,
								}
							}
						}else if(name === 'valueEn') {
							return {
								...form,
								characteristicEn: {
									...form.characteristicEn,
									[name]: value,
								}
							}
						}
						if(name === 'keyDe') {
							return {
								...form,
								characteristicDe: {
									...form.characteristicDe,
									[name]: value,
								}
							}
						}else if(name === 'valueDe') {
							return {
								...form,
								characteristicDe: {
									...form.characteristicDe,
									[name]: value,
								}
							}
						}
					}
					)
			)
		}
	};

	useEffect(() => {
		if(formCharacteristic){
			setFormCharacteristicUa(formCharacteristic.map((lang) => ({
				value: lang.characteristicUa?.valueUa,
				key: lang.characteristicUa?.keyUa
			})))
			setFormCharacteristicDe(formCharacteristic.map((lang) => ({
				value: lang.characteristicDe?.valueDe,
				key: lang.characteristicDe?.keyDe
			})))
			setFormCharacteristicEn(formCharacteristic.map((lang) => ({
				value: lang.characteristicEn?.valueEn,
				key: lang.characteristicEn?.keyEn
			})))
		}
	}, [formCharacteristic]);

	return (
			<MainContainer>
				<FlexContainer gap="30px" direction="column">
					{formCharacteristic.map((characteristic:any, index: number) => {
						return (
							<CharacteristicsWrapper key={index} style={{marginBottom: '30px'}}>
								<FlexContainer gap="30px">
									<Input
										width={'568px'}
										name="keyUa"
										label={t('сharacteristic')}
										placeholder={t("enter.characteristic.ua")}
										value={characteristic.characteristicUa.keyUa}
										onChange={(event) => Events.inputHandler(event, index)}
									/>
									<Input
										width={'568px'}
										name="valueUa"
										label={t('significance')}
										placeholder={t("enter.value.ua")}
										value={characteristic.characteristicUa.valueUa}
										onChange={(event) => Events.inputHandler(event, index)}
									/>
								</FlexContainer>
								<FlexContainer gap="30px">
									<Input
										width={'568px'}
										name="keyEn"
										label={t('сharacteristic')}
										placeholder={t("enter.characteristic.en")}
										value={characteristic.characteristicEn.keyEn}
										onChange={(event) => Events.inputHandler(event, index)}
									/>
									<Input
										width={'568px'}
										name="valueEn"
										label={t('significance')}
										placeholder={t("enter.value.en")}
										value={characteristic.characteristicEn.valueEn}
										onChange={(event) => Events.inputHandler(event, index)}
									/>
								</FlexContainer>
								<FlexContainer gap="30px">
									<Input
										width={'568px'}
										name="keyDe"
										label={t('сharacteristic')}
										placeholder={t("enter.characteristic.de")}
										value={characteristic.characteristicDe.keyDe}
										onChange={(event) => Events.inputHandler(event, index)}
									/>
									<Input
										width={'568px'}
										name="valueDe"
										label={t('significance')}
										placeholder={t("enter.value.de")}
										value={characteristic.characteristicDe.valueDe}
										onChange={(event) => Events.inputHandler(event, index)}
									/>
								</FlexContainer>
							</CharacteristicsWrapper>
						)
					})}
				</FlexContainer>
				<Divider height={30} />
				<ButtonNew height={40} width={139} onClick={Events.onAddClickHandler}>
					{ <>{t('add')}</> }
				</ButtonNew>
			</MainContainer>
	);
};

export default ProductCharacteristics;