import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({ justify: "space-between" })}
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const FlexStyledContainer = styled.div`
  width: 450px;

  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img`
  cursor: pointer;
`;

export const Title = styled.h1`
  ${FONT({ weight: "700", size: "24px", color: colors.black })};

  margin-left: 14px;
  margin-right: 60px;
`;

export const MainContainer = styled.main`
  position: relative;

  max-width: 1609px;
  height: 925px;

  margin-top: 26px;

  display: flex;
  gap: 62px;

  padding: 30px 30px;

  background: white;

  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);

  border-radius: 22px;

  @media (max-width: 1300px) {
    flex-direction: column;
    height: 1150px;
  }
`;

export const DateBlock = styled.div`
  width: 450px;

  display: flex;
  justify-content: space-between;

  ${FONT({ weight: "400", size: "12px", color: colors.gray })};
`;

export const DateText = styled.p``;

export const PermissionWrapper = styled.div`
  /* margin-top: 30px; */
  /* padding-left: 120px; */
  width: 800px;
  height: 400px;

  @media (max-width: 1300px) {
    padding: 0;
  }
`;

export const PermissionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PermissionBlock = styled.div`
  margin-top: 28px;
  width: 300px;
  display: flex;
  align-items: center;
`;

export const Name = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.black};
  margin-left: 12px;
`;

export const PermissionTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
`;
