import { FC } from 'react';

import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from '../../../store';

import { StyledButton } from './styled';
import { TButtonProps } from './types';

const ButtonNew: FC<TButtonProps> = ({ disabled, children, theme = 'orange', style = 'basic', onClick, width = 195, height = 49 }) => {
   const { logo } = useTypedSelector(getMainSettingSelector);
   return (
      <StyledButton disabled={disabled} color={logo?.data?.mainColor} className={`${theme} ${style}`} onClick={onClick} width={width} height={height}>
         {children}
      </StyledButton>
   )
}

export default ButtonNew

