import { combineReducers } from "@reduxjs/toolkit";

import { AdminReducer } from "./admin";
import { toastsReducer } from "./toasts";
import { ShopReducer } from "./shop";
import { SupportReducer } from "./support";
import { BannerReducer } from "./banner";
import { ProductReducer } from "./product";
import { OrderReducer } from "./order";
import { BonusReducer } from "./bonus";
import { CategoryReducer } from "./category";
import { SectionReducer } from "./section";
import { ClientReducer } from "./client";
import { AvailabilityReducer } from "./availabilityNottification";
import { FeedbackReducer } from "./feedback";
import { SettingReducer } from "./setting";
import { ConnectionReducer } from "./connection";
import { UserReducer } from "./user";
import { SubcategoryReducer } from "./subCategory";
import { BrandReducer } from "./brand";
import { DeliveryReducer } from "./delivery";

export default combineReducers({
	toasts: toastsReducer,
	admin: AdminReducer,
	shop: ShopReducer,
	category: CategoryReducer,
	connection: ConnectionReducer,
	support: SupportReducer,
	banner: BannerReducer,
	client: ClientReducer,
	product: ProductReducer,
	order: OrderReducer,
	bonus: BonusReducer,
	section: SectionReducer,
	availability: AvailabilityReducer,
	feedback: FeedbackReducer,
	setting: SettingReducer,
	user: UserReducer,
	subCategory: SubcategoryReducer,
	brand: BrandReducer,
	delivery: DeliveryReducer
})