import {AxiosResponse} from 'axios'
import { HttpService } from '../http.service'
import {TResponse} from '../types'
import {ADMINS_URL} from './config'
import {
	TCreateAdminRequestPayload,
	TEditAdminRequestPayload,
	TGetAdminRequestPayload,
	TGetAdminsRequestPayload,
	TRemoveAdminRequestPayload,
} from './types'

export class ApiAdminService extends HttpService {
	static getAdmins(
		{
			token,
			limit = 10,
			page = 0,
			value = '',
			start = '',
			end = ''
		}: TGetAdminsRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${ADMINS_URL.getAdmins}`,
			method: 'GET',
			headers: {
				Authorization: token
			},
			params: {
				limit,
				skip: limit * page,
				start,
				end,
				value
			}
		})
	}

	static createAdmin({data, token}: TCreateAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: ADMINS_URL.createAdmin,
			method: 'POST',
			data: data,
			headers: {
				Authorization: token
			}
		})
	}

	static removeAdmin({_id, token}: TRemoveAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${ADMINS_URL.removeAdmin}/${_id}`,
			method: 'DELETE',
			headers: {
				Authorization: token
			}
		})
	}

	static getAdmin({_id, token}: TGetAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${ADMINS_URL.getAdmin}/${_id}`,
			method: 'GET',
			headers: {
				Authorization: token
			}
		})
	}

	static editAdmin({data, token, _id}: TEditAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${ADMINS_URL.editAdmin}/${_id}`,
			method: 'PATCH',
			data: data,
			headers: {
				Authorization: token
			}
		})
	}
}
