import styled from "styled-components";
import { colors, FONT } from "../../../common";

export const ModalContainer = styled.div``;

export const ModalTitle = styled.h1`
  ${FONT({ weight: "700", size: "24px", color: colors.black, align: "center" })}

  margin-bottom:38px;
`;

export const FormContainer = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const InputsBlock = styled.div`
  &:not(:last-child) {
    margin-right: 60px;
  }
`;

export const InputPhoneBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const Span = styled.span`
  margin-top: 14px;
  margin-right: 5px;
`;

export const InputBlock = styled.div`
  margin-bottom: 30px;

  width: 568px;
`;

export const StyledButtons = styled.div`
  margin: 0 auto;
  width: 420px;
  display: flex;
  justify-content: space-between;
`;

export const InputRef = styled.input`
  width: 100%;
  padding: 13px 14px;
  margin-top: 7px;

  outline: none;

  border: 2px solid #d1d1d1;
  border-radius: 8px;

  background: ${colors.white};

  transition: 0.2s;

  &:focus {
    border: 2px solid ${({ color }) => `${color}`};
    background: ${colors.white};
  }

  &::placeholder {
    ${FONT({
      size: "16px",
      weight: "400",
      color: colors.gray,
    })}
  }

  @media (max-width: 1600px) {
    width: 100%;
  }
`;

export const Label = styled.label`
  ${FONT({
    size: "14px",
    weight: "700",
    color: colors.black,
  })}
`;
