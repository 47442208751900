import { Navigate } from "react-router-dom";

import { OrdersPage } from "../pages/Orders";
import { ProductsManagementPage } from "../pages/ProductsManagement";
import { LoginPage } from "../pages/Login";
import { BonusSystemPage } from "../pages/BonusSystem";
import { ShopPage } from "../pages/Shop";
import { SupportPage } from "../pages/Support";
import { BannerPage } from "../pages/Banner";
import { RolePage } from "../pages/Role";
import { CustomersPage } from "../pages/Customers";
import { OrderArchivePage } from "../pages/OrderArchive";
import { SettingsPage } from "../pages/Settings";
import { ProfileOrderPage } from "../pages/ProfileOrder";
import { CustomerProfilePage } from "../pages/CustomerProfile";
import { CreateProductPage } from "../pages/CreateProduct";
import { ClientOrderHistoryPage } from "../pages/OrderHistory/OrderHistory";
import { RolesCreatePage } from "../pages/CreateRole";
import { EditRolePage } from "../pages/EditRole";
import { EditProductPage } from "../pages/EditProduct";
import { AvailabilityNotificationPage } from "../pages/AvailabilityNotification";
import { StatisticsPage } from "../pages/Statistics";
import { FeedbackPage } from "../pages/Feedback";
import { FeedbackProfilePage } from "../pages/FeedbackProfile";
import { CategoriesIndexPage, SectionsIndexPage, Subcategories } from "../pages/Catalog";
import { NotFoundPage } from "../pages/NotFound";
import { BrandPage } from "../pages/Catalog/pages/Brand";
import { DeliveryPage } from "../pages/Delivery";

import { TRoutes } from './types';

const Routes: TRoutes = {
	orders: {
		name: 'orders',
		path: '/orders',
		component: OrdersPage,
	},
	ordersArchive: {
		name: 'ordersArchive',
		path: '/orders/archive',
		component: OrderArchivePage
	},
  profileOrder: {
    name: 'profileOrder',
	  path: 'orders/profile/:id',
	  component: ProfileOrderPage
  },
	productsManagement: {
		name: 'productsManagement',
		path: '/products-management',
		component: ProductsManagementPage,
	},
	editProduct: {
		name: 'editProduct',
		path: '/product/:id',
		component: EditProductPage
	},
	createProduct: {
		name: 'createProduct',
		path: '/product-create',
		component: CreateProductPage
	},
	feedbackProfile: {
		name: 'feedbackProfile',
		path: '/feedback/profile/:id',
		component: FeedbackProfilePage,
	},
	bonusSystem: {
		name: 'bonusSystem',
		path: '/bonus-system',
		component: BonusSystemPage
	},
	shop: {
		name: 'shop',
		path: '/shop',
		component: ShopPage
	},
	catalog: {
		name: 'catalog',
		path: '/catalog',
		component: () => <Navigate to={'/catalog/sections'} />
	},
	catalogSections: {
		name: 'catalogSections',
		path: '/catalog/sections',
		component: SectionsIndexPage
	},
	catalogCategories: {
		name: 'catalogCategories',
		path: '/catalog/categories',
		component: CategoriesIndexPage
	},
	catalogSubcategories: {
		name: 'catalogSubcategories',
		path: '/catalog/subcategories',
		component: Subcategories
	},
	catalogBrand: {
		name: 'catalogBrand',
		path: '/catalog/brand',
		component: BrandPage
	},
	support: {
		name: 'support',
		path: '/support',
		component: SupportPage
	},
	banner: {
		name: 'banner',
		path: '/banner',
		component: BannerPage
	},
	role: {
		name: 'role',
		path: '/role',
		component: RolePage
	},
	editRole: {
		name: 'editRole',
		path: '/editRole/:id',
		component: EditRolePage
	},
	settings: {
		name: 'settings',
		path: '/settings',
		component: SettingsPage
	},
	customers: {
		name: 'customers',
		path: '/customers',
		component: CustomersPage
	},
	editClientHistory: {
		name: 'editClientHistory',
		path: '/customer/profile/:id/history',
		component: ClientOrderHistoryPage
	},
	customerProfile: {
		name: 'customerProfile',
		path: '/customer/profile/:id',
		component: CustomerProfilePage
	},
	delivery: {
		name: 'delivery',
		path: '/delivery',
		component: DeliveryPage,
	},
	login: {
		name: 'login',
		path: '/login',
		component: LoginPage,
	},
	createRole: {
		name: 'createRole',
		path: '/createRole',
		component: RolesCreatePage
	},
	availabilityNotification: {
		name: 'availabilityNotification',
		path: '/availabilityNotification',
		component: AvailabilityNotificationPage
	},
	feedback: {
		name: 'feedback',
		path: '/feedback',
		component: FeedbackPage
	},
	statistics: {
		name: 'statistics',
		path: '/statistics',
		component: StatisticsPage
	},
	notFounded: {
		name: 'notFounded',
		path: '*',
		component: () => <Navigate to={'/login'} />,
	},
	notAvailable: {
		name: 'notAvailable',
		path: '/404',
		component: NotFoundPage,
	},
	notFoundedAuth: {
		name: 'notFoundedAuth',
		path: '*',
		component: () => <Navigate to={'/orders'} />
	}
};

export default Routes;