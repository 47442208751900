import styled from 'styled-components'

export const Container = styled.div`
  overflow-y: hidden;
`;

export const Image = styled.img`
   width: 46px;
   height: 46px;
`;

export const PreloaderContainer = styled.div`
   width: 100%;
   height: 100px;
`;

export const TableContainer = styled.div`
   height: 570px;
   overflow-y: auto;

  @media (max-width: 1600px) {
    height: 500px;
  }
`;
