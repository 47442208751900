import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from "moment";

import {
	ButtonNew,
	DataTotal,
	DateInput,
	FiltersWrapper,
	FlexContainer,
	HeaderWrapper,
	Pagination,
	SearchBar,
	StarRating,
	Table,
	TSort,
	useTypedSelector
} from "../../common";
import { feedbackActions, getMainFeedbackSelector } from "../../store/feedback";
import { RESPONSE } from "../../store/feedback/consts";
import { getUserSelector } from "../../store/user";
import { getLocale } from "../../types";

import {
	MainContainer,
	Container,
	StarContainer,
	Header,
	TitleBlock,
	Title,
	FlexStyledContainer,
	ButtonBlock
} from './styled';
import {ITEM_PER_PAGE} from './consts';

const Feedback: FC = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const local = getLocale();

	const [page, setPage] = useState<number>(0)
	const [searchParams, setSearchParams] = useSearchParams({})
	const [searchForm, setSearchForm] = useState({
		searchValue: '',
		dateStart: '',
		dateEnd: ''
	})
	const [selectedItems, setSelectedItems] = useState<number[]>([])
	const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)

	const [sortParamsForm, setSortParamsForm] = useState({
		sortBy: '',
		order: '' as TSort
	})

	const {feedbacks, response, loading} = useTypedSelector(getMainFeedbackSelector);
	const {permissions} = useTypedSelector(getUserSelector);

	const tableSortBy = useMemo(() => {
		{
			if (!sortParamsForm.order) {
				return {id: null, desc: false}
			}
			return {id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0}
		}
	}, [sortParamsForm])

	const Requests = {
		getFeedbacks: () => {
			dispatch(feedbackActions.getFeedbacks({
				limit: ITEM_PER_PAGE,
				page,
				lang: local,
				search: searchForm.searchValue,
				date_start: searchForm.dateStart,
				date_end: searchForm.dateEnd,
				...sortParamsForm
			}))
		},
		removeFeedbacks: (_ids: string[]) => {
			dispatch(feedbackActions.removeFeedback({_ids}))
		}
	}

	const Events = {
		onPageChangeHandler: ({selected}: { selected: number }) => {
			setPage(selected)
		},
		onChangeHandler: (e: React.SyntheticEvent) => {
			const input = e.target as HTMLInputElement
			setSearchForm({...searchForm, [input.name]: input.value})
			Requests.getFeedbacks()
		},
		onSubmitSearchHandler: (e: SyntheticEvent) => {
			e.preventDefault()
			Requests.getFeedbacks()
		},
		sortToggleHandler: (sortBy: string, order: TSort) => {
			setSortParamsForm({sortBy, order})
		},
		createButtonClickHandler: () => {
			navigate('./create')
		},
		editClickHandler: (e: SyntheticEvent, index: number) => {
			navigate(`../feedback/profile/${feedbacks.data[index]._id}`)
		},
		checkboxClickHandler: (e: SyntheticEvent, hasCheckboxesActiveState: boolean, ckeckedItemsIndex: number[]) => {
			setRemoveButtonState(hasCheckboxesActiveState)
			setSelectedItems(ckeckedItemsIndex)
		},
		removeFeedbacks: () => {
			Requests.removeFeedbacks(selectedItems.map((index) => feedbacks.data[index]._id))
			setRemoveButtonState(false)
			setSelectedItems([])
		},
	}

	const data = useMemo(
		() =>
			feedbacks.data.map((f: any) => {
				return {
					id: f.id,
					name: `${f?.customer?.name ?? '-'} ${f?.customer?.secondName ?? ''}`,
					date: moment(f.createdAt).format("DD.MM.YYYY HH:mm"),
					rating: (<StarContainer>
						<StarRating rating={f?.mark}/>
					</StarContainer>),
					product: f.product?.description?.title ?? '-',
					feedback: f.comment
				}
			}),
		[feedbacks]
	)

	const columns = useMemo(
		() => [
			{
				Header: t('id'),
				accessor: 'id', // accessor is the "key" in the data
				width: 50
			},
			{
				Header: t('date'),
				accessor: 'date',
				width: 200
			},
			{
				Header: t('product'),
				accessor: 'product',
				sortToggleHandler: Events.sortToggleHandler,
				width: 300
			},
			{
				Header: t('client'),
				accessor: 'name',
				sortToggleHandler: Events.sortToggleHandler,
				width: 250
			},
			{
				Header: t('rating'),
				accessor: 'rating',
				width: 200,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('feedback'),
				accessor: 'feedback',
				width: 400
			}
		],
		[]
	)

	useEffect(() => {
		Requests.getFeedbacks()
		setSearchParams({...searchParams, page: '' + page})
	}, [page, sortParamsForm, searchForm])

	// Set default page uri
	useEffect(() => {
		setPage(!isNaN(parseInt(searchParams.get('page') as string)) ? parseInt(searchParams.get('page') as string) : 0)
	}, [])

	useEffect(() => {
		if (response == RESPONSE.REMOVED) {
			Requests.getFeedbacks()
		}
	}, [response]);

	useEffect(() => {
		if (!loading && feedbacks?.meta?.needReload) Requests.getFeedbacks()
	});

	const feedback = permissions.filter((el:string) => el === 'feedback').join();
	if(feedback !== 'feedback') navigate('/404');
	return (
		<Container>
			<Header>
				<TitleBlock>
					<Title>{t('feedback')}</Title>
				</TitleBlock>
				<FlexStyledContainer>
					{removeButtonState && (
						<ButtonBlock>
							<ButtonNew theme="red" onClick={Events.removeFeedbacks}>
								{t('remove.all')}
							</ButtonNew>
						</ButtonBlock>
					)}
				</FlexStyledContainer>
			</Header>
			<MainContainer>
				<FlexContainer gap="30px" direction="column">
					<HeaderWrapper>
						<FiltersWrapper>
							<SearchBar
								name="searchValue"
								placeholder={t('search')}
								value={searchForm.searchValue}
								onChange={Events.onChangeHandler}
								onSubmit={Events.onSubmitSearchHandler}
							/>

							<DateInput name="dateStart" value={searchForm.dateStart} onChange={Events.onChangeHandler}/>
							<DateInput name="dateEnd" value={searchForm.dateEnd} onChange={Events.onChangeHandler}/>
						</FiltersWrapper>

						<DataTotal>
							{t('total.feedback')} {feedbacks?.meta?.totalCount}
						</DataTotal>
					</HeaderWrapper>

					<Table
						columns={columns}
						data={data}
						sortBy={tableSortBy}
						editable
						editClickHandler={Events.editClickHandler}
						checkboxClickHandler={Events.checkboxClickHandler}
					/>
				</FlexContainer>

				<Pagination
					page={page}
					pageCount={feedbacks.meta ? Math.ceil(feedbacks.meta.totalCount / ITEM_PER_PAGE) : 1}
					onPageChange={Events.onPageChangeHandler}
				/>
			</MainContainer>
		</Container>
	)
}

export default Feedback
