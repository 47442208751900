import { createReducer } from '@reduxjs/toolkit'
import { adminActions } from './action'
import { TInitialState } from './types'

const initialState: TInitialState = {
   adminList: {
      meta: null,
      data: []
   },
   admin: null,
   newAdmin: null,
   loading: false,
   response: null,
   name: null,
   secondName: null,
   accessToken: null,
   refreshToken: null,
   login: null,
   exp: null,
   role: null,
   image: null
}

const reducer = createReducer<TInitialState>(initialState, (builder) => {
   builder
      .addCase(adminActions.setAdmins, (state, { payload }) => ({ ...state, adminList: payload }))
      .addCase(adminActions.setAdmin, (state, { payload }) => ({ ...state, ...payload}))
      .addCase(adminActions.setNewAdmin, (state, {payload}) => ({...state, newAdmin: payload}))
      .addCase(adminActions.setAdminState, (state, { payload }) => ({ ...state, response: null, ...payload }))

})

export default reducer
