import {AxiosResponse} from 'axios'
import { HttpService } from '../http.service'
import {TResponse} from '../types'
import {CATEGORY_URL} from './config'
import {
   TCreateCategoryRequestPayload,
   TEditCategoryRequestPaylaod,
   TGetCategoriesRequestPayload,
   TGetCategoryBySectionPayload,
   TGetCategoryRequestPayload,
   TRemoveCategoryRequestPayload
} from './types'

export class ApiCategoryService extends HttpService {
   static getCategories({
      token,
      limit = 10,
      page = 0,
      order = '',
      sortBy = '',
      lang = 'ua'
   }: TGetCategoriesRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: CATEGORY_URL.getCategories,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: limit * page,
            order,
            sortBy,
            lang
         }
      })
   }

   static getCategoryBySection({ token, _id, lang = 'ua' }: TGetCategoryBySectionPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CATEGORY_URL.getCategoryBySection}/${_id}/bySection`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            lang
         }
      })
   }

   static removeCategory({ token, _id }: TRemoveCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CATEGORY_URL.removeCategory}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })
   }

   static editCategory({ token, _id, data }: TEditCategoryRequestPaylaod): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CATEGORY_URL.editCategory}/${_id}`,
         method: 'PATCH',
         headers: {
            Authorization: token
         },
         data
      })
   }

   static createCategory({ token, data }: TCreateCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: CATEGORY_URL.createCategory,
         method: 'POST',
         headers: {
            Authorization: token
         },
         data
      })
   }

   static getCategory({ token, _id, lang = 'ua' }: TGetCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CATEGORY_URL.getCategory}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            lang
         }
      })
   }
   static getDescription({ token, _id }: TGetCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CATEGORY_URL.getDescriptionCategory}/${_id}/descriptions`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })
   }
}
