import { createReducer } from '@reduxjs/toolkit'
import { sectionActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   sections: {
      data: [],
      meta: null
   },
   loading: false,
   response: null,
   description: {
      data: [],
      meta: null
   }
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(sectionActions.setSections, (state, { payload }) => ({ ...state, sections: payload }))
      .addCase(sectionActions.setSectionState, (state, { payload }) => ({ ...state, response: null, ...payload }))
     .addCase(sectionActions.setDescriptionSection, (state, { payload }) => ({ ...state, description: payload }))
})

export default reducer
