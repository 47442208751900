import React, {
  FC,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../hooks';
import { FlexContainer, H2 } from '../Styled';
import { api } from '../../config/api';

import { getMainProductSelector, productActions } from "../../../store";
import { getUserSelector } from "../../../store/user";
import { TProduct } from "../../../store/product/types";
import { TCategory } from "../../../store/category/types";
import { ApiCategoryService } from "../../../store/category/api.service";

import { RelativePreloader } from '../Preloader';
import { SearchBar } from '../SearchBar';
import { Popup } from '../Popup';
import { DateInput } from '../DateInput'
import { InfinityScroll } from '../InfinityScroll';
import { ButtonNew } from "../Button";
import { Table, TSort } from '../Table';
import { PaginateDropDownList, TOption } from '../PaginateDropDownList';
import { getLocale } from "../../../types";

import { DROP_DOWN_LIST_LIMIT, PER_PAGE_ITEMS } from './consts'
import { TSelectProductPopup } from './types'
import {
  Container,
  Image,
  PreloaderContainer,
  TableContainer
} from './styled';

const ProductPopup: FC<TSelectProductPopup> = ({ closeClickHandler, addProductClickHandler, activeItems }) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const local = getLocale();

   const { accessToken } = useTypedSelector(getUserSelector);
   const { products } = useTypedSelector(getMainProductSelector);

   const [productsList, setProductsList] = useState<TProduct[]>([]);
   const [page, setPage] = useState<number>(0);
   const [loading, setLoading] = useState<boolean>(true);
   const [activeProductsList, setActiveProductsList] = useState<string[]>([]);
   const [searchForm, setSearchForm] = useState({
      regex: '',
      date_start: '',
      date_end: '',
      category: { value: '', label: '' } as TOption
   });
   const [typedOrders, setTypedOrders] = useState<any>([]);
   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   });

   const tableSortBy = useMemo(() => {{
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }}
   }, [sortParamsForm])

   const Events = {
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      categoryListChangeHandler: (value: TOption) => {
         setSearchForm((form) => ({ ...form, category: value }))
      },
      loadOptions: async (search: string, loadOptions: any, additions: any) => {
         const page = additions?.page || 0

         const options = await Requests.getCategories(page)

         return {
            options: (options.data.data as TCategory[]).map((item) => ({
               value: item._id,
               label: item.title
            })),
            hasMore: options.data.meta.totalCount > page * DROP_DOWN_LIST_LIMIT,
            additional: {
               page: page + 1
            }
         }
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      checkboxClickHandler: (e: SyntheticEvent, p: any, t: any, index: number) => {
         setActiveProductsList((list) => {
            const isChecked = (e.target as HTMLInputElement).checked
            const arr = list
            let id: string = ''

            productsList.forEach((product, i) => {
               if (index === i) id = product._id
            })

            if (isChecked && id) { // @ts-ignore
               return [...new Set([...arr].concat([id]))]
            }

            // @ts-ignore
            return [...new Set(arr.filter((item) => item !== id))]
         })
      },
      handlerBlurFilter: () => {
         setSearchForm((form:any) => ({...form, category: {value: '', label: ''}}))
      },
      nextProducts: () => {
         Requests.getProducts(page)
         setPage((page) => page + 1)
      },
      addProductClickHandler: () => {
         addProductClickHandler && addProductClickHandler(activeProductsList)
      }
   }

   const Requests = {
      getCategories: async (page: number) => {
         return await ApiCategoryService.getCategories({token: accessToken as string, page})
      },
      getProducts: (p: number = page) => {
         dispatch(
            productActions.getProducts({
               limit: PER_PAGE_ITEMS,
               page: p,
               lang: local,
               query: searchForm.regex,
               date_start: searchForm.date_start,
               date_end: searchForm.date_end,
               category: [searchForm.category.value],
               ...sortParamsForm
            })
         )
      }
   }

   const Utils = {
      clearProducts: () => {
         dispatch(productActions.setProducts({ data: [], meta: null }))
      }
   }

   const columns = useMemo(
      () => [
         {
            Header: t('product.code'),
            accessor: 'id',
            width: 150,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('label'),
            accessor: 'label',
            width: 250
         },
         {
            Header: t('price'),
            accessor: 'price',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
        {
          Header: t('product.status'),
          accessor: 'sellStatus',
          width: 150,
          sortToggleHandler: Events.sortToggleHandler
        }
      ],
      []
   )

   const data = () => {
      return productsList.map((product) => {
         return {
            id: (<>{product.sku}</>),
            photo: (<Image src={`${api.withImageAPI}/product/preview/${product.preview}`}/>),
            label: (<>{product.description && product.description.title}</>),
            price: (<>{product.discountPrice ? product.discountPrice !== null ? product.discountPrice !== 0 ? product.discountPrice : product.price : product.price : product.price}</>),
            sellStatus: <>{t(product.sellStatus.split(' ').join('.'))}</>,
            isChecked: activeProductsList.includes(product._id)
         }
      })
   }

   useEffect(() => {
      setTypedOrders(data())
   }, [productsList]);

 useEffect(() => {
      setProductsList([])
      Requests.getProducts(0)
      setPage(0)
   }, [searchForm, tableSortBy])

   // Append new support request data in data state
   useEffect(() => {
      if (products.meta?.totalCount) {
         setProductsList((productsList) => {
            return productsList.concat(products.data)
         })
         products.meta?.totalCount <= productsList.length + products.data.length && setLoading(false)
      }
   }, [products])

   useEffect(() => {
      activeItems && setActiveProductsList(activeItems)
   }, [activeItems])

   useEffect(() => {
      return () => {
         Utils.clearProducts()
      }
   }, [])

   return (
     <Container>
        <Popup backgroundClickListener={closeClickHandler} width="1158px" height="844px">
           <FlexContainer direction="column" align="center" justify="center" style={{ padding: '40px 0' }}>
              <H2>{t('add.product')}</H2>
              <FlexContainer justify="space-around" style={{ marginTop: '33px' }}>
                 <SearchBar
                   name="regex"
                   placeholder={t('search')}
                   value={searchForm.regex}
                   onChange={Events.onChangeHandler}
                 />
                 <FlexContainer gap="30px" width="auto">
                    <DateInput name="date_start" value={searchForm.date_start} onChange={Events.onChangeHandler} />
                    <DateInput name="date_end" value={searchForm.date_end} onChange={Events.onChangeHandler} />
                 </FlexContainer>
                 <PaginateDropDownList
                   onBlur={Events.handlerBlurFilter}
                   loadOptions={Events.loadOptions}
                   onChange={Events.categoryListChangeHandler}
                   placeholder={t('filter.by.category')}
                   value={searchForm.category}
                 />
              </FlexContainer>
              <TableContainer>
                 <InfinityScroll
                   next={Events.nextProducts}
                   loading={loading}
                   preloader={
                      <PreloaderContainer>
                         <RelativePreloader size="50px" loading />
                      </PreloaderContainer>
                   }>
                    <Table
                      columns={columns}
                      data={typedOrders}
                      sortBy={tableSortBy}
                      checkboxClickHandler={Events.checkboxClickHandler}
                    />
                 </InfinityScroll>
              </TableContainer>
              <FlexContainer align="center" justify="center" gap="30px" style={{ paddingTop: '30px' }}>
                 <ButtonNew theme="gray" style="transparant" onClick={closeClickHandler}>
                    {t('cancel')}
                 </ButtonNew>
                 <ButtonNew theme="green" onClick={Events.addProductClickHandler}>
                    {t('add')}
                 </ButtonNew>
              </FlexContainer>
           </FlexContainer>
        </Popup>
     </Container>
   )
}

export default ProductPopup
