import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export class HttpService {
	static async request<T = any>(
		identifier: string,
		axiosConfig: AxiosRequestConfig,
	): Promise<AxiosResponse<T>> {
		const { method, url } = axiosConfig
		const baseMessage = `${method} ⟴ Axios ⟴ ${identifier} ⟴ URL: ${url}`

		try {
			return await axios.request(axiosConfig)
		} catch (e:any) {
			throw new Error(e)
		}
	}
}