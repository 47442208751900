import { createAction } from '@reduxjs/toolkit'
import {
	GET_BRANDS,
	SET_BRANDS,
	SET_BRANDS_STATE,
	CREATE_BRAND,
	EDIT_BRAND,
	REMOVE_BRAND
} from './consts'

export const brandActions = {
	getBrands: createAction(GET_BRANDS, (payload) => ({ payload })),
	setBrands: createAction(SET_BRANDS, (payload) => ({ payload })),
	setBrandsState: createAction(SET_BRANDS_STATE, (payload) => ({payload})),
	createBrand: createAction(CREATE_BRAND, (payload) => ({ payload })),
	editBrand: createAction(EDIT_BRAND, (payload) => ({ payload })),
	removeBrand: createAction(REMOVE_BRAND, (payload) => ({ payload })),
}
