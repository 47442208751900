import styled from 'styled-components';

import { TStyledPreloader } from './types';
import { colors } from "../../styles";

export const AbsoluteContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   height: 100vh;
   width: 100%;
   max-width: 100%;
   left: 0;
   top: 0;
   z-index: 1000;
   background-color: ${colors.gray};
`

export const LoadingBar = styled.div<TStyledPreloader>`
   width: ${({ size }) => size};
   height: ${({ size }) => size};

   border: 2px solid ${({ secondaryColor }) => secondaryColor};
   border-top: 3px solid ${({ color }) => `${color}`};

   border-radius: 100%;

   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   margin: auto;

   animation: spin 1.2s infinite ease;

   @keyframes spin {
      from {
         transform: rotate(0deg);
      }
      to {
         transform: rotate(360deg);
      }
   }
`

export const RelativeLoadingBar = styled(LoadingBar)`
   position: relative;
`
