import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from "moment";

import {
	ButtonNew,
	DataTotal,
	DateInput,
	FiltersWrapper,
	FlexContainer,
	HeaderWrapper,
	Pagination,
	ProfileImage,
	SearchBar,
	Table,
	useTypedSelector,
} from "../../common";
import { adminActions, getMainAdminSelector} from "../../store";

import {
	ButtonBlock,
	ButtonContainer,
	Container,
	MainContainer,
	Title,
	Header,
} from './styled';
import { api } from '../../common/config/api';
import { getUserSelector } from "../../store/user";

const Role: FC = () => {
	const ITEM_PER_PAGE = 8;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { adminList, loading } = useTypedSelector(getMainAdminSelector);
	const { permissions } = useTypedSelector(getUserSelector);

	const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);
	const [page, setPage] = useState<number | null>(null);
	const [searchForm, setSearchForm] = useState({
		searchValue: '',
		startDateValue: '',
		endDateValue: ''
	});
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [searchParams, setSearchParams] = useSearchParams({});

	const data = useMemo(
		() =>
			adminList?.data?.map((admin) => {
				const createAt = admin?.createdAt
				return {
					id: <>{admin.id}</>,
					photo:(
						<ProfileImage src={api.withImageAPI + '/admin/' + admin?.image} />
					) ,
					name: (
						<>
							{admin.name} {admin.secondName}
						</>
					),
					login: admin.login,
					created: <p>{moment(createAt).format('DD.MM.YYYY HH:mm')}</p>
				}
			}),
		[adminList?.data]
	);

	const columns = useMemo(
		() => [
			{
				Header: t('id'),
				accessor: 'id', // accessor is the "key" in the data
				width: 250
			},
			{
				Header: t('photo'),
				accessor: 'photo'
			},
			{
				Header: t('name'),
				accessor: 'name',
				width: 250
			},
			{
				Header: t('login'),
				accessor: 'login',
				width: 250
			},
			{
				Header: t('created'),
				accessor: 'created',
				width: 250
			}
		],
		[]
	);

	const Events = {
		createClickHandler: () => {
			navigate('../createRole')
		},
		onChangeHandler: (e: React.SyntheticEvent) => {
			const input = e.target as HTMLInputElement
			setSearchForm({ ...searchForm, [input.name]: input.value })
			Requests.getAdmins()
		},
		onSubmitSearchHandler: (e: SyntheticEvent) => {
			e.preventDefault()
			Requests.getAdmins()
		},
		checkboxClickHandler: (e: SyntheticEvent, hasCheckboxesActiveState: boolean, ckeckedItemsIndex: number[]) => {
			setRemoveButtonState(hasCheckboxesActiveState)
			setSelectedItems(ckeckedItemsIndex)
		},
		onPageChangeHandler: ({ selected }: { selected: number }) => {
			setPage(selected)
		},
		editClickHandler: (e: SyntheticEvent, index: number) => {
			const { _id } = adminList?.data[index]
			navigate(`/editRole/${_id}`)
		},
		removeClickHandler: (e: SyntheticEvent, index: number) => {
			const { _id } = adminList?.data[index]
			Requests.removeAdmin(_id)
		},
		removeCheckedItemsHandler: () => {
			selectedItems.forEach((index) => {
				const { _id } = adminList?.data[index]
				Requests.removeAdmin(_id)
			})
			setRemoveButtonState(false)
			setSelectedItems([])
		}
	};

	const Requests = {
		getAdmins: () => {
			page !== null &&
			dispatch(
				adminActions.getAdmins({
					limit: ITEM_PER_PAGE,
					page: page,
					value: searchForm.searchValue,
					start: searchForm.startDateValue,
					end: searchForm.endDateValue
				})
			)
		},
		removeAdmin: (_id: string) => {
			dispatch(adminActions.removeAdmin({ _id }))
		}
	}

	// Request to receive data if page changed
	useEffect(() => {
		Requests.getAdmins()
		adminList?.data?.length && setSearchParams({ ...searchParams, page: '' + page })
	}, [page, searchForm]);

	// Set default page uri
	useEffect(() => {
		setPage(!isNaN(parseInt(searchParams.get('page') as string)) ? parseInt(searchParams.get('page') as string) : 0)
	}, []);

	// Get data if there is no data
	useEffect(() => {
		if (!loading && adminList?.meta?.needReload) Requests.getAdmins()
	});

	const admin = permissions.filter((el:string) => el === 'admin').join();
	if(admin !== 'admin') navigate('/404');
	return (
		<Container>
			<Header>
				<Title>{t('roles')}</Title>
				<ButtonContainer>
					{removeButtonState && (
						<ButtonBlock>
							<ButtonNew theme="red" onClick={Events.removeCheckedItemsHandler}>
								{t('remove.all')}
							</ButtonNew>
						</ButtonBlock>
					)}
					<ButtonBlock>
						<ButtonNew theme='green' onClick={Events.createClickHandler}>
							{t('create')}
						</ButtonNew>
					</ButtonBlock>
				</ButtonContainer>
			</Header>
			<MainContainer>
				<FlexContainer direction="column" gap="30px">
					<HeaderWrapper>
						<FiltersWrapper>
							<SearchBar
								name="searchValue"
								placeholder={t('search')}
								value={searchForm.searchValue}
								onChange={Events.onChangeHandler}
								onSubmit={Events.onSubmitSearchHandler}
							/>

							<DateInput
								name="startDateValue"
								value={searchForm.startDateValue}
								onChange={Events.onChangeHandler}
							/>
							<DateInput name="endDateValue" value={searchForm.endDateValue} onChange={Events.onChangeHandler} />
						</FiltersWrapper>

						<DataTotal>
							{t('total.staff')} {adminList?.meta?.totalCount}
						</DataTotal>
					</HeaderWrapper>

					<Table
						columns={columns}
						data={data}
						checkboxClickHandler={Events.checkboxClickHandler}
						removeClickHandler={Events.removeClickHandler}
						editClickHandler={Events.editClickHandler}
						removable
						editable
					/>
				</FlexContainer>

				<Pagination
					page={page || 0}
					pageCount={adminList?.meta ? Math.ceil(adminList?.meta?.totalCount / ITEM_PER_PAGE) : 1}
					onPageChange={Events.onPageChangeHandler}
				/>
			</MainContainer>
		</Container>
	)
}

export default Role