export const GET_PRODUCTS = 'GET_PRODUCTS'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCT_STATE = 'SET_PRODUCT_STATE'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const CREATE_PRODUCT= 'CREATE_PRODUCT'
export const SET_PRODUCT = 'SET_PRODUCT'
export const GET_PRODUCT = 'GET_PRODUCT'
export const CREATE_GROUP = 'CREATE_GROUP'
export const GET_GROUPS = 'GET_GROUPS'
export const SET_GROUPS = 'SET_GROUPS'
export const GET_GROUP = 'GET_GROUP'
export const SET_GROUP = 'SET_GROUP'
export const EDIT_GROUP = 'EDIT_GROUP'
export const CREATE_VARIATION_BULK = 'CREATE_VARIATION_BULK'
export const SET_VARIATIONS_BULK = 'SET_VARIATIONS_BULK'
export const EDIT_VARIATION = 'EDIT_VARIATION'
export const EDIT_VARIATION_VALUE = 'EDIT_VARIATION_VALUE'
export const CREATE_VARIATION_VALUE = 'CREATE_VARIATION_VALUE'
export const SET_VARIATIONS_VALUE = 'SET_VARIATIONS_VALUE'
export const REMOVE_VARIATION = 'REMOVE_VARIATION'
export const CREATE_PHOTO_PRODUCT = 'CREATE_PHOTO_PRODUCT'
export const GET_PHOTO_PRODUCT = 'GET_PHOTO_PRODUCT'
export const SET_PHOTO_PRODUCT = 'SET_PHOTO_PRODUCT'
export const SET_PRODUCTS_GROUP = 'SET_PRODUCTS_GROUP'
export const GET_PRODUCTS_GROUP = 'GET_PRODUCTS_GROUP'
export const GET_DESCRIPTION = 'GET_DESCRIPTION'
export const SET_DESCRIPTION = 'SET_DESCRIPTION'
export const GET_VARIATION_VALUE = 'GET_VARIATION_VALUE'
export const SET_VARIATION_VALUE = 'SET_VARIATION_VALUE'

export const RESPONSE = {
	EDITED_VARIATIONS_VALUE: 'EDITED_VARIATIONS_VALUE',
	EDITED_VARIATIONS: 'EDITED_VARIATIONS',
	EDITED_GROUP: 'EDITED_GROUP',
	CREATED_GROUP: 'CREATED_GROUP',
	CREATED: 'CREATED',
	EDITED: 'EDITED',
	NOT_FOUNDED: 'NOT_FOUNDED',
	REMOVED: 'REMOVED',
	CREATED_VARIATION_BULK: 'CREATED_VARIATION_BULK',
	CREATED_PHOTO: 'CREATED_PHOTO',
	CREATED_VARIATION_VALUE: 'CREATED_VARIATION_VALUE'
}