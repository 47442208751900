import { createReducer } from '@reduxjs/toolkit'
import { clientActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   clients: {
      data: [],
      meta: null
   },
   client: null,
   loading: false,
   response: null
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(clientActions.setClients, (state, { payload }) => ({ ...state, clients: { ...payload } }))
      .addCase(clientActions.setClientState, (state, { payload }) => ({ ...state, response: null, ...payload }))
      .addCase(clientActions.setClient, (state, { payload }) => ({ ...state, client: payload }))
})

export default reducer
