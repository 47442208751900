import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import {
  getSupportRequestsSelector,
  getSupportSelector,
  supportActions,
} from "../../store";
import { TRequest } from "../../store/support/types";

import {
  ButtonNew,
  DropDownList,
  FlexContainer,
  SpaceBetweenContainer,
  TColumnTable,
  useTypedSelector,
  Textarea,
  TDataTable,
  Pagination,
  Table,
  RelativePreloader,
} from "../../common";

import {
  Container,
  Title,
  Header,
  DateField,
  FormContainer,
  MainContainer,
  Message,
  TableContainer,
} from "./styled";
import { ITEM_PER_PAGE } from "./consts";

const Support: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const requests = useTypedSelector(getSupportRequestsSelector);
  const { loading } = useTypedSelector(getSupportSelector);

  const [page, setPage] = useState<number>(0);
  const [requestTargetIndex, setRequestTargetIndex] = useState<number>(-1);
  const [supportRequests, setSupportRequests] = useState<TRequest[]>([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [form, setForm] = useState({
    status: "",
  });

  const statusOptions = useMemo(
    () => [
      {
        name: t("new"),
        value: "new",
      },
      {
        name: t("unprocessed"),
        value: "unprocessed",
      },
      {
        name: t("processed"),
        value: "processed",
      },
    ],
    []
  );

  const data: TDataTable[] = useMemo(
    () =>
      supportRequests.map((request) => {
        return {
          date: moment(request.updatedAt).format("DD.MM.YYYY HH:mm"),
          message: <Message>{request.message}</Message>,
          status: <>{t(request.status)}</>,
        };
      }),
    [supportRequests]
  );

  const columns: TColumnTable[] = useMemo(
    () => [
      {
        Header: t("date"),
        accessor: "date", // accessor is the "key" in the data
      },
      {
        Header: t("message"),
        accessor: "message",
        width: "356px",
      },
      {
        Header: t("status"),
        accessor: "status",
      },
    ],
    []
  );

  const Requests = {
    getRequests: () => {
      dispatch(supportActions.getRequests({ page, limit: ITEM_PER_PAGE }));
    },
    modifyRequest: () => {
      dispatch(
        supportActions.modifyRequest({
          _id: supportRequests[requestTargetIndex]._id,
          status: form.status,
        })
      );
    },
  };

  useEffect(() => {
    setSupportRequests(requests.data);
  }, [requests, requestTargetIndex]);

  const Events = {
    requestClickHandler(index: number) {
      setRequestTargetIndex(index);
    },
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    changeStatusHandler: (e: SyntheticEvent) => {
      setForm((form) => ({
        ...form,
        status: (e.target as HTMLSelectElement).value,
      }));
    },
    saveButtonHandler: () => {
      Requests.modifyRequest();
      Requests.getRequests();
    },
  };

  useEffect(() => {
    Requests.getRequests();
    setSearchParams({ ...searchParams, page: "" + page });
  }, [page]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  useEffect(() => {
    requestTargetIndex >= 0 &&
      setForm((form) => ({
        ...form,
        status: supportRequests[requestTargetIndex].status,
      }));
  }, [requestTargetIndex]);

  return (
    <>
      {!loading ? (
        <Container>
          <Header>
            <Title>{t("support")}</Title>
          </Header>
          <MainContainer>
            <TableContainer>
              <Table
                columns={columns}
                data={data}
                rowClickHandler={Events.requestClickHandler}
              />
              <Pagination
                page={page}
                pageCount={
                  requests.meta
                    ? Math.ceil(requests.meta.totalCount / ITEM_PER_PAGE)
                    : 1
                }
                onPageChange={Events.onPageChangeHandler}
              />
            </TableContainer>

            {requestTargetIndex >= 0 && (
              <FormContainer style={{ overflowY: "auto" }}>
                <DateField>
                  {moment(supportRequests[requestTargetIndex].updatedAt).format(
                    "YYYY-MM-DD HH:mm"
                  )}
                </DateField>

                <SpaceBetweenContainer style={{ marginTop: "49px" }}>
                  <Textarea
                    disabled={true}
                    width={"318px"}
                    height={"50px"}
                    label={t("name")}
                    readonly
                  >
                    {supportRequests[requestTargetIndex]?.name}
                  </Textarea>
                  <Textarea
                    disabled={true}
                    width={"318px"}
                    height={"50px"}
                    label={t("phone")}
                    readonly
                  >
                    {supportRequests[requestTargetIndex]?.phone}
                  </Textarea>
                </SpaceBetweenContainer>
                <FlexContainer direction="row" style={{ marginTop: "30px" }}>
                  <Textarea disabled={true} label={t("message")} readonly>
                    {supportRequests[requestTargetIndex].message}
                  </Textarea>
                </FlexContainer>
                <SpaceBetweenContainer style={{ marginTop: "30px" }}>
                  <DropDownList
                    options={statusOptions}
                    onChange={Events.changeStatusHandler}
                    value={form.status}
                  />
                  <ButtonNew
                    width={209}
                    height={45}
                    onClick={Events.saveButtonHandler}
                  >
                    {t("save")}
                  </ButtonNew>
                </SpaceBetweenContainer>
              </FormContainer>
            )}
          </MainContainer>
        </Container>
      ) : (
        <RelativePreloader />
      )}
    </>
  );
};

export default Support;
