export const GET_LOGO = 'GET_LOGO'
export const SET_LOGO = 'SET_LOGO'
export const SET_SETTING_STATE = 'SET_SETTING_STATE'
export const EDIT_LOGO = 'EDIT_LOGO'

export const RESPONSE = {
	NOT_FOUND: 'NOT_FOUND',
	CREATED: 'CREATED',
	EDITED:'EDITED',
	REMOVED:'REMOVED'
}
