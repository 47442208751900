import React, {
	FC,
	SyntheticEvent,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	getMainProductSelector,
	productActions,
	RESPONSE,
	showToastAction,
} from "../../store";
import {
	Assets,
	ButtonNew,
	generateErrorToast,
	useTypedSelector,
	useValidation
} from "../../common";

import {
	BasicData,
	CreateVariation,
	ProductCharacteristics,
	ProductSubBar
} from "./components";
import {
	Image,
	Container,
	Wrapper,
	Header,
	Title,
	TitleBlock,
	Message
} from './styled';
import {
	TCreateProductValidationSchema,
	TForm,
	TFormDe,
	TFormEn,
	TFormGroup,
	TFormUa,
	TProductGroup,
	TProductTypeOptions,
	TResultPhotos,
	TStatusOptions,
	TVariationsData,
	TVisibilityOptions
} from "./types";

const CreateProduct: FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const productTypeOptions:TProductTypeOptions[] = [
		{name: t('simple'), value: 'simple'},
		{name: t('variated'), value: 'variated'}
	];
	const visibilityOptions:TVisibilityOptions[] = [
		{name: t('show'), value: true},
		{name: t('not.show'), value: false}
	];
	const statusOptions:TStatusOptions[] = [
		{name: t('in.stock'), value: 'available'},
		{name: t('out.stock'), value: 'notavailable'}
	];

	const [form, setForm] = useState<TForm>({
		type: productTypeOptions[0].value,
		show: visibilityOptions[0].value,
		sku: '',
		nameUa: '',
		nameDe: '',
		nameEn: '',
		descriptionUa: '',
		descriptionDe: '',
		descriptionEn: '',
		category: '',
		subcategory: '',
		section: '',
		price: '',
		amount: '',
		discountPrice: '',
		brand: '',
		sellStatus: statusOptions[0].value,
	});

	const [variationsData, setVariationData] = useState<TVariationsData[]>([
		{
			order: 1,
			title: [{title: '', lang: 'en'}],
			type: '',
			values: [
				{
					order: 1,
					bgUrl: '',
					color: '',
					title: [
						{title: '', lang: 'en'}
					],
				}
			]
		}
	]);
	const selectColor = variationsData.filter(el => el.type=="color").reduce(
          (store: Array<string>, prev) => [
            ...store,
            ...(prev?.values?.map(item => item.color) ?? []),
          ],
		[],
	)

	const selectText = variationsData.filter(el => el.type=="text").reduce(
		(store: Array<string>, prev) => [
		  ...store,
		  ...(prev?.values?.map(item => item.title[0].title) ?? []),
		],
		[],
  ) 

	const [formPhoto, setFormPhoto] = useState({
		photos: [] as File[]
	});

	const [preview, setPreview] = useState<TResultPhotos>({
		photos: []
	});

	const [resultPhotos, setResultPhoto] = useState<TResultPhotos>({
		photos: []
	})
	const [formGroup, setFormGroup] = useState<TFormGroup[]>([
		{ variation: '', values: ''}
	]);
	const [activePhotoOnChange, setActivePhotoOnChange] = useState(false)
	const [productGroup, setProductGroup] = useState<TProductGroup[]>([
		{ variation: '', value: ''}
	]);
	const [activePage, setActivePage] = useState<string>('mainData');
	const [formCharacteristicUa, setFormCharacteristicUa] = useState<TFormUa[]>([]);
	const [formCharacteristicEn, setFormCharacteristicEn] = useState<TFormEn[]>([]);
	const [formCharacteristicDe, setFormCharacteristicDe] = useState<TFormDe[]>([]);

	const { response, variationsBulk, groups, photoProduct, products } = useTypedSelector(getMainProductSelector);

	const SKU = products?.data?.map((el) => el.sku);

	useEffect(() => {
		if(+form.amount <= 0 ){
			setForm((form) => ({...form, sellStatus: statusOptions[1].value}))
		}
		if(form.amount > '0'){
			setForm((form) => ({...form, sellStatus: statusOptions[0].value}))
		}
	}, [form.amount]);

	const filterPhoto = resultPhotos.photos.filter((el:string) => el !== undefined);
	const filterPreviewPhoto = preview.photos.filter((el:string) => el !== undefined);
	const galleryArray = filterPhoto?.map((el:string, index:number) => {
		return {
			order: index,
			image: el
		}
	});

	const Requests = {
		createProductPhoto: () => {
			const data = new FormData()
			formPhoto && data.append('preview', formPhoto.photos[0])
			formPhoto && data.append('gallery', formPhoto.photos[formPhoto.photos.length - 1])
			formPhoto.photos && dispatch(productActions.createPhotoProduct({ data }))
		},
		createProduct: () => {
			if(form.type === productTypeOptions[1].value){
				const data = {
					sku: form.sku,
					sellStatus: form.sellStatus,
					price: +form.price,
					show: form.show,
					category: form.category,
					subCategory: form.subcategory === '' ? undefined : form.subcategory,
					type: form.type,
					preview: filterPreviewPhoto[0],
					gallery: galleryArray,
					variations: productGroup,
					amount: form.amount,
					discountPrice: form.discountPrice === '' ? 0 : +form.discountPrice,
					brand: form.brand,
					description: [
						{
							lang:'en',
							title: form.nameEn,
							description: form.descriptionEn,
							attributes: formCharacteristicEn[0]?.value === '' ? [] : formCharacteristicEn
						},
						{
							lang:'ua',
							title: form.nameUa,
							description: form.descriptionUa,
							attributes: formCharacteristicUa[0]?.value === '' ? [] : formCharacteristicUa
						},
						{
							lang:'de',
							title: form.nameDe,
							description: form.descriptionDe,
							attributes: formCharacteristicDe[0]?.value === '' ? [] : formCharacteristicDe
						}
					],
					group: groups.data.slice(-1)[0]._id,
					dimensions: {
						weight: 0.1,
						width: 0.01,
						height: 0.01,
						length: 0.01
					}
				}
				dispatch(productActions.createProduct({data}))
			} else {
				const data = {
					sku: form.sku,
					sellStatus: form.sellStatus,
					price: +form.price,
					show: form.show,
					category: form.category,
					subCategory: form.subcategory === '' ? undefined : form.subcategory,
					type: form.type,
					preview: filterPreviewPhoto[0],
					gallery: galleryArray,
					amount: form.amount,
					brand: form.brand,
					discountPrice: form.discountPrice === '' ? 0 : +form.discountPrice,
					description: [
						{
							lang:'en',
							title: form.nameEn,
							description: form.descriptionEn,
							attributes: formCharacteristicEn[0]?.value === '' ? [] : formCharacteristicEn
						},
						{
							lang:'ua',
							title: form.nameUa,
							description: form.descriptionUa,
							attributes: formCharacteristicUa[0]?.value === '' ? [] : formCharacteristicUa
						},
						{
							lang:'de',
							title: form.nameDe,
							description: form.descriptionDe,
							attributes: formCharacteristicDe[0]?.value === '' ? [] : formCharacteristicDe
						}
					],
					dimensions: {
						weight: 0.1,
						width: 0.01,
						height: 0.01,
						length: 0.01
					}
				}
				dispatch(productActions.createProduct({data}))
			}
		}
	};

	useEffect(() => {
		if(photoProduct){
			setResultPhoto((photo) => ({
				photos: photo.photos.concat(photoProduct?.data?.gallery)
			}))
		}
	}, [photoProduct]);

	useEffect(() => {
		if(photoProduct){
			setPreview((photo) => ({
				photos: photo.photos.concat(photoProduct?.data?.preview)
			}))
		}
	}, [photoProduct]);

	const schema = useMemo<TCreateProductValidationSchema>(
		() => ({
			sku: {
				condition: !!form.sku && !SKU.includes(form.sku) && form.sku >= 0,
				error: t('enter.the.product.code.or.the.product.already.exists.with.this.code')
			},
			name:{
				condition: !!form.nameDe && !!form.nameEn && !!form.nameUa,
				error: t('enter.the.product.name.in.all.languages')
			},
			description: {
				condition: !!form.descriptionDe && !!form.descriptionUa && !!form.descriptionEn,
				error: t('enter.the.product.description.in.all.languages')
			},
			category: {
				condition: !!form.category,
				error: t('choose.a.category')
			},
			price: {
				condition: !!form.price && +form.price > 0,
				error: t('the.price.should.hit.more.than.zero')
			},
			discountPrice: {
				condition: +form.discountPrice < +form.price,
				error: t('the.price.with.discount.cannot.be.more.than.price.product')
			},
			brand: {
				condition: !!form.brand,
				error: t('choose.brand')
			},
			amount: {
				condition: !!form.amount && form.amount >= 0,
				error: t('enter.amount')
			},
			section: {
				condition: !!form.section,
				error: t('choose.section')
			},
			photo: {
				condition: formPhoto.photos.length !== 0,
					error: t('enter.photo')
			}
		}),
		[form, formPhoto]
	);

	useEffect(() => {
		setResultPhoto({photos: []})
		setPreview({photos: []})
	}, []);

	let valueArr = selectText.map((item) => { return item });
	let isDuplicate = valueArr.some((item, idx) => {
		return valueArr.indexOf(item) != idx
	})

	const {
		validation,
		enableValidation,
		disableValidation,
	} = useValidation(schema);

	const Events = {
		backButtonClickHandler: () => {
			navigate('../products-management')
		},
		inputChangeHandler: (e: SyntheticEvent) => {
			const {name, value} = e.target as HTMLInputElement
			setForm((props) => ({...props, [name]: value}))
		},
		inputChangeShowHandler: (e: SyntheticEvent) => {
			const {name, value} = e.target as HTMLInputElement;
			setForm((props) => ({...props, [name]: value === "true"}))
		},
		onPhotoChange: useCallback((file: File | null) => {
			file &&
			!formPhoto.photos.includes(file as File) &&
			setFormPhoto((form) => ({...form, photos: [...formPhoto.photos].concat(file)}))
			setActivePhotoOnChange(true);
			activePhotoOnChange && Requests.createProductPhoto();
		}, [formPhoto.photos]),
		createProductHandler: async () => {
			try {
				enableValidation();
				await validation();
				Requests.createProduct();
				disableValidation()
			} catch (error:any) {
				error?.map((err:string) => {
					dispatch(showToastAction.request(generateErrorToast(err)));
				});
			}
		},
	};

	useEffect(() => {
		if(response === RESPONSE.CREATED_VARIATION_BULK){
			setFormGroup(() => variationsBulk?.data?.map((g:any) => ({
				variation:  g?.variation?._id,
				values: g?.values?.map((el:any) => el?._id)
			})))
			const data = {
				variations: formGroup,
			}
			dispatch(productActions.createGroup({ data }))
		}
		if(response === RESPONSE.CREATED_GROUP){
			dispatch(productActions.getGroups({limit: 100000}))
		}
		if(response === RESPONSE.CREATED){
			setPreview({photos: []})
			setResultPhoto({photos: []})
			navigate('/products-management')
		}
	}, [response]);

	useEffect(() => {
		dispatch(productActions.getProducts({limit: 1000}))
		dispatch(productActions.getGroups({limit: 1000}))
	},[]);

	return (
		<Container>
			<Header>
				<TitleBlock>
					<Image onClick={Events.backButtonClickHandler} src={Assets.ARROW_LEFT}/>
					<Title>{t('create.product')}</Title>
				</TitleBlock>
				<ButtonNew theme="green" onClick={Events.createProductHandler}>
					{t('create')}
				</ButtonNew>
			</Header>
			<Wrapper>
				<ProductSubBar setActivePage={setActivePage} activePage={activePage}/>
				<>
					{activePage === 'mainData' &&
					<>
						<BasicData
							isDuplicate={isDuplicate}
							selectText={selectText}
							formPhoto={formPhoto}
							productTypeOptions={productTypeOptions}
							visibilityOptions={visibilityOptions}
							statusOptions={statusOptions}
							Events={Events}
							variationsData={variationsData}
							setVariationData={setVariationData}
							form={form}
							setForm={setForm}
							selectColor={selectColor}
						/>
						</>
					}
					{activePage === 'characteristics' &&
						<ProductCharacteristics
							setFormCharacteristicDe={setFormCharacteristicDe}
							setFormCharacteristicEn={setFormCharacteristicEn}
							setFormCharacteristicUa={setFormCharacteristicUa}
						/>
					}
					{ activePage === 'variations' &&
						<CreateVariation
							groups={groups}
							setFormGroup={setFormGroup}
							productGroup={productGroup}
							setProductGroup={setProductGroup}
							formGroup={formGroup}
							form={form}
							Events={Events}
							visibilityOptions={visibilityOptions}
							statusOptions={statusOptions}
							productTypeOptions={productTypeOptions}
							formPhoto={formPhoto}
						>
							<Message>{t('select.product.type')}</Message>
						</CreateVariation>
					}
				</>
			</Wrapper>
		</Container>
	)
}

export default CreateProduct
