import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  Assets,
  ButtonNew,
  DropDownList,
  Input,
  RelativePreloader,
  SubPagesBar,
  TSection,
  useTypedSelector,
} from "../../common";
import {
  bonusActions,
  clientActions,
  getBonusesSelector,
  getMainClientSelector,
} from "../../store";
import { RESPONSE } from "../../store/client/consts";
import { api } from "../../common/config/api";

import {
  Container,
  Header,
  MainContainer,
  Title,
  Image,
  TitleBlock,
  FlexStyledContainer,
  FormContainer,
  FormStyled,
  InputBlock,
  PhotoBlock,
  Label,
  Photo,
  DateBlock,
  DateText,
} from "./styled";
import { TForm } from "./type";

const CustomerProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { client, response } = useTypedSelector(getMainClientSelector);
  const { data: bonuses, loading } = useTypedSelector(getBonusesSelector);

  const statusOptions = useMemo(
    () => bonuses.map((bonus) => ({ name: bonus.name, value: bonus._id })),
    [bonuses]
  );

  const [form, setForm] = useState<TForm>({
    group: "",
  });

  const Events = {
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props: any) => ({ ...props, [name]: value }));
    },
    saveButtonClickHandler: () => {
      Requests.editClient();
      Requests.getClient();
    },
    removeClientHandler: () => {
      Requests.removeClient([client._id]);
    },
  };

  const Requests = {
    getClient: () => {
      dispatch(clientActions.getClient({ _id: id as string }));
    },
    getStatuses: () => {
      dispatch(bonusActions.getBonusesRequest());
    },
    editClient: () => {
      if (form.group === null) {
        const data: any = {
          group: null,
        };
        dispatch(clientActions.editClient({ _id: id as string, data }));
      } else {
        const data = {
          group: form.group,
        };
        dispatch(clientActions.editClient({ _id: id as string, data }));
      }
    },
    removeClient: (_id: string[]) => {
      dispatch(clientActions.removeClient({ _id }));
    },
  };

  useEffect(() => {
    if (form.group === "") {
      setForm({ group: null });
    }
  }, [form]);

  const Sections: TSection[] = useMemo(
    () => [
      {
        title: t("main.data"),
        active: true,
      },
      {
        title: t("orders.history"),
        onClickHandler: () => {
          navigate("./history");
        },
      },
    ],
    []
  );

  useEffect(() => {
    Requests.getClient();
    Requests.getStatuses();
  }, []);

  const handleDeleteBonus = () => {
    setForm({ group: "" });
  };

  useEffect(() => {
    if (client) {
      response !== RESPONSE.EDITED &&
        setForm({
          ...form,
          group: client.group?._id,
        });

      if (response === RESPONSE.EDITED) {
        Requests.getClient();
        Requests.getStatuses();
      }
      if (response === RESPONSE.REMOVED) {
        navigate("../customers");
      }
    }
  }, [client, response]);

  return (
    <>
      {!loading ? (
        <Container>
          <Header>
            <TitleBlock>
              <Image
                onClick={() => navigate("../customers")}
                src={Assets.ARROW_LEFT}
              />
              <Title>{t("edit")}</Title>
              <DateBlock>
                <DateText>
                  {t("createdAt.date")}
                  {moment(client?.createdAt).format("YYYY-MM-DD HH:mm")}
                </DateText>
                <DateText>
                  {t("updatedAt.date")}
                  {moment(client?.updatedAt).format("YYYY-MM-DD HH:mm")}
                </DateText>
              </DateBlock>
            </TitleBlock>
            <FlexStyledContainer>
              <ButtonNew
                theme="red"
                height={46}
                onClick={Events.removeClientHandler}
              >
                {t("delete")}
              </ButtonNew>
              <ButtonNew theme={"red"} height={46} onClick={handleDeleteBonus}>
                {t("reset.bonuse")}
              </ButtonNew>
              <ButtonNew
                theme="green"
                height={46}
                onClick={Events.saveButtonClickHandler}
              >
                {t("save")}
              </ButtonNew>
            </FlexStyledContainer>
          </Header>
          <MainContainer>
            <SubPagesBar sections={Sections} />
            <FormContainer>
              <FormStyled>
                <InputBlock>
                  <Input
                    width={"318px"}
                    name="name"
                    label={t("name")}
                    placeholder={t("enter.name")}
                    value={client?.name}
                    onChange={Events.inputChangeHandler}
                  />
                </InputBlock>
                <InputBlock>
                  <Input
                    width={"318px"}
                    name="surname"
                    label={t("surname")}
                    placeholder={t("enter.surname")}
                    value={client?.secondName}
                    onChange={Events.inputChangeHandler}
                  />
                </InputBlock>
                <InputBlock>
                  <DropDownList
                    name="group"
                    label={t("group")}
                    placeholder={t("enter.group.client")}
                    options={statusOptions}
                    value={form.group}
                    onChange={Events.inputChangeHandler}
                  />
                </InputBlock>
              </FormStyled>
              <FormStyled>
                <InputBlock>
                  <Input
                    width={"318px"}
                    name="phone"
                    label={t("phone")}
                    placeholder={t("enter.phone")}
                    value={client?.phone}
                    onChange={Events.inputChangeHandler}
                  />
                </InputBlock>
                <InputBlock>
                  <Input
                    width={"318px"}
                    name="email"
                    label={t("email")}
                    placeholder={t("enter.email")}
                    value={client?.email}
                    onChange={Events.inputChangeHandler}
                  />
                </InputBlock>
                <PhotoBlock>
                  <Label>{t("profile.photo")}</Label>
                  {client?.picture?.includes("https") ? (
                    <Photo src={client?.picture} />
                  ) : (
                    <Photo
                      src={`${api.withImageAPI}/customer/${client?.picture}`}
                    />
                  )}
                </PhotoBlock>
              </FormStyled>
            </FormContainer>
          </MainContainer>
        </Container>
      ) : (
        <RelativePreloader loading={loading} />
      )}
    </>
  );
};

export default CustomerProfile;
