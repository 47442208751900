import React, { useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
	Divider,
	generateErrorToast,
	Input,
	useValidation,
} from '../../common';
import { userActions } from "../../store/user";
import { showToastAction } from "../../store";

import {
	Container,
	LoginWrapper,
	Title,
	Logo,
	ButtonLogin
} from './styled';
import { TForm, TLoginValidationSchema } from "./types";

const Login = () => {
	const [form, setForm] = useState<TForm>({ login: '', password: '' });

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const schema = useMemo<TLoginValidationSchema>(
		() => ({
			login: {
				condition: !!form.login,
				error: t('placeholder.login')
			},
			password:{
				condition: !!form.password,
				error: t('placeholder.password')
			}
		}),
		[form]
	);

	const {
		validation,
		enableValidation,
		disableValidation,
	} = useValidation(schema);

	const Events = {
		loginButtonHandler: async () => {
			try {
				enableValidation();
				await validation();
				dispatch(userActions.request(form))
				disableValidation()
			} catch (error:any) {
				error?.map((err:string) => {
					dispatch(showToastAction.request(generateErrorToast(err)));
				});
			}
		},
		inputHandler: (e: React.SyntheticEvent) => {
			const { name, value } = e.target as HTMLInputElement
			setForm((props) => ({ ...props, [name]: value }))
		}
	};

	return (
		<Container>
			<LoginWrapper>
				<Logo>E-commerce</Logo>
				<Title>{t('login.form.title')}</Title>
				<Divider height={28} />
				<Input
					name="login"
					label={t('login')}
					width={'100%'}
					placeholder={t('placeholder.login')}
					value={form.login}
					onChange={Events.inputHandler}
					required
				/>
				<Divider height={18} />
				<Input
					name="password"
					type="password"
					width={'100%'}
					label={t('password')}
					placeholder={t('placeholder.password')}
					value={form.password}
					onChange={Events.inputHandler}
					required
				/>

				<Divider height={30} />

				<ButtonLogin onClick={Events.loginButtonHandler}>{t('login.button')}</ButtonLogin>

			</LoginWrapper>
		</Container>
	)
}

export default Login;