import styled from 'styled-components'
import { colors, FLEX, FONT } from '../../common'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  background: #E5E5E5;

  ${FLEX({})}
`

export const LoginWrapper = styled.div`
  width: 653px;

  padding: 100px 168px 80px 168px;
	
  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
  border-radius: 22px;

  background: ${colors.white};

  ${FLEX({direction:'column', justify: 'center', align: 'center'})}
`

export const Logo = styled.h1`
  ${FONT({
    size: '32px',
    weight: '700',
    align: 'center',
    color: colors.orange,
  })}
`;

export const Title = styled.h1`
  margin-top: 37px;
  margin-bottom: 28px;
	
  ${FONT({
	size: '24px',
	weight: '700',
	align: 'center',
	color: colors.black,
})}
`;

export const ButtonLogin = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	
  width: 195px;
	height: 46px;
  background: #F18018;
  border-radius: 8px;
	color: white;
	cursor: pointer;
	
	&:hover{
		color: #F18018;
		background-color: white;
		border: 2px solid #F18018;
	}
`;
