import React from 'react';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { StyledEngineProvider } from "@mui/material/styles";

import { AppRouter } from "./router";
import { persistor, store } from './store';
import { ToastsProvider } from "./common";

function App() {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
      <PersistGate loading={null} persistor={persistor}>
        <ToastsProvider>
          <AppRouter />
        </ToastsProvider>
      </PersistGate>
      </StyledEngineProvider>
    </Provider>
  );
}

export default App;
