import { FC, SyntheticEvent, useRef } from 'react';

import { BackgroundContainer, PopupContainer } from './styled';
import { TPopup } from './types';

const Popup: FC<TPopup> = ({ backgroundClickListener, children, height = '818px', width = '627px' }) => {
   const backgroundRef = useRef(null)

   const Events = {
      backgroundClickHandler: (e: SyntheticEvent) => {
         e.target === backgroundRef.current && backgroundClickListener && backgroundClickListener(e)
      }
   }

   return (
      <BackgroundContainer ref={backgroundRef} onClick={Events.backgroundClickHandler}>
         <PopupContainer width={width} height={height}>
            {children}
         </PopupContainer>
      </BackgroundContainer>
   )
}

export default Popup
