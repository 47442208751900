import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import {
  Assets,
  ButtonNew,
  DropDownList,
  FlexContainer,
  StarRating,
  Table,
  Textarea,
  useTypedSelector,
} from "../../common";
import { StarContainer } from "../Feedback/styled";
import { RESPONSE } from "../../store/feedback/consts";
import { feedbackActions, getMainFeedbackSelector } from "../../store/feedback";
import {
  clientActions,
  getMainClientSelector,
  getMainProductSelector,
  productActions,
} from "../../store";

import {
  ButtonBlock,
  Container,
  FlexStyledContainer,
  Header,
  ArrowImage,
  MainContainer,
  Name,
  Image,
  P,
  Wrapper,
  Label,
  ProductBlock,
  ProductContainer,
  FeedbacksContainer,
  StarBlock,
  Title,
  TitleBlock,
  Headline,
  ImageContainer,
  NameInput,
  InputContainer,
} from "./styled";
import { api } from "../../common/config/api";
import { getLocale } from "../../types";

const FeedbackProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const local = getLocale();

  const { feedback, feedbacksProduct, response } = useTypedSelector(
    getMainFeedbackSelector
  );
  const { client } = useTypedSelector(getMainClientSelector);
  const { newProduct } = useTypedSelector(getMainProductSelector);

  const [form, setForm] = useState({
    visibility: "",
  });

  const Events = {
    inputChangeShowHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value === "true" }));
    },
    editFeedbackHandler: () => {
      const data = {
        hidden: form.visibility,
      };
      dispatch(feedbackActions.editFeedback({ _id: id as string, data }));
    },
    removeFeedbacks: (id: string) => {
      dispatch(feedbackActions.removeFeedback({ _id: id as string }));
    },
    navigateHandler: () => {
      navigate("/feedback");
    },
    removeFeedbackHandler: () => {
      Requests.removeFeedback([feedback._id]);
    },
  };

  const Requests = {
    removeFeedback: (_ids: string[]) => {
      dispatch(feedbackActions.removeFeedback({ _ids }));
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: t("date"),
        accessor: "date",
        width: 200,
      },
      {
        Header: t("rating"),
        accessor: "rating",
        width: 200,
      },
      {
        Header: t("feedback"),
        accessor: "feedback",
        width: 250,
      },
    ],
    []
  );

  const hiddenOptions = [
    { name: t("show"), value: false },
    { name: t("not.show"), value: true },
  ];

  const data = useMemo(
    () =>
      feedbacksProduct.data.map((f: any) => {
        return {
          date: moment(f.createdAt).format("DD.MM.YYYY HH:mm"),
          rating: (
            <StarContainer>
              <StarRating rating={f?.mark} />
            </StarContainer>
          ),
          feedback: f.comment,
        };
      }),
    [feedbacksProduct]
  );

  useEffect(() => {
    if (id) {
      dispatch(feedbackActions.getFeedback({ _id: id as string }));
    }
  }, [id]);

  useEffect(() => {
    if (feedback?.customer) {
      dispatch(clientActions.getClient({ _id: feedback?.customer }));
    }
  }, [feedback?.customer]);

  useEffect(() => {
    if (feedback?.product) {
      dispatch(
        productActions.getProduct({ _id: feedback?.product, lang: local })
      );
    }
  }, [feedback?.product]);

  useEffect(() => {
    if (feedback?.product) {
      dispatch(feedbackActions.getFeedbacksProduct({ _id: feedback?.product }));
    }
  }, [feedback?.product]);

  useEffect(() => {
    if (feedback) {
      setForm({
        visibility: feedback.hidden,
      });
    }
  }, [feedback]);

  useEffect(() => {
    if (response === RESPONSE.REMOVED) {
      navigate("/feedback");
    }
  }, [response]);

  return (
    <Container>
      <Header>
        <TitleBlock>
          <ArrowImage
            onClick={Events.navigateHandler}
            src={Assets.ARROW_LEFT}
          />
          <Title>
            {t("feedback")} №{feedback?.id ?? 0}
          </Title>
        </TitleBlock>
        <FlexStyledContainer>
          <ButtonBlock>
            <ButtonNew theme="red" onClick={Events.removeFeedbackHandler}>
              {t("delete")}
            </ButtonNew>
          </ButtonBlock>
          <ButtonBlock>
            <ButtonNew theme="green" onClick={Events.editFeedbackHandler}>
              {t("save")}
            </ButtonNew>
          </ButtonBlock>
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <FlexContainer>
          <FlexContainer width={"750px"}>
            <FlexContainer gap={"62px"}>
              <InputContainer>
                <Label>{t("name")}</Label>
                <NameInput>{`${client?.name ?? "-"} ${
                  client?.secondName ?? ""
                }`}</NameInput>
              </InputContainer>
              <DropDownList
                name="visibility"
                label={t("visibility")}
                options={hiddenOptions}
                value={form.visibility}
                onChange={Events.inputChangeShowHandler}
              />
            </FlexContainer>
            <ProductContainer style={{ marginTop: "20px" }}>
              <FlexContainer align={"center"}>
                <Image
                  src={`${api.withImageAPI}/product/preview/${newProduct?.preview}`}
                />
                <ProductBlock>
                  <Name>{newProduct?.description?.title}</Name>
                  <P>{newProduct?.sku}</P>
                </ProductBlock>
              </FlexContainer>
              <StarBlock>
                <Label>{t("rating")}</Label>
                <StarRating size={24} rating={feedback?.mark} />
              </StarBlock>
            </ProductContainer>
            <Textarea
              name="description"
              width="698px"
              height="158px"
              placeholder={t("enter.description")}
              label={t("description")}
              onChange={() => ""}
            >
              {feedback?.comment}
            </Textarea>
          </FlexContainer>
          <Wrapper>
            <FlexContainer width={"750px"}>
              <FeedbacksContainer>
                <Headline>{t("all.feedback.product")}</Headline>
                <ImageContainer>
                  <Image
                    src={`${api.withImageAPI}/product/preview/${newProduct?.preview}`}
                  />
                  <ProductBlock>
                    <Name>{newProduct?.description?.title}</Name>
                    <P>{newProduct?.sku}</P>
                  </ProductBlock>
                </ImageContainer>
              </FeedbacksContainer>
            </FlexContainer>
            <Table columns={columns} data={data} />
          </Wrapper>
        </FlexContainer>
      </MainContainer>
    </Container>
  );
};

export default FeedbackProfile;
