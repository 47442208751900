import { createAction } from '@reduxjs/toolkit'
import {
   GET_BANNERS,
   REMOVE_BANNER,
   SAVE_BANNERS,
   SET_BANNERS,
   SET_BANNER_STATE
} from './const'
import { TRemoveBannerPayload, TSaveBannersPayload } from './types'

export const bannerActions = {
   getBanners: createAction(GET_BANNERS),
   setBanners: createAction(SET_BANNERS, (payload) => ({ payload })),
   removeBanner: createAction(REMOVE_BANNER, (payload: TRemoveBannerPayload) => ({ payload })),
   saveBanners: createAction(SAVE_BANNERS, (payload: TSaveBannersPayload) => ({ payload })),
   setBannerState: createAction(SET_BANNER_STATE, (payload) => ({ payload }))
}
