import React,
{
	SyntheticEvent,
	useCallback,
	useEffect,
	useState
} from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
	Assets,
	DropDownList,
	ELocales,
	FlexContainer,
	Input
} from "../../../../common";
import { productActions } from "../../../../store";

import {
	Delete,
	Image,
	Name,
	VariationBlock,
	VariationsHeader,
	VariationsMain
} from "../../styled";

const VariationTitle = ({ variationTypeOptions, variationTitle }: any) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const [idVariation, setIdVariation] = useState('');
	const [activeOnChange, setActiveOnChange] = useState(false);
	const [variation, setVariation] = useState(
		{
			order: 0,
			type: variationTitle.type,
			title: [
				{
					title: variationTitle.title,
					lang: ELocales.en
				},
				{
					title: variationTitle.title,
					lang: ELocales.uk
				},
				{
					title: variationTitle.title,
					lang: ELocales.de
				}
			],
		});

	const inputChangeHandler = useCallback((e: SyntheticEvent, id: string) => {
		setActiveOnChange(true)
		const {name, value} = e.target as HTMLInputElement;
		if (name === "type") {
			setVariation((prevState: any) => {
				return {
					...prevState,
					[name]: value,
				}
			});
		} else {
			setVariation((prevState: any) => {
				return {
					...prevState,
					title: [
						{
							title: value,
							lang: ELocales.en
						},
						{
							title: value,
							lang: ELocales.uk
						},
						{
							title: value,
							lang: ELocales.de
						}
					],
				}
			});
		}
		setIdVariation(id)
	}, [variation, idVariation]);

	useEffect(() => {
		const data = variation;
		activeOnChange && dispatch(productActions.editVariation({_id: idVariation, data}));
	}, [variation]);

	const removeVariationHandler = (id: string) => {
		dispatch(productActions.removeVariation({_id: id}))
	};

	return (
		<VariationBlock>
			<VariationsHeader>
				<Name>{t('create.variation')}</Name>
				<Delete onClick={() => removeVariationHandler(variationTitle.id)}><Image src={Assets.CROSS}/>{t('delete')}
				</Delete>
			</VariationsHeader>
			<VariationsMain>
				<FlexContainer gap={'62px'} style={{padding: '10px 0'}} align={'center'}>
					<Input
						name="title"
						label={t('title')}
						placeholder={t('enter.title')}
						value={variation.title[0].title}
						onChange={(event) => inputChangeHandler(event, variationTitle.id)}
					/>
					<DropDownList
						name="type"
						label={t('display.type')}
						options={variationTypeOptions}
						placeholder={t('enter.display.type')}
						value={variation.type}
						onChange={(event) => inputChangeHandler(event, variationTitle.id)}
					/>
				</FlexContainer>
			</VariationsMain>
		</VariationBlock>
	);
};

export default VariationTitle;