import React, { useState } from 'react';

import { VariationTitle } from '../VariationTitle';
import { VariationValue } from '../VariationValue';
import { Divider } from "../../../../common";

import {
	VariationsContainer,
	VariationWrapper,
	Container
} from "../../styled";

const VariationContainer = ({ variationsData, variationTypeOptions, selectColor }: any) => {
	const [valuesColor, setValuesColor] = useState<any>([]);
	const [valuesText, setValuesText] = useState<any>([]);
	const [variationTextId, setVariationTextId] = useState('');
	const [variationColorId, setVariationColorId] = useState('');

	return (
		<Container className={'variations_form_container'}>
					{
						variationsData?.map((variationTitle: any, index: number) => variationTitle && (
							<VariationWrapper className={'variation_form_container'} key={index}>
								<VariationsContainer>

									<VariationTitle
										variationTitle={variationTitle}
										variationTypeOptions={variationTypeOptions}
									/>

									<Container className={'variationsValue_form_container'}>
										{variationTitle?.values.map((formVariations: any, idx: number) => (
											<VariationValue
												key={idx}
												selectColor={selectColor}
												variationsData={variationsData}
												valuesText={valuesText}
												valuesColor={valuesColor}
												variationTextId={variationTextId}
												variationColorId={variationColorId}
												setVariationTextId={setVariationTextId}
												setVariationColorId={setVariationColorId}
												setValuesText={setValuesText}
												setValuesColor={setValuesColor}
												variationTitle={variationTitle}
												formVariations={formVariations}
												index={idx}
											/>
										))
										}
									</Container>
								</VariationsContainer>
								<Divider height={30}/>
							</VariationWrapper>
						))
					}
				</Container>
	);
};

export default VariationContainer;