import { createAction } from '@reduxjs/toolkit'
import {
   EDIT_FEEDBACK,
   GET_FEEDBACK,
   GET_FEEDBACK_PRODUCT,
   GET_FEEDBACKS,
   REMOVE_FEEDBACK,
   SET_FEEDBACK,
   SET_FEEDBACK_PRODUCT,
   SET_FEEDBACK_STATE,
   SET_FEEDBACKS
} from './consts'

export const feedbackActions = {
   getFeedbacks: createAction(GET_FEEDBACKS, (payload) => ({payload})),
   setFeedbacks: createAction(SET_FEEDBACKS, (payload) => ({payload})),
   getFeedback: createAction(GET_FEEDBACK, (payload) => ({payload})),
   setFeedback: createAction(SET_FEEDBACK, (payload) => ({payload})),
   getFeedbacksProduct: createAction(GET_FEEDBACK_PRODUCT, (payload) => ({payload})),
   setFeedbacksProduct: createAction(SET_FEEDBACK_PRODUCT, (payload) => ({payload})),
   editFeedback: createAction(EDIT_FEEDBACK, (payload) => ({payload})),
   removeFeedback: createAction(REMOVE_FEEDBACK, (payload) => ({ payload })),
   setFeedbackState: createAction(SET_FEEDBACK_STATE, (payload) => ({ payload })),
}

