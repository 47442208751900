import {call, put, takeLatest} from 'redux-saga/effects'
import {TDataWrapper, TResponse} from '../types'
import { ApiSettingService } from './api.service'
import { getAccessToken } from "../user";
import {TEditLogoPayload, TGetLogoPayload, TSettingResponse} from "./types";
import {settingAction} from "./actions";
import {RESPONSE} from "./consts";
import {showToastAction} from "../toasts";
import {generateErrorToast, generateSuccessToast} from "../../common";
import {i18n} from "../../common/config";

function* getLogoWorker({payload}: TDataWrapper<TGetLogoPayload>) {
	yield put(settingAction.setSettingState({loading: true}))
	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiSettingService, ApiSettingService.getLogo], {...payload, token})
		if (response.data) yield put(settingAction.setLogo(response.data))
	} catch (e) {
		yield put(settingAction.setLogo({data: [], meta: null}))
	}

	yield put(settingAction.setSettingState({loading: false}))
}

function* editLogoWorker({ payload }: TDataWrapper<TEditLogoPayload>) {
	yield put(settingAction.setSettingState({ loading: true }))

	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiSettingService, ApiSettingService.editLogo], { ...payload, token })
		if (response.data.data) yield put(settingAction.setLogo({ ...response.data.data }))
		yield put(showToastAction.request(generateSuccessToast(i18n.t("setting.updated.successfully") as string)));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.update.setting") as string)));
		yield put(settingAction.setLogo(null))
	}

	yield put(settingAction.setSettingState({ loading: false, response: RESPONSE.EDITED as TSettingResponse }))
}


export function* settingWatcher() {
	yield takeLatest(settingAction.getLogo, getLogoWorker)
	yield takeLatest(settingAction.editLogo, editLogoWorker)
}

