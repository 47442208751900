import styled from 'styled-components'
import { colors, FONT } from "../../styles";
import { Assets } from "../../assets";

export const CardContainer = styled.div`
   display: flex;
   border-top: 1px solid ${colors.lines};
   border-bottom: 1px solid ${colors.lines};
   width: 100%;
   height: 100px;
   margin: -1px 0 0 0;
   align-items: center;
   justify-content: space-between;
`

export const ProductImg = styled.img`
   max-width: 100px;
   max-height: 72px;
   width: auto;
   height: auto;  
   margin-left: 30px;
`

export const ProductName = styled.p`
   ${FONT({ color: colors.black, size: '14px' })}
   width:268px;
   max-width: 100%;
`

export const ProductNumber = styled.span`
   ${FONT({ color: colors.gray })}
`

export const RemoveButton = styled.button`
   border: none;
   background-color: transparent;
   background-image: ${`url(${Assets.CROSS})`};
   background-repeat: no-repeat;
   background-position: center;
   cursor: pointer;
   width: 14px;
   height: 14px;
`

export const PromotionalPrice = styled.div`
   ${FONT({ color: colors.gray, size: '14px' })}
   text-decoration-line: line-through;
`

export const Price = styled.div`
   ${FONT({ size: '16px', weight: '500' })}
`

export const BlockPicker = styled.div`
   width: 30px;
   height: 30px; 
   border-radius: 50%;
`;
