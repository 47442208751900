export const colors = {
	orange: '#F18018',
	green: '#31A52F',
	white: '#FFFFFF',
	gray: '#818181',
	red: '#DC3333',
	lines: '#F4F4F4',
	strokeInput: '#D1D1D1',
	greyBg: '#F4F5F7',
	black: '#222222',
}


export default colors;