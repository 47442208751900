import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
	DataTotal,
	DateInput,
	FiltersWrapper,
	FlexContainer,
	HeaderWrapper,
	HoverList,
	Pagination,
	SubPagesBar,
	TSection,
	TSort,
	useTypedSelector,
	Table,
	ButtonNew,
	SearchBar,
} from "../../common";
import { RESPONSE } from "../../store/order/consts";

import {
	MainContainer,
	Container,
	ButtonBlock,
	FlexStyledContainer,
	Header,
	Title,
	TitleBlock
} from "./styled";
import {getMainOrderSelector, orderActions } from "../../store";
import {getUserSelector} from "../../store/user";
import {getLocale} from "../../types";

const Orders = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const local = getLocale();

	const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [page, setPage] = useState<number>(0);
	const [searchForm, setSearchForm] = useState({
		searchValue: '',
		startDateValue: '',
		endDateValue: ''
	});
	const [searchParams, setSearchParams] = useSearchParams({});
	const [sortParamsForm, setSortParamsForm] = useState({
		sortBy: '',
		order: '' as TSort
	});

	const { orders, response, loading } = useTypedSelector(getMainOrderSelector);
	const { permissions } = useTypedSelector(getUserSelector);

	const paginationSections: TSection[] = useMemo(
		() => [
			{
				title: t('current.orders'),
				active: true
			},
			{
				title: t('notify.when.available'),
				onClickHandler: () => {
					navigate('/availabilityNotification')
				}
			},
			{
				title: t('archive'),
				onClickHandler: () => {
					navigate('../orders/archive')
				}
			}
		],
		[]
	)

	const tableSortBy = useMemo(() => {
		{
			if (!sortParamsForm.order) {
				return { id: null, desc: false }
			}
			return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
		}
	}, [sortParamsForm])

	const Requests = {
		getOrders: () => {
			dispatch(
				orderActions.getOrders({
					limit: 9,
					page,
					lang: local,
					regex: searchForm.searchValue,
					start: searchForm.startDateValue,
					end: searchForm.endDateValue,
					...sortParamsForm
				})
			)
		},
		removeOrders: (_ids: string[]) => {
			dispatch(orderActions.removeOrder({_ids}))
		}
	}

	const Events = {
		onPageChangeHandler: ({ selected }: { selected: number }) => {
			setPage(selected)
		},
		onChangeHandler: (e: React.SyntheticEvent) => {
			const input = e.target as HTMLInputElement
			setSearchForm({ ...searchForm, [input.name]: input.value })
			Requests.getOrders()
		},
		onSubmitSearchHandler: (e: SyntheticEvent) => {
			e.preventDefault()
			Requests.getOrders()
		},
		editClickHandler: (e: SyntheticEvent, index: number) => {
			navigate(`../orders/profile/${orders.data[index]._id}`)
		},
		sortToggleHandler: (sortBy: string, order: any) => {
			setSortParamsForm({ sortBy, order })
		},
		checkboxClickHandler: (e: SyntheticEvent, hasCheckboxesActiveState: boolean, ckeckedItemsIndex: number[]) => {
			setRemoveButtonState(hasCheckboxesActiveState)
			setSelectedItems(ckeckedItemsIndex)
		},
		removeOrders: () => {
			Requests.removeOrders(selectedItems.map((index) => orders.data[index]._id))
			setRemoveButtonState(false)
			setSelectedItems([])
		}
	};

	useEffect(() => {
		if(response === RESPONSE.REMOVED){
			Requests.getOrders();
		}
	}, [response]);

	const data = useMemo(
		() =>
			orders.data.map((order:any) => {
				const created_at = order.createdAt
				return {
					id: <>{order.id}</>,
					createdAt:moment(created_at).format('DD.MM.YYYY HH:mm'),
					order: (
						<FlexContainer align="center" justify="center">
							<FlexContainer
								direction="column"
								align="flex-start"
								justify="flex-start"
								gap="4px"
								width="170px"
								style={{ padding: 20 }}>
								<p style={{ textAlign: 'start' }}>{order.items[0].product?.description?.title}</p>
								{!!order.items.slice(1).length && (
									<HoverList items={order.items.slice(1).map((item:any) => item.product?.description?.title)} />
								)}
							</FlexContainer>
						</FlexContainer>
					),
					total: order?.itemsPrice?.toFixed(2),
					customer: (
						<>
							<p>
								{order.customer?.name ?? '-'} {order.customer?.secondName}
							</p>
							<p>{order.customer?.email}</p>
							<p>{order.customer?.phone}</p>
						</>
					),
					paymentType: <>{t(order.paymentType)}</>,
					deliveryMethod: <>{t(order?.delivery?.form)}</>,
					paymentStatus: <>{t(order.paymentStatus.split(' ').join('.'))}</>,
					orderStatus: <>{t(order.orderStatus.split(' ').join('.'))}</>
				}
			}),
		[orders]
	)

	const columns = useMemo(
		() => [
			{
				Header: t('order.code'),
				accessor: 'id',
				width: 20,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('date'),
				accessor: 'createdAt',
				width: 125,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
			  Header: t('sum'),
				accessor: 'total',
				width: 50,
				sortToggleHandler: Events.sortToggleHandler
	    },
			{
				Header: t('order'),
				accessor: 'order',
				width: 200
			},
			{
				Header: t('client'),
				accessor: 'customer',
				width: 200,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('payment.type'),
				accessor: 'paymentType',
				width: 100,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('delivery.method'),
				accessor: 'deliveryMethod',
				width: 150,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('payment'),
				accessor: 'paymentStatus',
				width: 200,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('order.status'),
				accessor: 'orderStatus',
				width: 100,
				sortToggleHandler: Events.sortToggleHandler
			}
		],
		[]
	)

	useEffect(() => {
		Requests.getOrders()
		setSearchParams({ ...searchParams, page: '' + page })
	}, [page, sortParamsForm, searchForm])

	// Set default page uri
	useEffect(() => {
		setPage(!isNaN(parseInt(searchParams.get('page') as string)) ? parseInt(searchParams.get('page') as string) : 0)
	}, []);

	useEffect(() => {
		if (!loading && orders?.meta?.needReload) Requests.getOrders()
	});

	const order = permissions.filter((el:string) => el === 'order').join();
	if(order !== 'order') navigate('/404');
	return (
	 <Container>
				<Header>
					<TitleBlock>
						<Title>{t('orders')}</Title>
					</TitleBlock>
					<FlexStyledContainer>
						{removeButtonState && (
							<ButtonBlock>
								<ButtonNew width={195} theme="red" onClick={Events.removeOrders}>
									{t('remove.all')}
								</ButtonNew>
							</ButtonBlock>
						)}
					</FlexStyledContainer>
				</Header>
				<MainContainer>
					<SubPagesBar sections={paginationSections} />
					<FlexContainer direction="column" gap="30px">
						<HeaderWrapper>
							<FiltersWrapper style={{marginLeft: '-30px'}}>
								<SearchBar
									name="searchValue"
									placeholder={t('search')}
									value={searchForm.searchValue}
									onChange={Events.onChangeHandler}
									onSubmit={Events.onSubmitSearchHandler}
								/>
								<DateInput
									name="startDateValue"
									value={searchForm.startDateValue}
									onChange={Events.onChangeHandler}
								/>
								<DateInput name="endDateValue" value={searchForm.endDateValue} onChange={Events.onChangeHandler} />
							</FiltersWrapper>
							<DataTotal>
								{t("total.orders")} {orders.meta?.totalCount}
							</DataTotal>
						</HeaderWrapper>

						<Table
							columns={columns}
							data={data}
							checkboxClickHandler={Events.checkboxClickHandler}
							editClickHandler={Events.editClickHandler}
							sortBy={tableSortBy}
							editable
						/>
					</FlexContainer>

					<Pagination
						page={page}
						pageCount={orders.meta ? Math.ceil(orders.meta.totalCount / 9) : 1}
						onPageChange={Events.onPageChangeHandler}
					/>
				</MainContainer>
			</Container>
	);
}

export default Orders;