import styled, { css } from 'styled-components'
import {TIcon, TImageButton} from './types'
import { colors, FLEX, FONT } from "../../styles";
import { Assets } from "../../assets";

export const Container = styled.div`
   ${FLEX({ direction: 'column', justify: 'flex-start', align: 'flex-start' })}
   
   .photo-input {
      display: none;
   }
`

export const Label = styled.label`
   text-align: center;
   margin-bottom: 8px;
   line-height: 16px;

   ${FONT({ color: colors.black, size: '14px', weight: '500' })}
`

export const PlusIcon = styled.img.attrs({
   src: Assets.PLUS_ICON
})`
   filter: ${colors.black};
   transition: all 0.4s ease;
   width: 37px;
   height: auto;
`

export const ImageButton = styled.button<TImageButton>`
   cursor: pointer;
   width: ${({ width }) => `${width}px`};
   height: ${({ height }) => `${height}px`};
   position: relative;
   ${FLEX({})}
   background-color: ${colors.gray};
   border: none;

   &:hover ${PlusIcon} {
      filter: ${colors.orange};
   }

   ${({ src }) =>
      src &&
      css`
         border-radius: 8px;

         ${PlusIcon} {
            display: none;
         }
      `}

   background-image: ${({ src }) => `url(${src})`};
   background-size: cover;
   background-position: center;
`;
export const ErrorLable = styled.p`
   ${FONT({ color: colors.red })}
   align-self: center;
   width: 110px;
   word-break: break-all;
`

export const CrossIcon = styled.img.attrs({ src: Assets.CROSS })<TIcon>`
   position: absolute;
   top: -10px;
   right: -10px;
   cursor: pointer;

   ${({ hidden }) =>
  hidden &&
  css`
         display: none;
  `}
`
